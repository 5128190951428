import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";
import { Flex } from "ui/atoms/Flex";
import { Background } from "ui/atoms/Background";
import { Typography } from "ui/atoms/Typography";

export interface Props extends IProps<HTMLDivElement> {
  left: React.ReactNode;
  right: React.ReactNode;
  color: string;
}

export const Bar: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      className={cx(props.className, styles(props, theme), "bar")}
    >
      <Box className="container">
        <Background
          shape="pill"
          x="70%"
          y="-25%"
          size={35}
          color={props.color}
        />
        <Background
          shape="pill"
          x="45%"
          y="110%"
          size={30}
          rotate={-68}
          color={props.color}
        />
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        {props.left}
        {props.right}
      </Flex>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  height: 5rem;
  position: relative;
  border-radius: ${theme.border.radius.medium};
  padding: ${theme.padding.page};
  box-sizing: border-box;

  & > .container.box {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  & > .flex {
    position: relative;
    z-index: 2;
  }
`;
