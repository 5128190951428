import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { DOM, IInputProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";
import { useForm } from "utils/hook/form";
import { log } from "utils/function/console";

export interface Props<T = ElementTypes> extends IInputProps<T> {
  error?: string | InputError;
  onError?(type: InputErrorTypes | null, message?: string): void;
  onChange?: (event: React.FormEvent<ElementTypes>) => void;
  skipvalidate?: boolean;
  children: (
    onChange: (e: React.FormEvent<ElementTypes>) => void
  ) => React.ReactNode;
  name?: string;
}

export const InputTemplate: React.FC<Props<ElementTypes>> = (props) => {
  const theme = useTheme();
  const { setError: setFormError } = useForm();
  const errorRef = React.useRef<ElementTypes>(null);

  function onChange(e: React.FormEvent<ElementTypes>) {
    // eslint-disable-next-line no-console
    if (props.onChange) props.onChange(e);
    if (!props.skipvalidate) setError(e.currentTarget);
  }
  function setError(target: EventTarget & ElementTypes) {
    const { validity } = target;
    let errorMSG: string | undefined;
    let type!: InputErrorTypes;
    for (const key in validity) {
      if (validity[key as InputErrorTypes]) {
        type = key as InputErrorTypes;
        errorMSG = getError(props.error, type);
        break;
      }
    }

    const t = errorRef.current as ElementTypes;
    if (errorMSG) {
      t.innerText = errorMSG;
      if (!t.classList.contains("show")) {
        t.classList.add("show");
        target.classList.add("error");
        if (props.onError) props.onError(type, errorMSG);
      }
    } else {
      t.innerText = "";
      if (t.classList.contains("show")) {
        target.classList.remove("error");
        t.classList.remove("show");
        if (props.onError) props.onError(null);
      }
    }

    // finally set form error
    if (props.name) {
      setFormError(props.name, validity);
    }
  }

  return (
    <Box
      className={cx(
        styles({ ...props }, theme),
        props.className,
        "input-wrapper"
      )}
      testId={props.testId}
    >
      {props.children(onChange)}
      <span
        className="error"
        data-testid={`${props.testId}-error`}
        ref={errorRef}
      />
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 1;

  & .selector {
    min-height: 50px;
    box-sizing: border-box;
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 200% */

    letter-spacing: 0.02em;
    padding: 8px 24px;
    border-radius: ${theme.border.radius.small};
    border: ${theme.border.input} solid ${theme.colors.Neutral.Gray4};
    backgound-color: ${theme.colors.Primary.White};
    color: ${theme.colors.Neutral.Gray3};

    &.error {
      border: ${theme.border.input} solid ${theme.colors.Error.Red2};
    }

    &:focus {
      border: ${theme.border.input} solid ${theme.colors.Neutral.Gray2};
      &.error {
        border: ${theme.border.input} solid ${theme.colors.Error.Red1};
      }
      outline: none;
    }
  }
  & span.error {
    display: none;
    width: 100%;
    text-align: right;
    color: ${theme.colors.Error.Red2};
    margin-top: 0.2rem;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    position: absolute;
    bottom: -1.6rem;
    right: 0;

    &.show {
      display: inline-block;
    }
  }
`;

// types & interfaces
type InputErrorTypes =
  | "badInput"
  | "customError"
  | "patternMismatch"
  | "rangeOverflow"
  | "rangeUnderflow"
  | "stepMismatch"
  | "tooLong"
  | "tooShort"
  | "typeMismatch"
  | "valid"
  | "valueMissing";

type InputError = {
  badInput?: string;
  customError?: string;
  patternMismatch?: string;
  rangeOverflow?: string;
  rangeUnderflow?: string;
  stepMismatch?: string;
  tooLong?: string;
  tooShort?: string;
  typeMismatch?: string;
  valid?: string;
  valueMissing?: string;
};
export type ElementTypes = HTMLInputElement | HTMLTextAreaElement;

// helper functions
function getError(
  error: string | InputError | undefined,
  type: InputErrorTypes
) {
  if (!error) return undefined;
  if (typeof error === "string") return error;

  return (error as InputError)[type];
}
