import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Typography } from "ui/atoms/Typography";
import { Toggle } from "ui/atoms/Toggle";
import { Flex } from "ui/atoms/Flex";
import { info } from "utils/function/console";

export interface Props extends IProps<HTMLDivElement> {
  leftValue: string;
  rightValue: string;
  toggle: boolean;
  setToggle(toogle: boolean): void;
}

export const ToggleGroup: React.FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <Flex
      {...props}
      className={cx(props.leftValue, styles(props, theme), "switchBox")}
      alignItems="center"
    >
      <Typography
        className="leftValueToggle"
        noselect
        testId={props.testId + "-left"}
      >
        {props.leftValue}
      </Typography>
      <Toggle
        toggle={props.toggle}
        setToggle={props.setToggle}
        className="toggle"
      />
      <Typography className="rightValueToggle" testId={props.testId + "-right"}>
        {props.rightValue}
      </Typography>
    </Flex>
  );
};

const styles = (props: Props, theme: Theme) => css`
  background-color: white;
  .leftValueToggle {
    margin-left: 1.5rem;
  }
  .toggle {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .rightValueToggle {
    margin-right: 1.5rem;
  }
  border-left: 1px solid ${theme.colors.Neutral.Gray4};
`;
