import React, { ReactNode } from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { useDebounce } from "utils/hook/debounce";
import { useUUID } from "utils/hook/uuid";

// atoms
import { Box } from "ui/atoms/Box";
import { Collapse } from "ui/atoms/Collapse";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";

// templates
import { FieldTemplate } from "ui/templates/Field";
import { InputTemplate, Props as InputTemplateProps } from "ui/templates/Input";

// local
import { Context } from "./context";
import { INameModel } from "utils/model/utils";
import { Option } from "./Option";
import { log } from "utils/function/console";

export interface Props
  extends Omit<InputTemplateProps<HTMLSelectElement>, "children"> {
  children?: React.ReactNode;
  multiple?: boolean;
  variant?: "filled" | "plain";
  color?: "white" | "lighter" | "light" | "primary" | "dark" | "darker";
  text?: React.ReactNode;
  placeholder?: string;
  label?: string;
  loading?: boolean;
  number?: boolean;
  doCheck?(value: ValueType): Promise<boolean>;
  defaultValue?: ValueType;
}

export const Dropdown: React.FC<Props> = (props) => {
  const { dom, ...rest } = props;

  // ref
  const mapRef = React.useRef<{ [key: string]: string }>({});
  const inputRef = React.useRef<HTMLInputElement>(null);
  const prevChildrenCount = React.useRef(0);

  // states
  const [expanded, setExpanded] = React.useState(false);
  const [Z, setZ] = React.useState<number>(1);
  const [map, setMap] = React.useState<{ [key: string]: string }>({});
  const [collapseLoad, setCollapseLoad] = React.useState(false);

  // hooks
  const theme = useTheme();
  const uuid = useUUID();
  const debouncedZ = useDebounce(() => setZ(1), theme.animation.default); // default collapse time
  const debouncedCollapseLoad = useDebounce(
    () => setCollapseLoad(false),
    theme.animation.fast
  );
  const debouncedEvent = useDebounce(
    (value: string) =>
      inputRef.current?.dispatchEvent(new Event("input", { bubbles: true })),
    1
  );
  const { t } = useTranslation();

  // variables
  const label = props.variant === "plain" ? undefined : props.label;
  const mapkeys = Object.keys(map);
  let buttonTextVariant: "button1" | "button2" | "button3" | undefined =
    undefined;
  let textContent: ReactNode = props.placeholder;

  // effects
  React.useEffect(() => {
    // register click away
    window.addEventListener("click", clickAway);

    return () => {
      window.removeEventListener("click", clickAway);

      debouncedZ.clear();
      debouncedEvent.clear();
      debouncedCollapseLoad.clear();
    };
  }, []);

  React.useEffect(() => {
    const count = React.Children.count(props.children);

    if (count !== prevChildrenCount.current) {
      setCollapseLoad(true);
      debouncedCollapseLoad.run(); // will be called later
    }

    prevChildrenCount.current = count;
  }, [props.children]);

  // functions
  async function toggleValue(value: string, name: string) {
    if (props.doCheck) {
      const check = await props.doCheck(value);

      if (!check) return true;
    }

    if (!props.multiple) {
      mapRef.current = { [value]: name };
    } else {
      if (mapRef.current[value]) {
        delete mapRef.current[value];
      } else {
        mapRef.current[value] = name;
      }
    }

    const copy = { ...mapRef.current };
    setMap(copy);

    if (!props.multiple) hide();
    debouncedEvent.run(value);
  }
  function hide() {
    setExpanded(false);
    debouncedZ.run(); // here it will become 1
    setZ(4); // so its still above rest but not current
  }
  function clickAway(e: MouseEvent) {
    const path = e.composedPath();
    /**NOTE path is a list
     * where it starts from  -  ends
     * [closest-to-click element,... highest (should be window)]
     * so we go from start and once we hit this component
     * we go back to check for selector or label component to be clicked
     */
    for (let i = 0; i < path.length; i++) {
      let node = path[i];
      let elm = node as HTMLElement;
      if (elm.tagName) {
        if (elm.getAttribute("data-dropdownid") === uuid) {
          // check if path is including either .selector or .label
          for (let j = i - 1; j >= 0; j--) {
            node = path[j];
            elm = node as HTMLElement;

            if (elm.tagName) {
              if (
                elm.classList.contains("label") ||
                elm.classList.contains("selector") ||
                elm.classList.contains("dropdown-list")
              ) {
                return;
              }
            }
          }
        }
      }

      if (node === window) {
        hide();
        break;
      }
    }
  }
  function buttonClick(e: React.MouseEvent) {
    if (!expanded) {
      setZ(5);
    } else {
      debouncedZ.run();
    }
    setExpanded(!expanded);
  }

  // ifs & buts
  if (props.variant === "plain") {
    if (props.label) textContent = props.label;
  }

  if (mapkeys.length > 0) {
    const texts = Object.values(map).join(", ");
    if (texts.length > 20) {
      textContent = t("atoms.select.multiple", { size: mapkeys.length });
    } else {
      textContent = texts;
    }
  }

  if (props.text) {
    textContent = props.text;
    buttonTextVariant = "button2";
  }

  // context
  const provides = {
    map,
    toggleValue,
    defaultValue: props.defaultValue?.toString() || "",
  };

  return (
    <Context.Provider value={provides}>
      <FieldTemplate
        {...rest}
        dom={{
          "data-dropdownid": uuid,
        }}
        className={cx(
          props.className,
          styles({ ...props, z: Z }, theme),
          props.variant,
          "dropdown"
        )}
        label={label}
        loading={props.loading}
      >
        <InputTemplate {...rest} testId={props.testId + "-wrapper"}>
          {(onChange) => (
            <span className={cx(props.className, "dropdown-wrapper")}>
              <input
                name={props.name}
                data-testid={props.testId}
                hidden
                ref={inputRef}
                value={mapkeys}
                onInput={onChange}
                data-selectnumber={props.number ? "true" : "false"}
              />
              <Button
                shape={props.variant === "plain" ? "plain" : "square"}
                className={cx("selector", props.variant || "filled")}
                onClick={buttonClick}
                color={props.color}
                testId={props.testId + "-button"}
                textVariant={buttonTextVariant}
              >
                {textContent}
              </Button>
              {!props.text && (
                <Button className="icon" onClick={buttonClick} shape="plain">
                  <Icon
                    type="caret"
                    rotate={expanded ? 90 : 175}
                    color={theme.colors.Neutral.Gray3}
                  />
                </Button>
              )}
              {!collapseLoad && (
                <Collapse
                  className="dropdown-list"
                  from="0"
                  to="full"
                  expanded={expanded}
                  testId={props.testId + "-collapse"}
                  target="height"
                  duration={theme.animation.fast}
                >
                  <Box>{props.children}</Box>
                </Collapse>
              )}
            </span>
          )}
        </InputTemplate>
      </FieldTemplate>
    </Context.Provider>
  );
};

// css design
const styles = (props: CSSProps, theme: Theme) => css`
  z-index: ${props.z};

  &.plain {
    & .dropdown-wrapper {
      display: inline;

      & .selector {
        background: none;
        border: none;
        border-radius: 0;
      }

      & .icon.button {
      }
    }
  }

  & .dropdown-wrapper {
    display: inline-block;
    width: 100%;
    position: relative;

    & .selector {
      width: 100%;
      justify-content: ${props.text ? "center" : "flex-start"};

      &.filled {
        background-color: ${theme.colors.Primary.White};
      }
    }

    & .icon.button {
      position: absolute;
      right: 0;
      top: 25px;
      transform: translate(-50%, -50%);
      padding: 0;
    }

    & .dropdown-list {
      // margin-top: 0.1rem;
      width: 100%;
      position: absolute;
      max-height: 30rem;
      overflow-y: auto;

      & .box {
        border: ${theme.border.button} solid ${theme.colors.Neutral.Gray4};
        background-color: ${theme.colors.Primary.White};
        padding: 1rem;
        z-index: 5;
        width: calc(100% - 14px);
        box-sizing: border-box;
        transform: translateX(7px);
        border-bottom-right-radius: ${theme.border.radius.small};
        border-bottom-left-radius: ${theme.border.radius.small};

        & .select-wrapper {
          display: inline-block;
          position: relative;
        }

        & optgroup {
          margin-top: 0.3rem;
          & option {
            padding-left: 2.8rem;
          }
        }
      }
    }
  }
`;

// types & interfaces
type CSSProps = Props & {
  z: number;
};
type ValueType = number | string | undefined;

Dropdown.displayName = "Dropdown";
