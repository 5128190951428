import { IProductCategory } from "utils/model/ProductCategory";

const data: IProductCategory = {
  id: 0,
  name: "product-name",
  types: [
    { id: 0, name: "a" },
    { id: 1, name: "b" },
    { id: 2, name: "c" },
    { id: 3, name: "d" },
    { id: 4, name: "e" },
    { id: 5, name: "f" },
  ],
};

export const productcategories = [
  { ...data },
  { ...data, id: 1 },
  { ...data, id: 2 },
  { ...data, id: 3 },
  { ...data, id: 4 },
  { ...data, id: 5 },
  { ...data, id: 6 },
  { ...data, id: 7 },
];
