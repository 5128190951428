/* eslint-disable no-console */
import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { AppContext } from "AppContext";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { HTTPError } from "utils/error/http";

// models
import { IUser } from "utils/model/User";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Background } from "ui/atoms/Background";
import { Typography } from "ui/atoms/Typography";
import { Icon } from "ui/atoms/Icon";
import { Form } from "ui/atoms/Form";
import { Flex } from "ui/atoms/Flex";
// import { Button } from 'ui/atoms/Button';

// molecules
import { Checkbox } from "ui/molecules/Checkbox";
import { TextField } from "ui/molecules/TextField";
import { Image } from "ui/molecules/Image";
import { SubmitButton } from "ui/molecules/SubmitButton";

// templates
import { PageTemplate, Props as PageTemplateProps } from "ui/templates/Page";
import {
  AuthPageTemplate,
  Props as AuthPageTemplateProps,
} from "ui/templates/AuthPage";

// locals
import { login } from "./http";
import { ISubmit } from "./types";
import { useDebounce } from "utils/hook/debounce";

export interface Props extends IProps<HTMLDivElement> {}

export const LoginPage: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { addSnackbar } = React.useContext(AppContext);

  const debouncedRedirect = useDebounce(() => {
    const { state, pathname } = location as {
      state: Location | undefined;
      pathname: string;
    };

    if (state) {
      if (state.pathname !== pathname) {
        navigate(state.pathname, { state: null });
      }
    } else if (t("router.home.route") !== pathname) {
      navigate(t("router.home.route", { state: null }));
    }
  }, 200);

  const { mutate: doLogin, isLoading } = useMutation<
    unknown,
    HTTPError,
    ISubmit,
    unknown
  >(login, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("authcheck");

      debouncedRedirect.run();
    },
    onError: (responds) => {
      addSnackbar({ text: responds.error[0].message, type: "error" });
    },
  });

  function onSubmit(e: React.FormEvent, data: ISubmit) {
    doLogin(data);
  }

  React.useEffect(() => {
    return () => {
      debouncedRedirect.clear();
    };
  }, []);

  return (
    <AuthPageTemplate
      {...props}
      authcheck={() => true}
      className={cx(props.className, styles(props, theme), "login")}
    >
      <Grid
        container
        cols={{
          default: "1fr 1fr",
        }}
      >
        <Box color={theme.colors.Secondary.Crimson} className="left">
          <Background
            color={theme.colors.Secondary.Crimson}
            shape="toroid"
            x="88%"
            y="10%"
            size={70}
          />
          <Background
            color={theme.colors.Secondary.Crimson}
            shape="circle"
            x="10%"
            y="88%"
            size={80}
          />
          <Flex justifyContent="center" alignItems="center" direction="column">
            <Icon type="logo" color={theme.colors.Primary.White} />
            <Image
              width="232"
              src="/assets/images/genisys.svg"
              alt="logo"
              className="noselect"
            />
          </Flex>
        </Box>
        <Form testId="login-form" onSubmit={onSubmit}>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Typography style={{ marginBottom: "3rem" }} variant="headline4">
              {t("pages.login.login")}
            </Typography>
            <TextField
              label={t("pages.login.email")}
              name="email"
              dom={{
                placeholder: t("pages.login.email"),
                type: "email",
                required: true,
              }}
              error={{
                typeMismatch: t("pages.login.errors.email.typeMismatch"),
                valueMissing: t("pages.login.errors.email.valueMissing"),
              }}
            />
            <TextField
              label={t("pages.login.password")}
              name="password"
              dom={{
                placeholder: t("pages.login.password"),
                type: "password",
                required: true,
              }}
              error={{
                valueMissing: t("pages.login.errors.password.valueMissing"),
              }}
            />
            <Flex
              className="controll"
              justifyContent="space-between"
              alignItems="center"
            >
              <Checkbox name="remember" label={t("pages.login.remember")} />
              <span>
                <SubmitButton
                  testId="login-submit"
                  color="darker"
                  shape="square"
                >
                  {t("pages.login.confirm")}
                </SubmitButton>
              </span>
            </Flex>
          </Flex>
        </Form>
      </Grid>
    </AuthPageTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  height: 100vh;

  & .box.left {
    position: relative;
    overflow: hidden;

    & .flex {
      position: relative;
      z-index: 3;

      & .icon {
        width: 4rem;
        height: 4rem;
        margin-bottom: 1.5rem;

        & svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  & .form {
    padding: 5rem;
    box-sizing: border-box;
    & > .flex {
      & > * {
        width: 100%;
        max-width: 25rem;

        //TODO replace with colGap
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;

        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }

    & .controll {
      height: auto;
      margin-top: 2rem;
    }
  }
`;
