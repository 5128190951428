import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Icon } from "ui/atoms/Icon";
import { Button } from "ui/atoms/Button";
import { Input, Props as InputProps } from "ui/atoms/Input";

// templates
import { FieldTemplate, Props as FieldProps } from "ui/templates/Field";

export type Props = InputProps &
  FieldProps & {
    label?: string;
    loading?: boolean;
    inputClassName?: string;
  };

export const TextField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [toggle, setToggle] = React.useState<boolean>(false); // only when password

  function click() {
    setToggle(!toggle);
  }

  let customType = props.dom?.type;
  if (customType === "password") {
    if (toggle) customType = "text";
  }

  const { dom, testId, ...rest } = props;

  return (
    <FieldTemplate
      {...rest}
      className={cx(props.className, styles(props, theme), "text-field")}
    >
      <span className="input-wrapper-icon">
        <Input
          {...props}
          className={props.inputClassName}
          dom={{ ...props.dom, type: customType }}
        />
        {props.dom?.type === "password" && (
          <Button onClick={click} shape="plain">
            <Icon
              type="eye"
              color={
                toggle ? theme.colors.Neutral.Gray3 : theme.colors.Neutral.Gray4
              }
            />
          </Button>
        )}
      </span>
    </FieldTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .input-wrapper-icon {
    position: relative;

    & > .button {
      position: absolute;

      z-index: 4;
      top: 0;
      right: 0;
      transform: translate(-50%, -25%);
      padding: 0;
    }
  }
`;
