// utils
import { ID } from "utils/types/model";
import { doFetch, getURL } from "utils/api/utils";

// locals
import { ISubmit } from "./types";

export async function fetchSurvey(id: ID) {
  return await doFetch(`/api/surveys/${id}`);
}

export async function fetchCategories() {
  return await doFetch("/api/productCategories");
}

export async function fetchLanguages() {
  return await doFetch("/api/languages");
}

export async function fetchCountries() {
  return await doFetch("/api/countries");
}

export async function fetchfragranceFamilies() {
  return await doFetch("/api/fragranceFamilies");
}

export async function fetchfragranceSubfamilies() {
  return await doFetch("/api/fragranceSubfamilies");
}

export async function fetchfragranceSpecifiers() {
  return await doFetch("/api/fragranceSpecifiers");
}

export async function deleteSurvey(id: ID) {
  return await doFetch(`/api/surveys/${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function create(survey: ISubmit) {
  return await doFetch("/api/surveys", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(survey),
  });
}

export async function update(survey: ISubmit) {
  return await doFetch(`/api/surveys/${survey.id}`, {
    method: "put",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(survey),
  });
}

export async function publish(survey: ISubmit) {
  return await doFetch(`/api/surveys/${survey.id}/publish`, {
    method: "put",
  });
}

export async function unpublish(survey: ISubmit) {
  return await doFetch(`/api/surveys/${survey.id}/unpublish`, {
    method: "put",
  });
}

export async function uploadCsv(data: { id: ID; csv: File }) {
  const formData = new FormData();
  formData.append("csv", data.csv);

  return await doFetch(`/api/surveys/${data.id}/uploadCsv`, {
    method: "post",
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
    body: formData,
  });
}
