import { AttributeType, IAttribute } from "utils/model/Attribute";

const innerData = {
  id: 0,
  imageLocation: "",
  translation: "translation",
  type: "ATTRIBUTE" as AttributeType,
};

const data: IAttribute = {
  id: 0,
  warmup: false,
  attribute: innerData,
};

export const attributes: IAttribute[] = [
  { ...data },
  { ...data, attribute: { ...innerData, id: 1 } },
  { ...data, attribute: { ...innerData, id: 2 } },
  { ...data, attribute: { ...innerData, id: 3 } },
  { ...data, attribute: { ...innerData, id: 4 } },

  { ...data, id: 1, attribute: { ...innerData, id: 5, type: "COLOR" } },
  { ...data, id: 1, attribute: { ...innerData, id: 6, type: "COLOR" } },
  { ...data, id: 1, attribute: { ...innerData, id: 7, type: "COLOR" } },
  { ...data, id: 1, attribute: { ...innerData, id: 8, type: "COLOR" } },
  { ...data, id: 1, attribute: { ...innerData, id: 9, type: "COLOR" } },

  { ...data, id: 2, attribute: { ...innerData, id: 10, type: "MOOD" } },
  { ...data, id: 2, attribute: { ...innerData, id: 11, type: "MOOD" } },
  { ...data, id: 2, attribute: { ...innerData, id: 12, type: "MOOD" } },
  { ...data, id: 2, attribute: { ...innerData, id: 13, type: "MOOD" } },
  { ...data, id: 2, attribute: { ...innerData, id: 14, type: "MOOD" } },

  { ...data, id: 3, attribute: { ...innerData, id: 15, type: "BRAND" } },
  { ...data, id: 3, attribute: { ...innerData, id: 16, type: "BRAND" } },
  { ...data, id: 3, attribute: { ...innerData, id: 17, type: "BRAND" } },
  { ...data, id: 3, attribute: { ...innerData, id: 18, type: "BRAND" } },
  { ...data, id: 3, attribute: { ...innerData, id: 19, type: "BRAND" } },

  { ...data, id: 4, warmup: true, attribute: { ...innerData, id: 20 } },
  { ...data, id: 4, warmup: true, attribute: { ...innerData, id: 21 } },
  { ...data, id: 4, warmup: true, attribute: { ...innerData, id: 22 } },
  { ...data, id: 4, warmup: true, attribute: { ...innerData, id: 23 } },
  { ...data, id: 4, warmup: true, attribute: { ...innerData, id: 24 } },

  {
    ...data,
    id: 5,
    warmup: true,
    attribute: { ...innerData, id: 25, type: "COLOR" },
  },
  {
    ...data,
    id: 5,
    warmup: true,
    attribute: { ...innerData, id: 26, type: "COLOR" },
  },
  {
    ...data,
    id: 5,
    warmup: true,
    attribute: { ...innerData, id: 27, type: "COLOR" },
  },
  {
    ...data,
    id: 5,
    warmup: true,
    attribute: { ...innerData, id: 28, type: "COLOR" },
  },
  {
    ...data,
    id: 5,
    warmup: true,
    attribute: { ...innerData, id: 29, type: "COLOR" },
  },

  {
    ...data,
    id: 6,
    warmup: true,
    attribute: { ...innerData, id: 30, type: "MOOD" },
  },
  {
    ...data,
    id: 6,
    warmup: true,
    attribute: { ...innerData, id: 31, type: "MOOD" },
  },
  {
    ...data,
    id: 6,
    warmup: true,
    attribute: { ...innerData, id: 32, type: "MOOD" },
  },
  {
    ...data,
    id: 6,
    warmup: true,
    attribute: { ...innerData, id: 33, type: "MOOD" },
  },
  {
    ...data,
    id: 6,
    warmup: true,
    attribute: { ...innerData, id: 34, type: "MOOD" },
  },

  {
    ...data,
    id: 7,
    warmup: true,
    attribute: { ...innerData, id: 35, type: "BRAND" },
  },
  {
    ...data,
    id: 7,
    warmup: true,
    attribute: { ...innerData, id: 36, type: "BRAND" },
  },
  {
    ...data,
    id: 7,
    warmup: true,
    attribute: { ...innerData, id: 37, type: "BRAND" },
  },
  {
    ...data,
    id: 7,
    warmup: true,
    attribute: { ...innerData, id: 38, type: "BRAND" },
  },
  {
    ...data,
    id: 7,
    warmup: true,
    attribute: { ...innerData, id: 39, type: "BRAND" },
  },
];
