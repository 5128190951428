import React from "react";
import {css, cx} from "@emotion/css";

// utils
import {IProps} from "utils/types/html";
import {Theme} from "utils/types/theme";
import {useTheme} from "utils/theme";

// atoms
import {Typography} from "ui/atoms/Typography";

// molecules
import {Logo} from "ui/molecules/Logo";

// templates
import {TesterBasicTemplate} from "ui/templates/TesterBasic";

export interface Props extends IProps<HTMLDivElement> {
  title: string;
}

export const TestEnd: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <TesterBasicTemplate
      {...props}
      className={cx(props.className, styles(props, theme), "test-end")}
    >
      {/* <Logo logoColor={theme.colors.Primary.Crimson} /> */}
      <Typography testId={props.testId + "-title"} variant="headline6">
        {props.title}
      </Typography>
    </TesterBasicTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
