import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { useTheme } from "utils/theme";
import { Theme } from "utils/types/theme";
import { TabTypes } from "utils/types/survey";

// atoms
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";
import { Typography } from "ui/atoms/Typography";

export interface Props extends IProps<HTMLDivElement> {
  tab: TabTypes;
  setTab(tab: TabTypes): void;
}

export const Footer: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  let nexttab: Tab = "";
  let prevtab: Tab = "";
  const options: TabTypes[] = [
    "basic",
    "product",
    "statements",
    "instructions",
  ];
  const index = options.findIndex((o) => o === props.tab);

  if (index !== 0) prevtab = options[index - 1];
  if (index !== options.length - 1) nexttab = options[index + 1];

  function go(tab: Tab) {
    return () => {
      if (tab !== "") {
        props.setTab(tab);
      }
    };
  }

  return (
    <Flex
      {...props}
      justifyContent="space-between"
      alignItems="center"
      className={cx(props.className, styles(props, theme), "footer")}
    >
      {props.tab !== "basic" && (
        <Button
          testId={props.testId + "-prev"}
          shape="plain"
          onClick={go(prevtab)}
        >
          <Flex alignItems="center" colGap="0.6rem">
            <Icon type="arrow" />
            <Typography>
              {t("common.backto", {
                name: t(`common.survey.tabs.${prevtab}`),
              })}
            </Typography>
          </Flex>
        </Button>
      )}
      {props.tab === "basic" && <span />}
      {props.tab !== "instructions" && (
        <Button
          testId={props.testId + "-next"}
          shape="plain"
          onClick={go(nexttab)}
        >
          <Flex alignItems="center" colGap="0.6rem">
            <Typography align="right">
              {t("common.goto", {
                name: t(`common.survey.tabs.${nexttab}`),
              })}
            </Typography>
            <Icon type="arrow" rotate={180} />
          </Flex>
        </Button>
      )}
      {props.tab === "instructions" && <span />}
    </Flex>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;

// types & interfaces
type Tab = TabTypes | "";
