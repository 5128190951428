import React from "react";
import { css, cx } from "@emotion/css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// utils
import { ISurvey } from "utils/model/Survey";
import { useTheme } from "utils/theme";

// atoms
import { Typography } from "ui/atoms/Typography";
import { Card } from "ui/atoms/Card";
import { Theme } from "utils/types/theme";

export interface Props extends ISurvey {
  testId?: string;
  className?: string;
}

export const SurveyCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card
      className={cx(
        props.className,
        styles(props, theme),
        "survey-card selectable"
      )}
      testId={props.testId}
      dom={{
        onClick: () => navigate(t("router.survey.route") + "/" + props.id),
      }}
    >
      <Typography
        style={{ width: "100%" }}
        variant="overline"
        color={theme.colors.Primary.Crimson}
      >
        {t(`survey.status.${props.status}`)}
      </Typography>
      <Typography variant="subtitle3">{props.name}</Typography>
      <Typography variant="paragraph">
        {t(`survey.date.${props.status}`, {
          date: moment(props.lastModification.time).format("DD/MM/YY"),
          user: props.lastModification.userName,
        })}
      </Typography>
    </Card>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .typography {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 0 ${theme.border.shadow.medium}
      ${theme.colors.Primary.BlackAlpha};
  }
`;
