import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AppContext } from "AppContext";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { info, log } from "utils/function/console";
import { TabTypes } from "utils/types/survey";

// models
import { ISurvey, SurveyStatus } from "utils/model/Survey";

// atoms
import { Box } from "ui/atoms/Box";
import { Button } from "ui/atoms/Button";
import { Flex } from "ui/atoms/Flex";
import { Grid } from "ui/atoms/Grid";
import { Icon } from "ui/atoms/Icon";
import { Input } from "ui/atoms/Input";

// molecules
import { Tabs } from "ui/molecules/Tabs";
import { useForm } from "utils/hook/form";

export interface Props extends IProps<HTMLDivElement> {
  tab: TabTypes;
  setTab(tab: TabTypes): void;
  survey?: ISurvey;
  copy?: boolean;

  onDelete(): void;
}

export const Header: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { errors } = useForm();
  const { t } = useTranslation();
  const { addSnackbar } = React.useContext(AppContext);
  // TODO check if
  const showSummary = props.survey?.progress
    ? props.survey.progress.current > 0
    : false;
  const navigate = useNavigate();
  const state = props.copy
    ? "publish"
    : props.survey?.status === "PUBLISHED"
    ? "unpublish"
    : "publish";
  const canDelete = props.survey?.id !== undefined;
  const url = props.survey?.url?.startsWith("undefined")
    ? props.survey.url.replace("undefined", window.location.origin + "/test")
    : undefined;

  function previewClick() {
    navigate(t("router.preview.redirect", { id: props.survey?.id }));
  }
  function summaryClick() {
    navigate(t("router.summary.redirect", { id: props.survey?.id }));
  }
  function deleteClick() {
    props.onDelete();
  }
  function copyClick() {
    if (navigator.clipboard) {
      if (url) {
        navigator.clipboard.writeText(url);

        addSnackbar({
          text: t("organisms.survey.header.copy.success"),
          type: "success",
        });
      }
    } else {
      addSnackbar({
        text: t("organisms.survey.header.copy.navigatorError"),
        type: "error",
      });
    }
  }

  return (
    <Flex
      {...props}
      direction="column"
      className={cx(styles(props, theme), "survey-header")}
      rowGap="0.8rem"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex colGap="1rem">
          <span>
            <Button
              testId={props.testId + "-" + props.survey}
              disabled={props.copy || Object.keys(errors).length > 0}
              type="submit"
              className="nowrap"
              name={state}
              color={state === "publish" ? "primary" : "orange"}
              value={state}
            >
              <Icon
                type={state === "publish" ? "check" : "checknot"}
                style={{ marginRight: "0.8rem" }}
              />
              {t(`organisms.survey.header.${state}`)}
            </Button>
          </span>
          <span>
            <Button
              className="nowrap"
              color="dark"
              testId={props.testId + "-preview"}
              onClick={previewClick}
            >
              <Icon type="eye" style={{ marginRight: "0.8rem" }} />
              {t("organisms.survey.header.preview")}
            </Button>
          </span>
          {!props.copy && showSummary && (
            <span>
              <Button
                className="nowrap"
                color="teal"
                testId={props.testId + "-summary"}
                onClick={summaryClick}
              >
                <Icon type="summary" style={{ marginRight: "0.8rem" }} />
                {t("organisms.survey.header.summary")}
              </Button>
            </span>
          )}
        </Flex>
        <Flex colGap="1rem">
          <span>
            <Button
              className="nowrap"
              testId={props.testId + "-delete"}
              onClick={deleteClick}
              border
              color="error"
              disabled={!canDelete}
            >
              <Icon type="delete" style={{ marginRight: "0.8rem" }} />
              {t("organisms.survey.header.delete")}
            </Button>
          </span>

          <span>
            <Button
              className="nowrap"
              testId={props.testId + "-savechanges"}
              type="submit"
              border
              color="darker"
              name="save"
              value="save"
            >
              <Icon type="checkpoint" style={{ marginRight: "0.8rem" }} />
              {props.copy || !props.survey?.id
                ? t("organisms.survey.header.savedraft")
                : t("organisms.survey.header.savechanges")}
            </Button>
          </span>
        </Flex>
      </Flex>
      {!props.copy && props.survey?.url && (
        <Flex justifyContent="center" alignItems="center" colGap="1rem">
          <Input dom={{ value: url }} />
          <Button
            className="nowrap"
            shape="square"
            color="success"
            onClick={copyClick}
          >
            <Icon type="copy" style={{ marginRight: "0.8rem" }} />
            {t("organisms.survey.header.copy.button")}
          </Button>
        </Flex>
      )}

      <Flex justifyContent="flex-start">
        <Tabs
          space="1rem"
          tabs={[
            {
              id: "basic",
              name: t("common.survey.tabs.basic"),
              badge: 0,
            },
            {
              id: "product",
              name: t("common.survey.tabs.product"),
              badge: 0,
            },
            {
              id: "statements",
              name: t("common.survey.tabs.statements"),
              badge: 0,
            },
            {
              id: "instructions",
              name: t("common.survey.tabs.instructions"),
              badge: 0,
            },
          ]}
          testId={props.testId + "-tabs"}
          selected={props.tab}
          setSelected={props.setTab}
        />
      </Flex>
    </Flex>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .nowrap {
    display: inline-flex; /* centering with icons */
  }

  & .top {
    height: 5rem;
  }
`;
