import React, { useEffect, useState } from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { useParams, useNavigate } from "react-router";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
// import { ISummary } from "utils/model/Summary";
import { info } from "utils/function/console";

// atoms
import { Button } from "ui/atoms/Button";
import { Flex } from "ui/atoms/Flex";
import { Icon } from "ui/atoms/Icon";
import { Typography } from "ui/atoms/Typography";

// molecules
import { RainbowBarHorizontal } from "ui/molecules/RainbowBarHorizontal";
import { ToggleGroup } from "ui/molecules/ToggleGroup";

// pages
import { fetchResultsCSV } from "ui/pages/Summary/http";
import { Context } from "../../pages/Summary/Context";
import { TextareaField } from "../../molecules/TextareaField";
import { NumberField } from "../../atoms/NumberField";
export interface Props extends IProps<HTMLDivElement> {}

export const HeaderSummary: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const {
    highYes,
    setHighYes,
    threshold,
    setThreshold,
    normalizedTime,
    setNormalizedTime,
  } = React.useContext(Context);

  const downloadCsvSummaryResults = () => {
    fetchResultsCSV(params.id as string);
  };

  function toggleHighYes() {
    setHighYes(!highYes);
  }

  function toggleNormalizedTime() {
    setNormalizedTime(!normalizedTime);
    setThreshold(normalizedTime ? 2.0 : -1.0);
  }

  function editClick() {
    if (params.id !== undefined) {
      navigate(t("router.survey.redirect", { id: params.id }));
    }
  }

  return (
    <Flex
      {...props}
      direction="column"
      className={cx(styles(theme), "headersummary")}
      noHeight
    >
      <Flex justifyContent="space-between" className="header" noHeight>
        <Typography variant="headline6">
          {t("organisms.headersummary.summaryofresults")}
        </Typography>
        <Flex colGap="0.75rem">
          <Button
            onClick={editClick}
            testId={props.testId + "-editSurvey"}
            border
            color="darker"
          >
            <Icon
              type="editblack"
              style={{
                marginRight: "0.8rem",
              }}
            />
            {t("organisms.headersummary.editsurvey")}
          </Button>
          <Button
            color="darker"
            testId={props.testId + "-preview"}
            onClick={downloadCsvSummaryResults}
          >
            <Icon type="export" style={{ marginRight: "0.8rem" }} />
            {t("organisms.headersummary.exportresponses")}
          </Button>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className="subHeader"
      >
        <Flex alignItems="center">
          <Typography variant="overline">
            {t("organisms.headersummary.levelcertainty")}
          </Typography>
          <RainbowBarHorizontal className="ml3 mr3" />
        </Flex>
        <ToggleGroup
          leftValue={t("organisms.headersummary.yes")}
          rightValue={t("organisms.headersummary.highyes")}
          className="highYesToggle"
          toggle={highYes}
          setToggle={toggleHighYes}
        />
        <Flex alignItems="center" style={{ backgroundColor: "white" }}>
          <ToggleGroup
            leftValue={t("organisms.headersummary.absoluteThreshold")}
            rightValue={t("organisms.headersummary.relativeThreshold")}
            className="thresholdToggle"
            toggle={normalizedTime}
            setToggle={toggleNormalizedTime}
          />
          <NumberField
            onChange={setThreshold}
            defaultValue={normalizedTime ? 2.0 : -1.0}
            value={threshold}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const styles = (theme: Theme) => css`
  padding-top: 1.5rem;
  .header {
    padding-left: 1.5rem;
  }
  .inputThreshold {
    width: 88px;
    margin-top: 1.5px;
  }
  .subHeader {
    background-color: white;
    height: 4rem;
    margin-top: 1.5rem;
    padding-left: 1.5rem;
    width: 100%;
  }
`;
