import { ISubFamily } from "utils/model/SubFamily";

export const subfamilies: ISubFamily[] = [
  { id: 0, name: "subfamily0" },
  { id: 1, name: "subfamily1" },
  { id: 2, name: "subfamily2" },
  { id: 3, name: "subfamily3" },
  { id: 4, name: "subfamily4" },
  { id: 5, name: "subfamily5" },
  { id: 6, name: "subfamily6" },
  { id: 7, name: "subfamily7" },
  { id: 8, name: "subfamily8" },
  { id: 9, name: "subfamily9" },
  { id: 10, name: "subfamily10" },
  { id: 11, name: "subfamily11" },
  { id: 12, name: "subfamily12" },
];
