import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// molecules
import { TestButton } from "ui/molecules/TestButton";

// templates
import { TesterBasicTemplate } from "ui/templates/TesterBasic";
import { Flex } from "ui/atoms/Flex";
import { Typography } from "ui/atoms/Typography";
import { CardTester } from "ui/molecules/CardTester";

export interface Props extends IProps {
  title: string;
  ok: string;
  // displayed: boolean;
  text: string;
  submit(time: number): void;
}

export const TestReading: React.FC<Props> = (props) => {
  const theme = useTheme();

  const startTime = performance.now();

  function click() {
    const timeToAnswer = performance.now() - startTime;
    props.submit(timeToAnswer);
  }

  return (
    <TesterBasicTemplate
      {...props}
      className={cx(props.className, styles(props, theme), "test-question")}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Typography variant="button3" style={{ width: "100%" }} align="left">
          {props.title}
        </Typography>
        <CardTester testId={props.testId + "-card"} question={props.text} />
        <TestButton
          color="primary"
          testId={props.testId + "-continue"}
          onClick={click}
        >
          {props.ok}
        </TestButton>
      </Flex>
    </TesterBasicTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;

// types & interfaces
