import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { info, log } from "utils/function/console";
import { HTTPError } from "utils/error/http";
import { Viewer } from "utils/function/authchecks";
import { IPaginationResponse } from "utils/types/api";

// models
import { ISurvey } from "utils/model/Survey";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";

// organisms
import { Header } from "ui/organisms/Header.home";
import { RecentSurveys } from "ui/organisms/RecentSurveys";
import { Table } from "ui/organisms/Table.survey";

// templates
import { SHTemplate } from "ui/templates/SidebarHeader";

// local
import { fetchSurveys, fetchRecentSurveys } from "./http";
import { fetchResultsCSV } from "../Summary/http";
import { Typography } from "ui/atoms/Typography";

export interface Props extends IProps<HTMLDivElement> {}

export const DashboardPage: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // states
  const [tab, setTab] = React.useState("overview");
  const [page, setPage] = React.useState(1);

  // http
  const surveys = useQuery<IPaginationResponse<ISurvey>, HTTPError>(
    "surveys",
    () => fetchSurveys(tab, page),
  );
  const mostRecentSurveys = useQuery<IPaginationResponse<ISurvey>, HTTPError>(
    "recent-survey",
    fetchRecentSurveys
  );

  // functions
  function favoriteClick(data: ISurvey) {
    info("fav", data);
  }
  function createClick() {
    navigate(t("router.survey.route"));
  }
  function nextPage() {
    if (!surveys.isLoading && page < ~~(surveys.data?.total||0)/20) {
      setPage(page + 1);
    }
  }
  function prevPage() {
    if (!surveys.isLoading && page > 1) {
      setPage(page - 1);
    }
  }

  React.useEffect(() => {
    surveys.refetch();
  }, [tab, page]);

  return (
    <SHTemplate
      authcheck={Viewer}
      {...props}
      variant={1}
      className={cx(
        props.className,
        styles(props, theme),
        "implicit-reaction-page"
      )}
    >
      <Grid container className="dashboard-grid" rows="25rem 1fr" rowGap="1rem">
        <Box padding={theme.padding.page}>
          <Header
            tab={tab}
            setTab={setTab}
            create={createClick}
            draft={0}
            published={0}
            completed={0}
            className="mb2"
          />
          <RecentSurveys surveys={mostRecentSurveys} />
        </Box>
        <Table
          surveys={surveys}
          className="dashboard-table"
          name={t(`pages.home.table.name.${tab}`)}
          favoriteClick={favoriteClick}
        />
        {(surveys.data?.items.length||0) > 0 && (
          <>
          <Flex colGap="1rem" justifyContent="center" alignItems="center">
            <Button shape="square" disabled={surveys.isLoading || page <= 1} onClick={prevPage}>{t("common.previousPage")}</Button>
            <Typography>{t("common.currentPage", {page})}</Typography>
            <Button shape="square" disabled={surveys.isLoading || page > ~~(surveys.data?.total||0)/20} onClick={nextPage}>{t("common.nextPage")}</Button>
          </Flex>
          <br />
          <br />
          </>
        )}
      </Grid>
    </SHTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  background-color: ${theme.colors.Neutral.Gray1};
  height: 100%;

  & main > .dashboard-grid {
    // padding: ${theme.padding.page};
    height: calc(100vh - ${theme.components.header.height}px + 21rem);
    // overflow-y: scroll;
    // overflow: hidden;

    & .dashboard-table {
      height: 100%;
      padding: ${theme.padding.page};

      & .items {
        overflow: hidden;
      }
    }
  }
`;
