import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Flex, Props as FlexProps } from "ui/atoms/Flex";
import { Grid } from "ui/atoms/Grid";
import { Typography } from "ui/atoms/Typography";

// molecules
import { TestButton, Props as ButtonProps } from "ui/molecules/TestButton";

// templates (weird)
import { TesterBasicTemplate } from "ui/templates/TesterBasic";

export interface Props extends IProps<HTMLDivElement> {
  title: string;
  left: ButtonProps;
  right: ButtonProps;
  alignBottom?: boolean;
  flexProps?: FlexProps;
  loading?: boolean;
}

export const TesterButtonsTemplate: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <TesterBasicTemplate
      className={cx(
        props.className,
        styles(props, theme),
        "tester-buttons-template"
      )}
    >
      <Grid
        {...props}
        cols="repeat(3, 1fr)"
        rows="auto 1fr"
        areas={["title title title", "left body right"]}
        className="main"
        container
      >
        <Grid item name="title">
          <Typography className="title" variant="button3">
            {props.title}
          </Typography>
        </Grid>
        <Grid item name="left">
          <Flex alignItems={props.alignBottom ? "flex-end" : "center"}>
            <TestButton
              disabled={props.loading}
              testId={props.testId + "-left"}
              {...props.left}
            />
          </Flex>
        </Grid>
        <Grid item name="body">
          <Flex
            justifyContent="center"
            alignItems="center"
            {...props.flexProps}
          >
            {props.children}
          </Flex>
        </Grid>
        <Grid item name="right">
          <Flex
            justifyContent="flex-end"
            alignItems={props.alignBottom ? "flex-end" : "center"}
          >
            <TestButton
              disabled={props.loading}
              testId={props.testId + "-right"}
              {...props.right}
            />
          </Flex>
        </Grid>
      </Grid>
    </TesterBasicTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .grid.main > .grid > .title {
    margin-left: calc(120px - ${theme.padding.testerpage});
  }
`;
