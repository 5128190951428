import { createContext } from "react";
import { UseQueryResult } from "react-query";

// utils
import { HTTPError } from "utils/error/http";
import { ISummary } from "utils/model/Summary";

export const Context = createContext<IContext>({
  summary: {} as UseQueryResult<ISummary, HTTPError>,
  setThreshold: () => null,
  normalizedTime: false,
  threshold: 2,
  setNormalizedTime: () => null,
  highYes: false,
  setHighYes: () => null,
});

export interface IContext {
  summary: UseQueryResult<ISummary, HTTPError>;
  threshold: number;
  setThreshold(threshold: number): void;
  normalizedTime: boolean;
  setNormalizedTime(normalizedTime: boolean): void;
  highYes: boolean;
  setHighYes(highYes: boolean): void;
}
