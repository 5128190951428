import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Typography } from "ui/atoms/Typography";

export interface Props extends IProps<HTMLDivElement> {}

export const AttributeBar: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      {...props}
      className={cx(props.className, styles(props, theme), "attribute-bar")}
      color={theme.colors.Secondary.Crimson}
      padding="1rem"
    >
      <Grid container cols="3rem 1fr" colGap="1rem">
        <Grid item className="line">
          <Typography color={theme.colors.Primary.White} variant="overline">
            {t("common.id")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color={theme.colors.Primary.White} variant="overline">
            {t("molecules.attributebar.related")}
          </Typography>
        </Grid>
      </Grid>
      {props.children}
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  border-radius: ${theme.border.radius.small};
  margin: 1rem 0;

  & .grid.line {
    border-right: 1px solid ${theme.colors.Primary.White};

    & > .typography {
      padding-left: 0.5rem;
    }
  }
`;
