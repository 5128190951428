import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { IAnswer } from "utils/types/test";

// atoms
import { Form } from "ui/atoms/Form";

// molecules
import { TestButton } from "ui/molecules/TestButton";
import { CardTester } from "ui/molecules/CardTester";

// templates
import {
  TesterButtonsTemplate,
  Props as PropsTemplate,
} from "ui/templates/TesterButtons";

type Mode = 'yes'|'no';
export interface Props extends IProps {
  title: string;
  yes: string;
  no: string;
  text: string;
  mode: Mode;
  continueClick(): void;
}

export const TestTraining: React.FC<Props> = (props) => {
  const theme = useTheme();

  function click(mode:Mode) {
    return () => {
      if (mode === props.mode) props.continueClick();
    }
  }

  return (
    <TesterButtonsTemplate
      {...props}
      left={{
        testId: props.testId + "-no",
        color: "darker",
        children: props.no,
        onClick: click('no'),
      }}
      right={{
        testId: props.testId + "-yes",
        color: "primary",
        children: props.yes,
        onClick: click('yes'),
      }}
      className={cx(props.className, styles(props, theme), "test-question")}
    >
      <CardTester question={props.text} />
    </TesterButtonsTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
