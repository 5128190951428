import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { info } from "utils/function/console";

// atoms
import { Box, Props as BoxProps } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";

// molecules
import { Tabs } from "ui/molecules/Tabs";

export interface Props extends BoxProps {
  draft: number;
  published: number;
  completed: number;
  tab: string;
  setTab(tab: string): void;
  create(): void;
}

export const Header: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  // functions
  function toggleSearchClick() {
    info("toggle search");
  }
  function createSurveyClick() {
    props.create();
  }

  return (
    <Box
      {...props}
      className={cx(props.className, styles(props, theme), "header")}
    >
      <Grid
        container
        cols="2fr 1fr auto auto"
        colGap="1rem"
        areas="tabs empty search create"
      >
        <Tabs
          tabs={[
            {
              id: "overview",
              name: t("organisms.headerhome.tabs.overview"),
              badge: 0,
            },
            {
              id: "draft",
              name: t("organisms.headerhome.tabs.draft"),
              badge: props.draft,
            },
            {
              id: "published",
              name: t("organisms.headerhome.tabs.published"),
              badge: props.published,
            },
            {
              id: "completed",
              name: t("organisms.headerhome.tabs.completed"),
              badge: props.completed,
            },
          ]}
          testId={props.testId + "-tabs"}
          selected={props.tab}
          setSelected={props.setTab}
        />

        <Button
          testId={props.testId + "-search"}
          className="search"
          shape="circle"
          color="primary"
          onClick={toggleSearchClick}
        >
          <Icon type="search" />
        </Button>

        <Button
          testId={props.testId + "-create"}
          className="create"
          color="darker"
          onClick={createSurveyClick}
        >
          <Icon type="plus" />
          {t("organisms.headerhome.create")}
        </Button>
      </Grid>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .tabs {
    grid-area: tabs;
  }
  & .search {
    grid-area: search;
  }
  & .create {
    grid-area: create;
  }
`;
