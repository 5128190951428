import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// local
import { Context } from "./context";
import { useDebounce } from "utils/hook/debounce";
import { log } from "utils/function/console";

export interface OptionProps extends IProps<HTMLOptionElement> {
  value: string;
  children: string;
}

export const Option: React.FC<OptionProps> = (props) => {
  const theme = useTheme();
  const { map, toggleValue, defaultValue } = React.useContext(Context);

  React.useEffect(() => {
    if (defaultValue !== undefined) {
      const values = defaultValue.toString().split(",");
      if (values.includes(props.value) && !map[props.value]) {
        toggle();
      }
    }
  }, [defaultValue]);

  function toggle() {
    toggleValue(props.value, props.children);
  }

  return (
    <option
      {...props.dom}
      value={props.value}
      className={cx(
        props.className,
        styles({ ...props, checked: !!map[props.value] }, theme),
        "option",
        "selectable"
      )}
      onClick={toggle}
      data-testid={props.testId}
    >
      {props.children}
    </option>
  );
};

// css design
const styles = (props: CSSProps, theme: Theme) => css`
  padding: 0.5rem 2rem;
  position: relative;
  cursor: pointer;

  &:after {
    position: absolute;
    left: 8px;
    top: 50%;
    height: 10px;
    width: 2rem;
    content: "";
    transform: translateY(calc(-50% - 1px));
    background: url("/assets/icons/${props.checked
        ? "checked"
        : "unchecked"}.svg")
      no-repeat;
  }
`;

// types & interfaces
type CSSProps = OptionProps & {
  checked: boolean;
};

Option.displayName = "Option";
