import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { useTheme } from "utils/theme";
import { Theme } from "utils/types/theme";

// templates
import { InputTemplate, Props as InputTemplateProps } from "ui/templates/Input";

export interface Props
  extends Omit<InputTemplateProps<HTMLInputElement>, "children"> {
  defaultValue?: string;
}

export const Input: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { dom, ...rest } = props;

  return (
    <InputTemplate {...rest} testId={props.testId + "-wrapper"}>
      {(onChange) => (
        <input
          name={props.name}
          {...dom}
          className={cx(
            styles(props, theme),
            props.className,
            "selector",
            "input"
          )}
          data-testid={props.testId}
          onInput={onChange}
          defaultValue={props.defaultValue}
        />
      )}
    </InputTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
