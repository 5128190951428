import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { themeContext, useTheme } from "utils/theme";

// atoms
import { Flex } from "ui/atoms/Flex";
import { Card } from "ui/atoms/Card";

export interface Props extends IProps<HTMLDivElement> {
  percentage: number;
  draw?: number;
}
const CircularIcon = (props: any) => {
  return (
    <svg viewBox="0 0 36 36" className="circular-chart">
      <path
        className="svg-back"
        fill="none"
        stroke={props.theme.colors.Neutral.Gray1}
        strokeWidth={3}
        d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        className="circle"
        strokeDasharray={`${props.percentage} 100`}
        stroke={props.color}
        d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
      />

      <text x={18} y={21} className="svg-circle-text">
        {props.percentage}%
      </text>
    </svg>
  );
};

function getGreenToRed(percent: number) {
  const r =
    percent < 50 ? 255 : Math.floor(255 - ((percent * 2 - 100) * 255) / 100);
  const g = percent > 50 ? 255 : Math.floor((percent * 2 * 255) / 100);
  return `rgb(${r},${g},0)`;
}

export const CardSummaryCircular: React.FC<Props> = (props) => {
  const theme = useTheme();
  const circumference = 2 * 8 * Math.PI;
  const draw = (props.percentage * circumference) / 100;
  const color = getGreenToRed(props.percentage);
  return (
    <Card
      {...props}
      className={cx(
        props.className,
        styles({ ...props, draw }, theme),
        "cardSummaryCircular"
      )}
    >
      <Flex justifyContent="center" alignItems="center">
        <CircularIcon
          color={color}
          theme={theme}
          percentage={props.percentage}
          offset={draw}
        />
      </Flex>
    </Card>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  width: ${theme.components.summaryCard.width};
  background-color: ${theme.colors.Primary.White};

  .svg-circle-text {
    font-size: 0.5rem;
    text-anchor: middle;
    fill: ${theme.colors.Primary.Black}
    font-weight: bold;
  }

  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
  }
  
  .circle {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

`;
