import React from "react";
import { css, cx } from "@emotion/css";
import { AppContext } from "AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { Pages } from "utils/types/router";
import { HTTPError } from "utils/error/http";
import { info, log, warn } from "utils/function/console";

// models
import { IUser } from "utils/model/User";

// atoms
import { Grid } from "ui/atoms/Grid";

// organisms
import { Header } from "ui/organisms/Header";
import { Sidebar } from "ui/organisms/Sidebar";
import { PageLoader } from "ui/organisms/PageLoader";

// templates - yes this is weird..
import { PageTemplate, Props as PageTemplateProps } from "ui/templates/Page";

// local
import { fetchAuthcheck } from "./http";
import { useTranslation } from "react-i18next";

export interface Props extends PageTemplateProps {
  authcheck(user: IUser | undefined): boolean;
}

export const AuthPageTemplate: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { setUser } = React.useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isLoading,
    data: user,
    error,
    refetch,
  } = useQuery<IUser, HTTPError>("authcheck", fetchAuthcheck, {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: false,
  });

  React.useEffect(() => {
    const datestr = window.localStorage.getItem("last-authcheck");

    const now = new Date();
    const maxtime = 1000 * 60 * 60; // 1 day

    if (!datestr || now.getTime() - Number(datestr) > maxtime) {
      info("refetch", process.env.NODE_ENV);
      refetch();
    }
  }, []);

  const loginRoute = t("router.login.route");

  React.useEffect(() => {
    if (error) {
      warn("WARNIGN: authcheck error", error);
      setUser();
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("last-authcheck");

      if (location.pathname !== loginRoute)
        navigate(loginRoute, { state: location });
    }
  }, [error]);

  React.useEffect(() => {
    if (user) {
      setUser(user);

      // NOTE route stopping
      if (!(user && props.authcheck(user)) && location.pathname !== "/") {
        navigate("/", { state: location });
      }
    }
  }, [user]);

  return (
    <PageTemplate
      {...props}
      className={cx(props.className, "sh-template", styles(props, theme))}
    >
      {!isLoading && props.children}
      {isLoading && <PageLoader />}
    </PageTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
