import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Popup, Props as PopupProps } from "ui/atoms/Popup";
import { Typography } from "ui/atoms/Typography";
import { Card } from "ui/atoms/Card";
import { Box } from "ui/atoms/Box";

export interface Props extends IProps {
  mode: "left" | "right" | "top" | "bottom";
  text: string;
  wrapperProps?: IProps<HTMLSpanElement>;
  minWidth?: string;
}

export const Tooltip: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const childWrapperRef = React.useRef<HTMLSpanElement>(null);
  const [boundary, setBoundary] = React.useState<Boundary>({
    x: -1,
    y: -1,
    width: -1,
    height: -1,
  });

  React.useEffect(() => {
    if (childWrapperRef.current) {
      setBoundary(childWrapperRef.current.getBoundingClientRect());
    }
  }, [childWrapperRef.current]);

  return (
    <Box
      {...props}
      className={cx(
        props.className,
        styles({ ...props, boundary }, theme),
        "tooltip",
        props.mode
      )}
    >
      <span
        {...(props.wrapperProps?.dom || {})}
        ref={childWrapperRef}
        style={props.wrapperProps?.style}
        data-testid={props.wrapperProps?.testId || props.testId + "-wrapper"}
        className={cx(props.wrapperProps?.className, "tooltip-child-wrapper")}
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
      >
        {props.children}
      </span>
      <Popup
        {...props}
        testId={props.testId + "-popup"}
        open={open}
        className="tooltip-popup"
      >
        <Card
          testId={props.testId + "-card"}
          radius="small"
          noBorder
          className="tooltip-card"
        >
          <Typography
            variant="button1"
            testId={props.testId + "-text"}
            color={theme.colors.Primary.White}
          >
            {props.text}
          </Typography>
        </Card>
      </Popup>
    </Box>
  );
};

// css design
const styles = (props: CSSProps, theme: Theme) => css`
  position: relative;
  & .tooltip-child-wrapper {
  }

  & .tooltip-popup {
    position: absolute;
    left: 0;
    top: 0;

    & .tooltip-card {
      background-color: ${theme.colors.Primary.Black};
      min-width: ${props.minWidth};

      &:after {
        position: absolute;
        content: "";
        width: 0;
      }
    }
  }

  &.left .tooltip-popup {
    top: 50%;
    transform: translate(calc(-100% - 1rem), -50%);

    & .tooltip-card:after {
      transform: translate(0, -50%);
      top: 50%;
      left: 100%;

      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid ${theme.colors.Primary.Black};
    }
  }
  &.right .tooltip-popup {
    top: 50%;
    left: 100%;
    transform: translate(1rem, -50%);

    & .tooltip-card:after {
      transform: translate(-100%, -50%);
      top: 50%;
      left: 0;

      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid ${theme.colors.Primary.Black};
    }
  }
  &.top .tooltip-popup {
    left: 50%;
    transform: translate(-50%, calc(-100% - 1rem));

    & .tooltip-card:after {
      transform: translate(-50%, 0);
      top: 100%;
      left: 50%;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${theme.colors.Primary.Black};
    }
  }
  &.bottom .tooltip-popup {
    left: 50%;
    top: 100%;
    transform: translate(-50%, 1rem);

    & .tooltip-card:after {
      transform: translate(-50%, -100%);
      top: 0;
      left: 50%;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid ${theme.colors.Primary.Black};
    }
  }
`;

// types & interfaces
type Boundary = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type CSSProps = Props & {
  boundary: Boundary;
};
