/* eslint-disable no-inner-declarations */
/* eslint-disable no-console */
import React from "react";
import {css, cx} from "@emotion/css";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

// utils
import {Theme} from "utils/types/theme";
import {useTheme} from "utils/theme";
import {IAnswer, IAuth} from "utils/types/test";
import {detectDevice} from "utils/function/device";

// models
import {IRotation} from "utils/model/Rotation";
import {AttributeType, IAttribute} from "utils/model/Attribute";

// atoms
import {Button} from "ui/atoms/Button";
import {Icon} from "ui/atoms/Icon";

// organisms
import {TestWelcome} from "ui/organisms/TestWelcome";
import {TestInstruction} from "ui/organisms/TestInstruction";
import {TestAuth} from "ui/organisms/TestAuth";
import {TestCallibration} from "ui/organisms/TestCallibration";
import {TestQuestion} from "ui/organisms/TestQuestion";
import {TestTraining} from "ui/organisms/TestTraining";
import {TestReading} from "ui/organisms/TestReading";
import {TestEnd} from "ui/organisms/TestEnd";
import {PageLoader} from "ui/organisms/PageLoader";

// templates
import {PageTemplate} from "ui/templates/Page";

// local
import {Props} from "./types";
import {Context} from "./Context";

export const TesterPage: React.FC<Props> = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const [part, setPart] = React.useState(0);
  const params = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = React.useState<IRotation[]>([]);

  const {survey, rotation, authenticate, answer, readingtime} =
    React.useContext(Context);

  function continueClick() {
    if (props.preview && part === 0) {
      setPart(2);
    } else setPart(() => part + 1);
  }

  function login(data: IAuth) {
    authenticate.mutateAsync(data).then(() => {
      continueClick();
    });
  }

  function submitReading(time: number) {
    readingtime.mutateAsync({time}).then(() => {
      continueClick();
    });
  }

  function submitAnswer(data: IAnswer) {
    answer.mutateAsync(data).then(() => {
      continueClick();
    });
  }

  React.useEffect(() => {
    if (rotation.data) {
      const sortedProducts = rotation.data.rotations.sort((info) => info.rank);
      setProducts(sortedProducts);
    }
  }, [rotation.data]);

  // questions
  const attributes = React.useMemo(() => {
    
    const warmup: AttributeSet = {
      ATTRIBUTE: [],
      BRAND: [],
      COLOR: [],
      IMAGE: [],
      MOOD: [],
    };
    const test: AttributeSet = {
      ATTRIBUTE: [],
      BRAND: [],
      COLOR: [],
      IMAGE: [],
      MOOD: [],
    };
    const data: AttributeData = {
      questions: [],
      warmup: [],
    }

    if (!rotation.data) return data;

    rotation.data.surveyAttributes.forEach((attribute) => {
      if (attribute.warmup) warmup[attribute.attribute.type].push(attribute);
      else test[attribute.attribute.type].push(attribute);
    });

    function loop(set: QuestionValue[]) {
      return (a:IAttribute) => {
        set.push(a);
        set.push("callibration");
      }
    }

    // warmup.ATTRIBUTE.forEach(loop(data.warmup)); // order 1 
    // warmup.BRAND.forEach(loop(data.warmup)); //     ...
    // warmup.COLOR.forEach(loop(data.warmup));
    // warmup.MOOD.forEach(loop(data.warmup));
    // warmup.IMAGE.forEach(loop(data.warmup));     // order 5

    for (let i=0; i<rotation.data.rotations.length; i++) {
      const quetionset = randomizeFull(test);
      const questions: QuestionValue[] = [];

      // warmup before actual test
      warmup.ATTRIBUTE.forEach(loop(questions)); // order 1 
      warmup.BRAND.forEach(loop(questions)); //     ...
      warmup.COLOR.forEach(loop(questions));
      warmup.MOOD.forEach(loop(questions));
      
      quetionset.ATTRIBUTE.forEach(loop(questions)); // order 1 
      quetionset.BRAND.forEach(loop(questions)); //     ...
      quetionset.COLOR.forEach(loop(questions));
      quetionset.MOOD.forEach(loop(questions));

      // NOTE images comes later
      warmup.IMAGE.forEach(loop(questions));     // order 5
      quetionset.IMAGE.forEach(loop(questions));     // order 5

      // removing last calibration 
      questions.splice(questions.length-1, 1);
      data.questions.push(questions);
    }

    return data;
  }, [rotation.data]);

  // removing logo
  // if (survey.isLoading) {
  //   return <PageLoader testId={props.testId + "-loading-screen"} />;
  // }

  if (survey.isError) {
    return <h1>something went wrong.. try again later</h1>; // FIXME snackbar ?
  }

  if (!survey.data) {
    return null;
  }
  if (!survey.data.instruction) {
    return null;
  }

  const isMobile = ["pad", "mobile"].includes(detectDevice());

  function getNext() {
    if (!survey.data) return null;

    // question
    const d = 12; // + attributes.warmup.length; // default steps (previous)

    // if (part >= 12 && part < d) {
    //   const warmupindex = part - 12;
    //   return 'helo warmup'
    // }

    const size = attributes.questions.length > 0 ? attributes.questions[0].length + 2 : 0;
    const productindex = ~~((part - d) / size);

    // TODO product index must be checked (so it doesnt skip some questions)
    // TODO need to reset questionindex to 0 when new company 

    if (
      productindex < products.length &&
      part >= d &&
      part <= size * products.length + d
    ) {
      const questionindex = (part - d) - (size * productindex);

      if (questionindex === 0)
        return (
          <TestInstruction
            title={survey.data.instruction.experienceProductInstruction}
            continue={survey.data.instruction.continueButtonInstruction}
            continueClick={continueClick}
          />
        );
      else if (questionindex === 1)
        return (
          <TestInstruction
            title={survey.data.instruction.testInstructionLong}
            continue={survey.data.instruction.continueButtonInstruction}
            continueClick={continueClick}
          />
        );
      else {
        const rotation = products[productindex];
        const question = attributes.questions[productindex][questionindex - 2];
        // const info = attributes.map[questionindex - 2];

        if (question === "callibration") {
          // if (!isMobile) {
          //   continueClick();
          //   return null;
          // }

          return (
            <TestCallibration
              title={isMobile ? survey.data.instruction.fingerprintInstruction : survey.data.instruction.calibrationText}
              continueClick={continueClick}
              isMobile={isMobile}
            />
          );
        }
        return (
          <TestQuestion
            title={survey.data.instruction.textInstructionShort}
            yes={survey.data.instruction.yesText}
            no={survey.data.instruction.noText}
            rotationId={rotation.id}
            rank={rotation.rank}
            onSubmit={submitAnswer}
            loading={answer.isLoading}
            attribute={{
              id: question.id,
              text: question.attribute.translation,
              image: question.attribute.imageLocation,
            }}
          />
        );
      }
    }

    switch (part) {
      case 0:
        return (
          <TestWelcome
            continue={survey.data.instruction.continueButtonInstruction}
            welcome={survey.data.instruction.welcome}
            continueClick={continueClick}
          />
        );
      case 1:
        return (
          <TestAuth
            label={survey.data.instruction.userIdPrompt}
            continue={survey.data.instruction.continueButtonInstruction}
            token={params.token as string}
            login={login}
          />
        );
      case 2:
        return (
          <TestInstruction
            title={survey.data.instruction.warmupYesNoInstructionLong}
            continue={survey.data.instruction.continueButtonInstruction}
            continueClick={continueClick}
          />
        );
      case 3:
        return (
          <TestTraining
            mode="yes"
            title={survey.data.instruction.warmupYesNoInstructionShort}
            yes={survey.data.instruction.yesText}
            no={survey.data.instruction.noText}
            text={survey.data.instruction.yesText}
            continueClick={continueClick}
          />
        );
      case 4:
        // if (!isMobile) {
        //   continueClick();
        //   return null;
        // }

        return (
          <TestCallibration
            title={isMobile ? survey.data.instruction.fingerprintInstruction : survey.data.instruction.calibrationText}
            continueClick={continueClick}
            isMobile={isMobile}
          />
        );
      case 5:
        return (
          <TestTraining
            mode="no"
            title={survey.data.instruction.warmupYesNoInstructionShort}
            yes={survey.data.instruction.yesText}
            no={survey.data.instruction.noText}
            text={survey.data.instruction.noText}
            continueClick={continueClick}
          />
        );
      case 6:
        return (
          <TestInstruction
            title={survey.data.instruction.readSpeedInstructionLong}
            continue={survey.data.instruction.continueButtonInstruction}
            continueClick={continueClick}
          />
        );
      case 7:
        return (
          <TestReading
            title={survey.data.instruction.readSpeedInstructionShort}
            text={survey.data.instruction.readSpeedStatement1}
            submit={submitReading}
            ok={survey.data.instruction.okText}
          />
        );
      case 8:
        // if (!isMobile) {
        //   continueClick();
        //   return null;
        // }

        return (
          <TestCallibration
            title={isMobile ? survey.data.instruction.fingerprintInstruction : survey.data.instruction.calibrationText}
            continueClick={continueClick}
            isMobile={isMobile}
          />
        );
      case 9:
        return (
          <TestReading
            title={survey.data.instruction.readSpeedInstructionShort}
            text={survey.data.instruction.readSpeedStatement2}
            submit={submitReading}
            ok={survey.data.instruction.okText}
          />
        );
      case 10:
        // if (!isMobile) {
        //   continueClick();
        //   return null;
        // }

        return (
          <TestCallibration
            title={isMobile ? survey.data.instruction.fingerprintInstruction : survey.data.instruction.calibrationText}
            continueClick={continueClick}
            isMobile={isMobile}
          />
        );
      case 11:
        return (
          <TestReading
            title={survey.data.instruction.readSpeedInstructionShort}
            text={survey.data.instruction.readSpeedStatement3}
            submit={submitReading}
            ok={survey.data.instruction.okText}
          />
        );
      default:
        return <TestEnd title={survey.data.instruction.endSurveyMessage} />;
    }
  }
  function goBack() {
    navigate(t("router.survey.route") + "/" + params.id);
  }

  return (
    <PageTemplate
      {...props}
      className={cx(props.className, styles(props, theme), "survey-test")}
    >
      <React.Suspense fallback={<PageLoader />}>{getNext()}</React.Suspense>
      {props.preview && (
        <Button
          className="preview-goback"
          onClick={goBack}
          shape="circle"
          color="primary"
        >
          <Icon type="arrow" />
        </Button>
      )}
    </PageTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .preview-goback {
    position: absolute;
    bottom: 0;
    left: 10%;

    transform: translate(-50%, -50%);
  }
`;

// types & interfaces
type AttributeSet = {
  ATTRIBUTE: IAttribute[];
  BRAND: IAttribute[];
  COLOR: IAttribute[];
  IMAGE: IAttribute[];
  MOOD: IAttribute[];
};
type QuestionValue = IAttribute | "callibration";
type AttributeMapValue = {
  variant: "warmup" | "test";
  type: AttributeType;
  index: number;
};
type AttributeData = {
  warmup: QuestionValue[];
  questions: QuestionValue[][];
};

// functions
function randomizeFull(set:AttributeSet):AttributeSet {
  return {
    ATTRIBUTE: randomize(set.ATTRIBUTE),
    BRAND: randomize(set.BRAND),
    COLOR: randomize(set.COLOR),
    IMAGE: randomize(set.IMAGE),
    MOOD: randomize(set.MOOD),
  };
}
function randomize<T>(arr:T[]) {
  const copy = [...arr]; 
  for (let i = 0; i < copy.length * 2; i++) {
    const a = Math.round((copy.length - 1) * Math.random());
    const b = Math.round((copy.length - 1) * Math.random());
    swap<T>(copy, a, b);
  }

  return copy;
}
function swap<T>(arr: T[], ia: number, ib: number) {
  const temp = arr[ia];

  arr[ia] = arr[ib];
  arr[ib] = temp;
}
