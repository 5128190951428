import React from "react";
import { css, cx } from "@emotion/css";
import { keyframes } from "@emotion/core";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// utils
import { IProps } from "utils/types/html";

export interface Props extends IProps {
  width?: number | string;
  height?: number | string; // default would be 1rem
  duration?: number;
  rounded?: string;
}

export const Skeleton: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      {...props.dom}
      className={cx(props.className, styles(props, theme), "skeleton")}
      data-testid={props.testId}
      style={props.style}
    >
      <div className="mover">
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  background-color: ${theme.colors.Neutral.Gray4};
  position: relative;
  overflow: hidden;

  border-radius: ${props.rounded};

  width: ${getValue(props.width) || "100%"};
  height: ${getValue(props.height) || "1rem"};

  & div.mover {
    position: absolute;
    top: -25%;
    left: calc(-100% - 150px);
    animation: ${move} ${props.duration || 2}s ease-out infinite;
    width: 10rem;
    height: 150%;

    & span {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 8rem;
      height: 100%;
      background-color: ${theme.colors.Primary.White};
      filter: blur(100px);

      &:nth-child(2) {
        filter: blur(60px);
        width: 4rem;
      }

      &:nth-child(3) {
        filter: blur(30px);
        width: 1rem;
      }
    }
  }
`;

const move = keyframes`
  from {
    left: calc(-100% - 150px);
  }
  to {
    left: calc(100% + 150px);
  }
`;

function getValue(value?: number | string) {
  if (value) {
    if (typeof value === "number") return `${value}px`;
    return value;
  }
  return undefined;
}
