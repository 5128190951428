import { IFamily } from "utils/model/Family";

export const families: IFamily[] = [
  { id: 0, name: "family0" },
  { id: 1, name: "family1" },
  { id: 2, name: "family2" },
  { id: 3, name: "family3" },
  { id: 4, name: "family4" },
  { id: 5, name: "family5" },
  { id: 6, name: "family6" },
  { id: 7, name: "family7" },
  { id: 8, name: "family8" },
  { id: 9, name: "family9" },
  { id: 10, name: "family10" },
  { id: 11, name: "family11" },
  { id: 12, name: "family12" },
];
