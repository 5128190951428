import React from "react";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "AppContext";

// utils
import { IConfig } from "utils/types/router";
import { HTTPError } from "utils/error/http";

// models
import { ID } from "utils/types/model";
import { IFamily } from "utils/model/Family";
import { ISpecifier } from "utils/model/Specifier";
import { ISubFamily } from "utils/model/SubFamily";
import { ILanguage } from "utils/model/Language";
import { ICountry } from "utils/model/Country";
import { IProductCategory } from "utils/model/ProductCategory";
import { ISurvey } from "utils/model/Survey";

import { SurveyPage as Component } from "./Component";
import { Context } from "./Context";
import { Props, ISubmit } from "./types";

// http
import {
  fetchSurvey,
  fetchCategories,
  fetchCountries,
  fetchLanguages,
  fetchfragranceFamilies,
  fetchfragranceSubfamilies,
  fetchfragranceSpecifiers,
  deleteSurvey as deleteSurveyHttp,
  uploadCsv as uploadCsvHttp,
  publish as publishHttp,
  unpublish as unpublishHttp,
  create as createHttp,
  update as updateHttp,
} from "./http";
import { log } from "utils/function/console";

export * from "./Component";
export * from "./types";

export const SurveyPage = (props: Props) => {
  const csvRef = React.useRef<{ csv: File; id?: ID } | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const { addSnackbar } = React.useContext(AppContext);

  const id = props.test || params.id;
  let survey: UseQueryResult<ISurvey, HTTPError> | undefined;

  //#region http
  // check if id exists, if it does we need to fetch data accoringly
  if (typeof id === "string") {
    // info("http survey fetch");
    survey = useQuery<ISurvey, HTTPError>("survey", () => fetchSurvey(id));
  }
  const categories = useQuery<IProductCategory[], HTTPError>(
    "categories",
    fetchCategories
  );
  const countries = useQuery<ICountry[], HTTPError>(
    "countries",
    fetchCountries
  );
  const languages = useQuery<ILanguage[], HTTPError>(
    "languages",
    fetchLanguages
  );
  const families = useQuery<IFamily[], HTTPError>(
    "families",
    fetchfragranceFamilies
  );
  const specifiers = useQuery<ISpecifier[], HTTPError>(
    "specifiers",
    fetchfragranceSpecifiers
  );
  const subfamilies = useQuery<ISubFamily[], HTTPError>(
    "subfamilies",
    fetchfragranceSubfamilies
  );
  const deleteSurvey = useMutation<boolean, HTTPError, ID>(deleteSurveyHttp, {
    onSuccess: () => {
      navigate(t("router.home.route"));
    },
    onError: (response) => {
      response.error.forEach((e) => {
        addSnackbar({
          text: e.message,
          type: "error",
        });
      });
    },
  });
  const uploadCsv = useMutation<unknown, HTTPError, { id: ID; csv: File }>(
    uploadCsvHttp,
    {
      onSuccess: () => {
        if (csvRef.current) {
          // it was a create
          window.location.href = `${window.location.origin}${t(
            "router.survey.route"
          )}/${csvRef.current.id}`;
        }
      },
      onError: (response) => {
        response.error.forEach((e) => {
          addSnackbar({
            text: e.message,
            type: "error",
          });
        });
      },
    }
  );
  const publish = useMutation<ISurvey, HTTPError, ISubmit>(publishHttp, {
    onSuccess: () => {
      survey?.refetch();

      addSnackbar({
        text: t("pages.survey.http.publish.success"),
        type: "success",
      });
    },
    onError: (response) => {
      response.error.forEach((e) => {
        addSnackbar({
          text: e.message,
          type: "error",
        });
      });
    },
  });
  const unpublish = useMutation<ISurvey, HTTPError, ISubmit>(unpublishHttp, {
    onSuccess: () => {
      survey?.refetch();

      addSnackbar({
        text: t("pages.survey.http.unpublish.success"),
        type: "success",
      });
    },
    onError: (response) => {
      response.error.forEach((e) => {
        addSnackbar({
          text: e.message,
          type: "error",
        });
      });
    },
  });
  const create = useMutation<ISurvey, HTTPError, ISubmit>(createHttp, {
    onSuccess: (data) => {
      if (csvRef.current && csvRef.current.csv.size !== 0) {
        csvRef.current.id = data.id;
        uploadCsv.mutate(csvRef.current as { csv: File; id: ID });
      } else {
        window.location.href = `${window.location.origin}${t(
          "router.survey.route"
        )}/${data.id}`;
      }
    },
    onError: (error) => {
      addSnackbar({
        text: error.error[0].message,
        type: "error",
      });
    },
  });
  const update = useMutation<ISurvey, HTTPError, ISubmit>(updateHttp, {
    onSuccess: () => {
      // TODO display success?
      addSnackbar({
        text: t("pages.survey.http.update.success"),
        type: "success",
      });
      survey?.refetch();
    },
    onError: (response) => {
      response.error.forEach((e) => {
        addSnackbar({
          text: e.message,
          type: "error",
        });
      });
    },
  });
  //#endregion

  const provides = {
    csvRef,
    survey,
    categories,
    countries,
    languages,
    families,
    specifiers,
    subfamilies,
    deleteSurvey,
    uploadCsv,
    publish,
    unpublish,
    create,
    update,
  };

  return (
    <Context.Provider value={provides}>
      <Component {...props} />
    </Context.Provider>
  );
};

export const Config: IConfig = {
  authcheck: (token, user) => {
    if (token && user) return true;

    return false;
  },
};
