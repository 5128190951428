import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Typography } from "ui/atoms/Typography";

// templates
import { TesterSingleTemplate } from "ui/templates/TesterSingle";
export interface Props extends IProps<HTMLDivElement> {
  continue: string;
  title: string;
  continueClick(): void;
}

export const TestInstruction: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <TesterSingleTemplate
      {...props}
      className={cx(props.className, styles(props, theme), "test-instruction")}
    >
      <Typography variant="button3" testId={props.testId + "-titletext"}>
        {props.title}
      </Typography>
    </TesterSingleTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
