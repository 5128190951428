import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Card } from "ui/atoms/Card";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";

export interface Props extends IProps<HTMLDivElement> {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}

export const CardClose: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Card
      {...props}
      className={cx(props.className, styles(props, theme), "card")}
    >
      <Button
        testId={props.testId + "-close"}
        className="close"
        shape="plain"
        onClick={props.onClose}
      >
        <Icon type="cross" />
      </Button>
      {props.children}
    </Card>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .close.button {
    position: absolute;
    padding: 0;
    top: 0.6rem;
    right: 0.6rem;
  }
`;
