import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Flex } from "ui/atoms/Flex";
import { Typography } from "ui/atoms/Typography";

import { error as errorlog } from "utils/function/console";

interface Props extends WithTranslation {
  name: string;
  fallbackUI?: React.ReactNode;
}
type State = {
  hasError: boolean;
};

class ErrorBoundaryClass extends React.Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // NOTE this should log it down somewhere (needs fix see setup.ts)
    errorlog(`Error Boundary Catch for ${this.props.name}`, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallbackUI) return this.props.fallbackUI;

      return (
        <Flex justifyContent="center" alignItems="center">
          <Typography variant="headline1">
            {this.props.t("error.boundary.fallback")}
          </Typography>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryClass);
