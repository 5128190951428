import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// util imports
import { Pages } from "utils/types/router";
import { IProps } from "utils/types/html";
import { useTheme } from "utils/theme";
import { SidebarVariant, Theme } from "utils/types/theme";

// atomic imports
import { Grid } from "ui/atoms/Grid";
import { Flex } from "ui/atoms/Flex";
import { Box } from "ui/atoms/Box";
import { List } from "ui/atoms/List";
import { Icon } from "ui/atoms/Icon";
import { Background } from "ui/atoms/Background";
import { Collapse } from "ui/atoms/Collapse";

// molecules imports
import { SidebarItem } from "ui/molecules/SidebarItem";
import { Image } from "ui/molecules/Image";
import { useLocation, useNavigate } from "react-router-dom";
import { log } from "utils/function/console";
import { getRouteName } from "utils/function/router";

// types & interfaces
interface Props extends IProps {
  variant?: SidebarVariant;
}

interface CSSProps extends Props {
  collapsed: boolean;
}

// defining Component as function component
export const Sidebar: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [collapsed, setCollapsed] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [hovering, setHover] = React.useState(false);

  function toggleMenu() {
    setCollapsed(!collapsed);
  }
  function select(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const target = e.currentTarget.getAttribute("id") as string;
    if (target === "traditionalSurveys") {
      setExpanded(!expanded);
    } else {
      navigate(t(`router.${target}.route`));
    }
    // else setPage(target as Pages);

    //TODO should we redirect to route?
  }
  function hover(value: boolean) {
    return () => {
      setHover(value);
    };
  }

  const cssProps: CSSProps = {
    ...props,
    collapsed,
  };

  const collapsedExtended = collapsed ? !hovering : collapsed;

  const routeName = getRouteName(location);

  return (
    <div
      data-testid={props.testId + "-wrapper"}
      style={props.style}
      className={cx(styles(cssProps, theme), "sidebar")}
    >
      <Collapse
        target="width"
        from={400}
        to={136}
        method="ease-out"
        duration={collapsed ? theme.animation.default : theme.animation.slow}
        expanded={collapsedExtended}
        testId={props.testId}
      >
        <Grid container rows={`${theme.components.header.height}px 8fr`}>
          <Grid item>
            <Box
              className={[
                headerStyles(cssProps, theme),
                "header",
                "hover",
                collapsedExtended ? "contracted" : "",
              ].join(" ")}
              testId={props.testId + "-header"}
              dom={{ onClick: toggleMenu }}
            >
              <Background
                shape="toroid"
                size={30}
                x="-5%"
                y="35%"
                rotate={10}
                color={getColor(cssProps, theme)}
              />
              <Background
                shape="toroid"
                className="header-background-right"
                size={45}
                x="87%"
                y="30%"
                color={getColor(cssProps, theme)}
              />
              <Grid
                container
                cols={collapsedExtended ? "1fr 0 0" : "2rem 1fr 2rem"}
                colGap={collapsedExtended ? 0 : "1rem"}
                alignItems="center"
              >
                <Flex
                  className="logo"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon type="logo" color={theme.colors.Primary.White} />
                </Flex>
                <Image
                  src="/assets/images/genisys.svg"
                  alt="logo text"
                  width={103}
                  className="noselect"
                />
                <Icon type="arrow" color={theme.colors.Primary.White} />
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            className={cx(bodyStyles(cssProps, theme), "body")}
            dom={{ onMouseEnter: hover(true), onMouseLeave: hover(false) }}
          >
            <Background
              shape="circle"
              size={90}
              x="30%"
              y="70%"
              color={getColor(cssProps, theme)}
            />
            <List>
              <SidebarItem
                icon="clock"
                id="home"
                testId="home"
                selected={routeName}
                collapsed={collapsedExtended}
                click={select}
              >
                {t("router.home.name")}
              </SidebarItem>
              <SidebarItem
                id="bigDataAnalytics"
                testId="bigDataAnalytics"
                icon="haircross"
                selected={routeName}
                collapsed={collapsedExtended}
                click={select}
              >
                {t("router.bigDataAnalytics.name")}
              </SidebarItem>
              <SidebarItem
                icon="file"
                selected="not-you" // making sure this can never be selected
                id="traditionalSurveys"
                testId="traditionalSurveys"
                click={select}
                collapsed={collapsedExtended}
                expanded={expanded}
              >
                {t("router.traditionalSurveys.name")}
              </SidebarItem>
              <Collapse
                target="height"
                from="0"
                to="100%"
                method="ease-out"
                expanded={expanded}
                testId="menu-collapse"
                style={{ width: "100%" }}
              >
                <SidebarItem
                  id="centralLocationTest"
                  testId="centralLocationTest"
                  selected={routeName}
                  collapsed={collapsedExtended}
                  click={select}
                  variant={props.variant || theme.components.sidebar.variant}
                >
                  {t("router.centralLocationTest.name")}
                </SidebarItem>
                <SidebarItem
                  id="homeUserTest"
                  testId="homeUserTest"
                  selected={routeName}
                  collapsed={collapsedExtended}
                  click={select}
                  variant={props.variant || theme.components.sidebar.variant}
                >
                  {t("router.homeUserTest.name")}
                </SidebarItem>
              </Collapse>
            </List>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};

const styles = (props: CSSProps, theme: Theme) => css`
  height: 100%;

  & > span {
    height: 100%;
    overflow: hidden;
    background-color: ${getColor(props, theme)};
  }
`;
const headerStyles = (props: CSSProps, theme: Theme) => css`
  background-color: ${getColor(props, theme)};
  height: 100%;
  position: relative;
  overflow: hidden;

  padding: 0 2rem;

  & .header-background-right {
    opacity: 1;
    transition: opacity ${theme.animation.fast}ms ease;
  }

  &.contracted {
    padding: 0;

    & .header-background-right {
      opacity: 0;
    }
    & .grid {
      justify-content: center;

      & .logo-text,
      & .hamburger {
        display: none;
      }
    }
  }

  & .grid {
    position: relative;
    z-index: 2;

    & .logo {
      padding: 0;
      max-width: 136px;
    }
  }
`;
const bodyStyles = (props: CSSProps, theme: Theme) => css`
  position: relative;

  & .list {
    position: relative;
    z-index: 2;
  }
`;

// helper functions
function getColor(props: CSSProps, theme: Theme, sub?: boolean) {
  if (props.variant === "dark" || theme.components.sidebar.variant === "dark")
    return theme.colors.Neutral.Gray2;

  return theme.colors.Secondary.Crimson;
}
