import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { useTheme } from "utils/theme";
import { Theme } from "utils/types/theme";

export interface Props extends IProps<HTMLSpanElement> {
  value: number;
  background?: string;
  color?: string;
}

export const Badge: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <span
      {...props.dom}
      className={cx(props.className, styles(props, theme), "badge")}
      data-testid={props.testId}
    >
      {props.value}
    </span>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  border-radius: 100px;
  background-color: ${props.background || theme.colors.Neutral.Gray3};
  padding: 0 16px;
  color: ${props.color || theme.colors.Primary.White};

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
`;
