/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from "react";

type unknownFunction = (...params: any[]) => void;
// eslint-disable-next-line @typescript-eslint/ban-types
export function useDebounce(cb: unknownFunction, delay: number) {
  const timer = useRef<NodeJS.Timeout | null>(null);

  function runner(...params: any[]) {
    clear();
    timer.current = setTimeout(() => {
      cb(...params);
    }, delay);
  }
  function clear() {
    if (timer.current) clearTimeout(timer.current);
  }

  return {
    run: runner,
    clear,
  };
}
