import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { IProps } from "utils/types/html";

// atoms
import { Box } from "ui/atoms/Box";
import { Typography, Variant } from "ui/atoms/Typography";
import { LoadComponent } from "ui/molecules/LoadComponent";

export interface Props extends IProps {
  label?: string;
  loading?: boolean;
  children?: React.ReactNode;
  labelVariant?: Variant;
}

export const FieldTemplate = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const theme = useTheme();

    return (
      <Box
        {...props}
        ref={ref}
        testId={props.testId ? props.testId + "-field" : ""}
        className={cx(props.className, styles(props, theme), "field")}
      >
        {props.label && (
          <Typography
            testId={props.testId + "-label"}
            variant={props.labelVariant || "overline"}
            className="label"
          >
            {props.label}
          </Typography>
        )}
        <LoadComponent rounded="8px" height="50px" loading={!!props.loading}>
          {props.children}
        </LoadComponent>
      </Box>
    );
  }
);

// css design
const styles = (props: Props, theme: Theme) => css`
  position: relative;

  &:focus {
    z-index: 2;
  }

  & .label {
    margin-bottom: 0.4rem;
    display: inline-block;
  }
`;
