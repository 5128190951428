import { HeaderVariant, SidebarVariant } from "utils/types/theme";

export default {
  name: "primary",
  colors: {
    Primary: {
      Crimson: "#822C2E",
      White: "#FFF",
      Black: "#000",
      BlackAlpha: "rgba(0,0,0,0.1)",
    },
    Secondary: {
      Crimson: "#9F3638",
    },
    Neutral: {
      Gray1: "#F7F7F7",
      Gray2: "#4E4E4E",
      Gray3: "#191919",
      Gray4: "#DCDCDC",
    },
    Accent: {
      Teal1: "#5D9196",
      Teal2: "#4B858A",
      Orange: "#E18D2C",
      Purple: "#7E2672",
    },
    Success: {
      Green1: "#C2E6C2",
      Green2: "#99DA98",
    },
    Error: {
      Red1: "#DD9393",
      Red2: "#CF6767",
    },
  },
  sizes: {
    MobileMin: 320,
    MobileMax: 480,
    PadMin: 481,
    PadMax: 768,
    LaptopMin: 769,
    LaptopMax: 1024,
    DesktopMin: 1025,
    DesktopMax: 1200,
  },
  padding: {
    page: "1.5rem",
    testerpage: "80px",
  },
  border: {
    button: "0.1rem",
    input: "1px",

    radius: {
      small: "8px",
      medium: "16px",
      large: "string;",
    },

    shadow: {
      small: "4px",
      medium: "8px",
      big: "16px",
    },
  },
  global: [
    `
    body {
      margin: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: 'Montserrat', sans-serif;
      background-color: #F7F7F7;
    }
    
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    .noselect {
      user-select: none;
    }
    .nowrap {
      white-space: nowrap;
    }
    .dotted {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .hover {
      cursor: pointer;
      position: relative;

      &:hover::after {
        content: '';
        pointer-events: none;
        width: 100%;
        height: 100%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: black;
        opacity: 0.1;
        z-index: 5;
      }
    }
    .selectable {
      cursor: pointer;
      position: relative;
      
      &:hover {
        &:before {
          z-index: 10;
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.1);
          pointer-events: none;
        }

        // background-color: #F7F7F7;

        &.outer {
          background-color: white !important;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
        }
      }
    }
    .mt1 { margin-top: 1rem; }
    .mt2 { margin-top: 2rem; }
    .mt3 { margin-top: 3rem; }
    .mt4 { margin-top: 4rem; }

    .mb1 { margin-bottom: 1rem; }
    .mb2 { margin-bottom: 2rem; }
    .mb3 { margin-bottom: 3rem; }
    .mb4 { margin-bottom: 4rem; }

    .ml1 { margin-left: 1rem; }
    .ml2 { margin-left: 2rem; }
    .ml3 { margin-left: 3rem; }
    .ml4 { margin-left: 4rem; }

    .mr1 { margin-right: 1rem; }
    .mr2 { margin-right: 2rem; }
    .mr3 { margin-right: 3rem; }
    .mr4 { margin-right: 4rem; }

    .width100 { width: 100%; }
    .height100 { height: 100%; }

    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    `,
  ],
  components: {
    header: {
      height: 112,
      variant: "primary" as HeaderVariant,
    },
    sidebar: {
      variant: "primary" as SidebarVariant,
    },
    snackbar: {
      timeout: 5000,
    },
    summaryCard: {
      width: "176px",
      height: {
        small: "80px",
      },
    },
  },
  animation: {
    default: 180,
    fast: 120,
    slow: 350,
  },
};
