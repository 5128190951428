import React from "react";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { useParams } from "react-router-dom";

import { AppContext } from "AppContext";

// utils
import { HTTPError } from "utils/error/http";
import { surveys } from "utils/mock/data/surveys"; // Faking
import { IAnswer, IAuth, IAuthResponds, IReadingTime } from "utils/types/test";

// models
import { ID } from "utils/types/model";
import { ISurvey } from "utils/model/Survey";
import { IRotationTester } from "utils/model/Rotation";

// locals
import { TesterPage as Component } from "./Component";
import { Props } from "./types";
import { Context } from "./Context";

// http
import {
  fetchSurvey,
  fetchRotation,
  readingtime as readingtimeHttp,
  authenticate as authenticateHttp,
  answer as answerHttp,
} from "./http";
import { log } from "utils/function/console";

export * from "./types";
export * from "./Component";

export function TesterPage(props: Props) {
  const params = useParams();
  const { addSnackbar } = React.useContext(AppContext);

  React.useEffect(() => {
    if (!props.preview) {
      window.localStorage.removeItem("token");
    }
  }, []);

  // http calls extended with preview & test
  function fetchSurveyE() {
    if (props.test) {
      return surveys[0];
    }

    return fetchSurvey(params.id as ID);
  }
  async function fetchRotationE() {
    if (props.test) {
      return getRotation(surveys[0]);
    }
    if (props.preview) {
      if (survey.data) {
        return getRotation(survey.data);
      }

      return null;
    }

    return fetchRotation();
  }
  async function authenticateHttpE(auth: IAuth) {
    if (props.test || props.preview) {
      return "token";
    }

    return authenticateHttp(auth);
  }
  async function answerHttpE(answer: IAnswer) {
    if (props.test || props.preview) {
      return true;
    }

    return answerHttp(answer);
  }
  async function readingtimeHttpE(data: IReadingTime) {
    if (props.test || props.preview) {
      return true;
    }

    return readingtimeHttp(data);
  }

  // fetch
  const survey = useQuery<ISurvey, HTTPError>("survey", fetchSurveyE, {
    onSettled: () => {
      if (props.preview) {
        window.setTimeout(() => {
          rotation.refetch();
        }, 1);
      }
    },
  });
  const rotation = useQuery<IRotationTester, HTTPError>(
    "rotation",
    fetchRotationE,
    {
      retry: false,
    }
  );

  const authenticate = useMutation<IAuthResponds, HTTPError, IAuth>(
    authenticateHttpE,
    {
      onSuccess: (data) => {
        window.localStorage.setItem("token", data.accessToken);
        rotation.refetch();
      },
      onError: (error) => {
        addSnackbar({
          text: error.error[0].message,
          type: "error",
        });
      },
    }
  );

  const answer = useMutation<unknown, HTTPError, IAnswer>(answerHttpE);

  const readingtime =
    useMutation<unknown, HTTPError, IReadingTime>(readingtimeHttpE);

  const provides = {
    survey,
    authenticate,
    readingtime,
    rotation,
    answer,
  };

  return (
    <Context.Provider value={provides}>
      <Component {...props} />
    </Context.Provider>
  );
}

// helper functions
function getRotation(survey: ISurvey): IRotationTester {
  return {
    respondant: {
      id: 0,
      status: "None",
      identifier: "0",
    },
    rotations: survey.products.map((p, index) => ({
      rank: index + 1,
      id: p.id as number,
      status: "None",
      productCode: "123",
    })),
    surveyAttributes: survey.surveyAttributes,
    answered: [],
  };
}
