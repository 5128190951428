import { css } from "@emotion/css";
import React from "react";

// utils
import { IProps } from "utils/types/html";

export interface Props extends IProps<HTMLUListElement> {}

export const List: React.FC<Props> = (props) => {
  return (
    <ul
      {...props.dom}
      className={[props.className, styles(props), "list"].join(" ")}
      data-testid={props.testId}
    >
      {React.Children.map(props.children, (child, index) => (
        <li key={index}>{child}</li>
      ))}
    </ul>
  );
};

// css design
const styles = (props: Props) => css`
  list-style: none;
  margin: 0;
  padding: 0;
`;
