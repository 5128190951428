import React, { ReactNode } from "react";
import { css, cx } from "@emotion/css";
import { keyframes } from "@emotion/core";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { info } from "utils/function/console";

// props
export interface Props extends IProps<HTMLButtonElement> {
  color?:
    | "white"
    | "lighter"
    | "light"
    | "primary"
    | "dark"
    | "darker"
    | "orange"
    | "teal"
    | "success"
    | "error"; // default is dark

  textVariant?: "button1" | "button2" | "button3"; // button1 is default // TODO remove this
  shape?: "plain" | "circle" | "square" | "square-small";
  border?: boolean;
  ripple?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseOver?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
  children?: React.ReactNode;
  name?: string;
  value?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const theme = useTheme();

    function click(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      if (props.ripple) addRipple(event);

      if (props.onClick) props.onClick(event);
    }

    return (
      <button
        type={props.type || "button"}
        {...props.dom}
        onMouseOver={props.onMouseOver}
        onMouseLeave={props.onMouseLeave}
        data-testid={props.testId}
        className={cx(
          props.className,
          styles(props, theme),
          "selectable",
          "button",
          props.textVariant ?? "button1",
          props.shape ?? "",
          props.border ? "bordered" : "",
          props.color
        )}
        ref={ref}
        onClick={click}
        style={props.style}
        disabled={props.disabled}
        name={props.name}
        value={props.value}
      >
        {props.children}
      </button>
    );
  }
);

// types & interfaces
type Colors = "";

// css design
const ripple = keyframes`
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(4);
    opacity: 0;
  }
`;

const styles = (props: Props, theme: Theme) => css`
  /* default styles */
  border: none;
  cursor: pointer;
  border-radius: 100px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  box-sizing: border-box;

  position: relative;
  overflow: hidden;

  & span.ripple {
    content: "";
    opacity: 0;
    position: absolute; /* The absolute position we mentioned earlier */
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.18);

    &.index0 {
      animation: ${ripple} 1000ms ease-out;
    }
    &.index1 {
      animation: ${ripple} 800ms ease-out;
    }
  }

  &.button1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
  &.button2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  &.button3 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
  &.plain {
    background-color: transparent !important;
    padding: 8px;
    border-radius: 0;
  }
  &.circle {
    border-radius: 50%;
    padding: 0.5rem;
  }
  &.square {
    border-radius: ${theme.border.radius.small};
  }
  &.square-small {
    border-radius: ${theme.border.radius.small};
    padding: 0;
  }
  &.white {
    background-color: ${theme.colors.Primary.White} !important;
    color: ${theme.colors.Primary.Black};

    & span.ripple {
      background-color: rgba(0, 0, 0, 0.18);
    }

    &.bordered {
      border-color: ${theme.colors.Primary.White};
      color: ${theme.colors.Primary.White};
    }
  }
  &.lighter {
    background-color: ${theme.colors.Neutral.Gray1} !important;
    color: ${theme.colors.Primary.Black};

    & .typography {
      color: ${theme.colors.Primary.Black};
    }

    &.bordered {
      border-color: ${theme.colors.Neutral.Gray1};
      color: ${theme.colors.Neutral.Gray1};
    }
  }
  &.light {
    background-color: ${theme.colors.Neutral.Gray4} !important;
    color: ${theme.colors.Primary.White};

    & .typography {
      color: ${theme.colors.Primary.White};
    }

    &.bordered {
      border-color: ${theme.colors.Neutral.Gray4};
      color: ${theme.colors.Neutral.Gray4};
    }
  }
  &.dark {
    background-color: ${theme.colors.Neutral.Gray2} !important;
    color: ${theme.colors.Primary.White};

    & .typography {
      color: ${theme.colors.Primary.White};
    }

    &.bordered {
      border-color: ${theme.colors.Neutral.Gray2};
      color: ${theme.colors.Neutral.Gray2};
    }
  }
  &.darker {
    background-color: ${theme.colors.Neutral.Gray3} !important;
    color: ${theme.colors.Primary.White};

    & .typography {
      color: ${theme.colors.Primary.White};
    }

    &.bordered {
      border-color: ${theme.colors.Neutral.Gray3};
      color: ${theme.colors.Neutral.Gray3};
    }
  }
  &.primary {
    background-color: ${theme.colors.Primary.Crimson} !important;
    color: ${theme.colors.Primary.White};

    & .typography {
      color: ${theme.colors.Primary.White};
    }

    &.bordered {
      border-color: ${theme.colors.Primary.Crimson};
      color: ${theme.colors.Primary.Crimson};
    }
  }
  &.error {
    background-color: ${theme.colors.Error.Red2} !important;
    color: ${theme.colors.Primary.White};

    & .typography {
      color: ${theme.colors.Primary.White};
    }

    &.bordered {
      border-color: ${theme.colors.Error.Red2};
      color: ${theme.colors.Error.Red2};
    }
  }
  &.orange {
    background-color: ${theme.colors.Accent.Orange} !important;
    color: ${theme.colors.Primary.White};

    & .typography {
      color: ${theme.colors.Primary.White};
    }

    &.bordered {
      border-color: ${theme.colors.Accent.Orange};
      color: ${theme.colors.Accent.Orange};
    }
  }
  &.teal {
    background-color: ${theme.colors.Accent.Teal1} !important;
    color: ${theme.colors.Primary.White};

    & .typography {
      color: ${theme.colors.Primary.White};
    }

    &.bordered {
      border-color: ${theme.colors.Accent.Teal1};
      color: ${theme.colors.Accent.Teal1};
    }
  }
  &.success {
    background-color: ${theme.colors.Success.Green1} !important;
    color: ${theme.colors.Primary.Black};

    & .typography {
      color: ${theme.colors.Primary.Black};
    }

    &.bordered {
      border-color: ${theme.colors.Success.Green1};
      color: ${theme.colors.Success.Green1};
    }
  }
  &.bordered {
    background-color: transparent !important;
    border-width: ${theme.border.button};
    border-style: solid;
  }
  &:hover {
  }
  &:focus {
  }
  &[disabled] {
    cursor: not-allowed;
  }
`;

// helper
function addRipple(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
  const button = event.currentTarget;
  for (let i = 0; i < 2; i++) {
    const ripple = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    ripple.style.width = ripple.style.height = `${diameter}px`;
    ripple.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    ripple.style.top = `${event.clientY - button.offsetTop - radius}px`;
    ripple.classList.add("ripple");
    ripple.classList.add("index" + i);
    ripple.style.animationFillMode = "forwards"; // NOTE

    button.appendChild(ripple);

    setTimeout(() => {
      if (ripple.isConnected) {
        ripple.parentElement?.removeChild(ripple);
      }
    }, 1000);
  }
}
