import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Grid } from "ui/atoms/Grid";

// organisms
import { Sidebar } from "ui/organisms/Sidebar";
import { Construction } from "ui/organisms/Construction";

// templates
import { PageTemplate } from "ui/templates/Page";

export interface Props extends IProps<HTMLDivElement> {}

export const ConstructionPage: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <PageTemplate
      {...props}
      className={cx(props.className, styles(props, theme), "construction-page")}
    >
      <Grid
        className="sidebar-tempalte-grid"
        style={props.style}
        container
        cols="auto 1fr"
      >
        <Sidebar />
        <Construction />
      </Grid>
    </PageTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .construction {
    min-height: 100vh;
  }
`;
