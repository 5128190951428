import { Location } from "react-router-dom";

export function getRouteName(location: Location) {
  const { pathname } = location;

  if (pathname === "/") return "home";
  if (/survey/.test(pathname)) return "survey";

  // NOTE add more details later..

  const match = /(\w+)/.exec(pathname);

  if (match) return match[0]; // will take the first e.g. /hello/world -> hello

  return pathname;
}
