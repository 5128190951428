import { ISpecifier } from "utils/model/Specifier";

export const specifiers: ISpecifier[] = [
  { id: 0, name: "specifier0" },
  { id: 1, name: "specifier1" },
  { id: 2, name: "specifier2" },
  { id: 3, name: "specifier3" },
  { id: 4, name: "specifier4" },
  { id: 5, name: "specifier5" },
  { id: 6, name: "specifier6" },
  { id: 7, name: "specifier7" },
  { id: 8, name: "specifier8" },
  { id: 9, name: "specifier9" },
  { id: 10, name: "specifier10" },
  { id: 11, name: "specifier11" },
  { id: 12, name: "specifier12" },
];
