import { createContext } from "react";
import { UseMutationResult, UseQueryResult } from "react-query";

// utils
import { HTTPError } from "utils/error/http";

// models
import { ID } from "utils/types/model";
import { ILanguage } from "utils/model/Language";
import { ICountry } from "utils/model/Country";
import { IProductCategory } from "utils/model/ProductCategory";
import { ISurvey } from "utils/model/Survey";
import { IFamily } from "utils/model/Family";
import { ISpecifier } from "utils/model/Specifier";
import { ISubFamily } from "utils/model/SubFamily";

// local
import { ISubmit } from "./types";

export const Context = createContext<IContext>({
  survey: undefined,
  categories: {} as UseQueryResult<IProductCategory[], HTTPError>,
  countries: {} as UseQueryResult<ICountry[], HTTPError>,
  languages: {} as UseQueryResult<ILanguage[], HTTPError>,
  families: {} as UseQueryResult<IFamily[], HTTPError>,
  specifiers: {} as UseQueryResult<ISpecifier[], HTTPError>,
  subfamilies: {} as UseQueryResult<ISubFamily[], HTTPError>,
  deleteSurvey: {} as UseMutationResult<unknown, HTTPError, ID>,
  uploadCsv: {} as UseMutationResult<unknown, HTTPError, { id: ID; csv: File }>,
  publish: {} as UseMutationResult<ISurvey, HTTPError, ISubmit>,
  unpublish: {} as UseMutationResult<ISurvey, HTTPError, ISubmit>,
  create: {} as UseMutationResult<ISurvey, HTTPError, ISubmit>,
  update: {} as UseMutationResult<ISurvey, HTTPError, ISubmit>,
  csvRef: {} as React.MutableRefObject<null>,
});

export interface IContext {
  survey: UseQueryResult<ISurvey, HTTPError> | undefined;
  categories: UseQueryResult<IProductCategory[], HTTPError>;
  countries: UseQueryResult<ICountry[], HTTPError>;
  languages: UseQueryResult<ILanguage[], HTTPError>;
  families: UseQueryResult<IFamily[], HTTPError>;
  specifiers: UseQueryResult<ISpecifier[], HTTPError>;
  subfamilies: UseQueryResult<ISubFamily[], HTTPError>;
  deleteSurvey: UseMutationResult<unknown, HTTPError, ID>;
  uploadCsv: UseMutationResult<unknown, HTTPError, { id: ID; csv: File }>;
  publish: UseMutationResult<ISurvey, HTTPError, ISubmit>;
  unpublish: UseMutationResult<ISurvey, HTTPError, ISubmit>;
  create: UseMutationResult<ISurvey, HTTPError, ISubmit>;
  update: UseMutationResult<ISurvey, HTTPError, ISubmit>;
  csvRef: React.MutableRefObject<{
    csv: File;
    id?: ID | undefined;
  } | null>;
}
