import { createContext } from "react";
import { ISnackbar } from "ui/molecules/Snackbar";
import { IUser } from "utils/model/User";
import { Languages } from "utils/translation/Types";
export interface IAppContext {
  loading: boolean;
  windowWidth: number;
  user?: IUser;
  setUser(user?: IUser): void;
  changeLanguage(lang: Languages): void;
  addSnackbar(snackbar: Omit<ISnackbar, "id">): void;
}

export const AppContext = createContext<IAppContext>({
  loading: true,
  windowWidth: window.innerWidth,
  setUser: (user) => null,
  changeLanguage: (lang) => null,
  addSnackbar: (snackbar) => null,
});
