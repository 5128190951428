// import { initReactI18next } from "react-i18next";
import i18next, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import { info, warn } from "utils/function/console";

import en from "./locales/en.json";
import se from "./locales/se.json";

export const resources = {
  en: {
    translation: en,
  },
  se: {
    translation: se,
  },
} as Resource;

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,
      lng: "en",
      fallbackLng: "en",
      // keySeparator: true, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false,
      },
    },
    function (err, res) {
      if (err) warn(err);
      else {
        info("i18next loaded successfully");
      }
    }
  );

export default i18next;
