import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { ISurvey } from "utils/model/Survey";
import { HTTPError } from "utils/error/http";
import { info } from "utils/function/console";

// atoms
import { Flex } from "ui/atoms/Flex";

// molecules
import { TextareaField } from "ui/molecules/TextareaField";

export interface Props extends IProps<HTMLDivElement> {
  survey?: UseQueryResult<ISurvey, HTTPError>;
}

export const Instructions: React.FC<Props> = (props) => {
  const theme = useTheme();

  info("")

  return (
    <Flex
      {...props}
      direction="column"
      rowGap="1rem"
      className={cx(props.className, styles(props, theme), "instructions")}
    >
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="welcome"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="userIdPrompt"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="userIdCheck"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="testInstructionLong"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="textInstructionShort"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="testInstructionImageShort"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="experienceProductInstruction"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="warmupYesNoInstructionLong"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="warmupYesNoInstructionShort"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="readSpeedInstructionLong"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="readSpeedInstructionShort"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="endSurveyMessage"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="continueButtonInstruction"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="fingerprintInstruction"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="calibrationText"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="yesText"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="noText"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="okText"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="readSpeedStatement1"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="readSpeedStatement2"
      />
      <Textarea
        data={props.survey?.data}
        loading={props.survey?.isLoading}
        name="readSpeedStatement3"
      />
      <br />
      <br />
    </Flex>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;

// types & interfaces
export interface ISubmit {
  instruction: {
    welcome: string;
    userIdPrompt: string;
    userIdCheck: string;
    testInstructionLong: string;
    textInstructionShort: string;
    testInstructionImageShort: string;
    experienceProductInstruction: string;
    warmupYesNoInstructionLong: string;
    warmupYesNoInstructionShort: string;
    readSpeedInstructionLong: string;
    readSpeedInstructionShort: string;
    endSurveyMessage: string;
    continueButtonInstruction: string;
    fingerprintInstruction: string;
    calibrationText: string;
    yesText: string;
    noText: string;
    okText: string;
    readSpeedStatement1: string;
    readSpeedStatement2: string;
    readSpeedStatement3: string;
  };
}

type Fields =
  | "welcome"
  | "userIdPrompt"
  | "userIdCheck"
  | "testInstructionLong"
  | "textInstructionShort"
  | "testInstructionImageShort"
  | "experienceProductInstruction"
  | "warmupYesNoInstructionLong"
  | "warmupYesNoInstructionShort"
  | "readSpeedInstructionLong"
  | "readSpeedInstructionShort"
  | "endSurveyMessage"
  | "continueButtonInstruction"
  | "fingerprintInstruction"
  | "calibrationText"
  | "yesText"
  | "noText"
  | "okText"
  | "readSpeedStatement1"
  | "readSpeedStatement2"
  | "readSpeedStatement3";

// helper
function Textarea(props: { data?: ISurvey; name: Fields; loading?: boolean }) {
  const { t } = useTranslation();

  return (
    <TextareaField
      loading={props.loading}
      defaultValue={
        props.data && props.data.instruction
          ? props.data.instruction[props.name]
          : t(`pages.survey.forms.instructions.defaultvalue.${props.name}`)
      }
      name={"instruction." + props.name}
      error={{
        valueMissing: t("error.valueMissing"),
      }}
      label={t(`pages.survey.forms.instructions.${props.name}`)}
      dom={{
        placeholder: t("common.placeholder.text"),
        required: true,
      }}
    />
  );
}
