import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";

// atoms
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";
import { Badge } from "ui/atoms/Badge";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

interface Tab {
  id: string;
  name: string;
  badge: number;
}
export interface Props extends Omit<IProps, "selected"> {
  tabs: Tab[];
  selected?: string;
  setSelected(id: string): void;
  space?: string;
}

export const Tabs: React.FC<Props> = (props) => {
  const theme = useTheme();

  function click(tab: Tab) {
    return () => {
      props.setSelected(tab.id);
    };
  }

  return (
    <Flex
      className={cx(props.className, styles(props, theme), "tabs")}
      testId={props.testId}
      style={props.style}
      noHeight
      justifyContent="space-between"
    >
      {props.tabs.map((tab) => (
        <Button
          key={tab.id}
          className={tab.id === props.selected ? "selected" : ""}
          onClick={click(tab)}
          textVariant="button2"
          shape="plain"
          testId={`${props.testId}-${tab.id}`}
        >
          {tab.name}
          {tab.badge !== 0 && <Badge value={tab.badge} />}
        </Button>
      ))}
    </Flex>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .button {
    position: relative;
    margin-left: ${props.space};
    margin-right: ${props.space};
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }

    & .badge {
      margin-left: 0.8rem;
    }

    &.selected:after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 5%;
      width: 90%;
      height: 3px;
      background-color: ${theme.colors.Secondary.Crimson};
    }
  }
`;
