import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { SurveyParadigm } from "utils/model/Survey";

// atoms
import { Box } from "ui/atoms/Box";
import { Button } from "ui/atoms/Button";

// organisms
import { Construction } from "ui/organisms/Construction";

export interface Props extends IProps<HTMLDivElement> {
  setParadigm(value: SurveyParadigm | "base"): void;
}

export const Learning: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function goBack() {
    props.setParadigm("base");
  }

  return (
    <Box
      {...props}
      className={cx(props.className, styles(props, theme), "learning")}
    >
      <Button shape="square" color="darker" onClick={goBack}>
        {t("common.goback")}
      </Button>
      <Construction />
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  height: 40rem;
`;
