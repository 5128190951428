import React from "react";
import { injectGlobal } from "@emotion/css";

// utils
import { Theme, JsonTheme, Themes } from "utils/types/theme";

// themes
import primaryTheme from "./themes/primary";
import secondaryTheme from "./themes/secondary";

interface Props {
  type?: Themes;
}

export const ThemeProvider: React.FC<Props> = (props) => {
  const [theme, setTheme] = React.useState<Theme>(primaryTheme);

  React.useEffect(() => {
    switch (props.type) {
      default: {
        selectTheme(primaryTheme);
        break;
      }
      case "secondary": {
        selectTheme(secondaryTheme);
        break;
      }
    }
  }, [setTheme, primaryTheme, props.type]);

  function selectTheme(value: JsonTheme) {
    setTheme(value);
    // FIXME should clear prev theme
    injectGlobal(value.global);
  }

  const provides = { theme, setTheme };

  return (
    <themeContext.Provider value={provides}>
      {props.children}
    </themeContext.Provider>
  );
};

// hook
export function useTheme(): Theme {
  const { theme } = React.useContext(themeContext);
  return theme as Theme;
}

// context
export interface ThemeContext {
  theme: Theme | null;
  setTheme(theme: Theme): void;
}
export const themeContext = React.createContext<ThemeContext>({
  theme: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: (theme) => {},
});
