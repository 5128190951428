import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { useTheme } from "utils/theme";

// atoms
import { Icon, Props as IconProps } from "ui/atoms/Icon";
import { Theme } from "utils/types/theme";

export interface Props extends IconProps {
  badge: boolean;
}

export const IconWithBadge: React.FC<Props> = (props) => {
  const { className, ...rest } = props;
  const theme = useTheme();

  return (
    <span className={cx(className, styles(props, theme))}>
      <Icon {...rest} />
    </span>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${theme.colors.Success.Green1};
    position: absolute;
    top: -5px;
    right: -5px;

    display: ${props.badge ? "initial" : "none"};
  }
`;
