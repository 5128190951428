export const Vector = {
  _x: 1,
  _y: 0,
  create: function (x, y) {
    let obj = Object.create(this);
    obj.setX(x);
    obj.setY(y);
    return obj;
  },
  setX: function (value) {
    this._x = value;
  },
  getX: function () {
    return this._x;
  },
  setY: function (value) {
    this._y = value;
  },
  getY: function () {
    return this._y;
  },
  setAngle: function (angle) {
    let length = this.getLenght();
    this._x = Math.cos(angle) * length;
    this._y = Math.sin(angle) * length;
  },
  getAngle: function () {
    return Math.atan2(this._y, this._x);
  },
  setLenght: function (length) {
    let angle = this.getAngle();
    this._x = Math.cos(angle) * length;
    this._y = Math.sin(angle) * length;
  },
  getLenght: function () {
    return Math.sqrt(this._x * this._x + this._y * this._y);
  },
  add: function (v2) {
    return Vector.create(this._x + v2.getX(), this._y + v2.getY());
  },
  subtract: function (v2) {
    return Vector.create(this._x - v2.getX(), this._y - v2.getY());
  },
  multiply: function (val) {
    return Vector.create(this._x * val, this._y * val);
  },
  divide: function (val) {
    return Vector.create(this._x / val, this._y / val);
  },
  addTo: function (v2) {
    this._x += v2.getX();
    this._y += v2.getY();
  },
  subtractFrom: function (v2) {
    this._x -= v2.getX();
    this._y -= v2.getY();
  },
  multiplyBy: function (val) {
    this._x *= val;
    this._y *= val;
  },
  multiplyX: function (val) {
    this._x *= val;
  },
  multiplyY: function (val) {
    this._y *= val;
  },
  divideBy: function (val) {
    this._x /= val;
    this._y /= val;
  },
  outsideMap: function (width, height, size) {
    if (this._x > width + size) {
      return true;
    } else if (this._x < -size) {
      return true;
    } else if (this._y > height + size) {
      return true;
    } else if (this._y < -size) {
      return true;
    } else {
      return false;
    }
  },
  distanceTo: function (v2) {
    let dx = v2.getX() - this._x,
      dy = v2.getY() - this._y;
    return Math.sqrt(dx * dx + dy * dy);
  },
};
export const Particle = {
  position: null,
  velocity: null,

  create: function (xPos, yPos, speed, direction) {
    let obj = Object.create(this);
    obj.position = Vector.create(xPos, yPos);
    obj.velocity = Vector.create(0, 0);
    obj.velocity.setLenght(speed);
    obj.velocity.setAngle(direction);
    return obj;
  },

  update: function () {
    this.position.addTo(this.velocity);
  },
  accelerate: function (accel) {
    let currentSpeed = this.velocity.getLenght();
    this.velocity.setLenght(currentSpeed + accel);
  },
};
export const Rock = {
  particle: null,
  angle: 0,
  rotateSpeed: 0,
  dots: [],
  Size: 0,
  radius: 0,
  getDirection: function () {
    return this.particle.velocity.getAngle();
  },
  getSpeed: function () {
    return this.particle.velocity.getLenght();
  },
  createSpecial: function (x, y, speed, direction, size) {
    let obj = Object.create(this);
    let p = Particle.create(x, y, speed, direction);
    obj.particle = p;
    obj.angle = direction;
    obj.rotateSpeed =
      Math.round(Math.random()) == 0 ? 0.0088 * size : 0.0088 * size * -1;
    obj.Size = size;
    obj.radius = 15 * size;
    this.createDots(obj);
    return obj;
  },
  create: function (shipX, shipY, w, h, Size) {
    let obj = Object.create(this);
    let rnd = Math.round(Math.random() * 3);
    let x = 0,
      y = 0,
      width = 0,
      height = 0;
    switch (rnd) {
      case 0:
        x = -50;
        y = 0;
        width = 50;
        height = h;
        break;
      case 1:
        x = 0;
        y = -50;
        width = w;
        height = 50;
        break;
      case 2:
        x = w;
        y = 0;
        width = 50;
        height = h;
        break;
      case 3:
        x = 0;
        y = h;
        width = w;
        height = 50;
        break;
    }
    obj.Size = Size;
    obj.radius = 15 * obj.Size;
    obj.rotateSpeed =
      Math.round(Math.random()) == 0
        ? 0.0088 * obj.Size
        : 0.0088 * obj.Size * -1;
    x += Math.random() * width;
    y += Math.random() * height;
    let direction = Math.atan2(shipY - y, shipX - x);
    obj.particle = Particle.create(x, y, Size / 4 + 1, direction);
    this.createDots(obj);
    return obj;
  },
  createDots: function (obj) {
    if (obj.dots.length > 0) {
      obj.dots = [];
    }
    for (let i = 0; i <= 12; i++) {
      if (i == 12) {
        let d = obj.dots[0];
        obj.dots.push(d);
      } else {
        let tAngle = i * Math.PI * (2 / 12),
          dSize = obj.radius / 4,
          index = Math.round(Math.random()) == 0 ? -1 : 1,
          xT = Math.cos(tAngle) * (obj.radius + dSize * index),
          yT = Math.sin(tAngle) * (obj.radius + dSize * index);
        // eslint-disable-next-line no-redeclare
        var d = Vector.create(xT, yT);
        obj.dots.push(d);
      }
    }
  },
  draw: function (context) {
    context.save();
    context.translate(
      this.particle.position.getX(),
      this.particle.position.getY()
    );
    context.rotate(this.angle);
    context.beginPath();
    for (let i = 0; i < this.dots.length - 1; i++) {
      context.moveTo(this.dots[i].getX(), this.dots[i].getY());
      context.lineTo(this.dots[i + 1].getX(), this.dots[i + 1].getY());
    }
    context.stroke();
    context.restore();
  },
  isColiding: function (rock2) {
    return (
      this.particle.position.distanceTo(rock2.particle.position) <
      this.radius + rock2.radius
    );
  },
  update: function (w, h) {
    let pos = this.particle.position;
    if (pos.getX() > w + 50) {
      this.particle.position.setX(-50);
    }
    if (pos.getX() < -50) {
      this.particle.position.setX(w + 50);
    }
    if (pos.getY() > h + 50) {
      this.particle.position.setY(-50);
    }
    if (pos.getY() < -50) {
      this.particle.position.setY(h + 50);
    }
    this.particle.update();
    this.angle += this.rotateSpeed;
  },
};
export function elastic_bounce(r1, r2) {
  //balls
  let v1x = r1.particle.position.getX(),
    v1y = r1.particle.position.getY(),
    l1 = r1.getSpeed(),
    d1 = r1.getDirection(),
    m1 = r1.Size,
    v2x = r2.particle.position.getX(),
    v2y = r2.particle.position.getY(),
    l2 = r2.getSpeed(),
    d2 = r2.getDirection(),
    m2 = r2.Size;

  let A = Math.atan2(v1y - v2y, v1x - v2x);

  doApart(r1, r2);
  if (r1.isColiding(r2)) {
    doApart(r1, r2);
  }

  let v1 = getBounce_Velocity(l1, d1, m1, l2, d2, m2, A),
    v2 = getBounce_Velocity(l2, d2, m2, l1, d1, m1, A); //swapped

  r1.particle.velocity = v1;
  r2.particle.velocity = v2;
}
function doApart(r1, r2) {
  let v1x = r1.particle.position.getX(),
    v1y = r1.particle.position.getY(),
    v2x = r2.particle.position.getX(),
    v2y = r2.particle.position.getY();

  let A = Math.atan2(v2y - v1y, v2x - v1x);
  let dr =
    r1.radius +
    r2.radius -
    r1.particle.position.distanceTo(r2.particle.position) +
    0.8;
  let push = Vector.create((Math.cos(A) * dr) / 2, (Math.sin(A) * dr) / 2);
  //find out which of the balls should be added.. and which should be subracted
  let vecTemp1 = r1.particle.position.add(push);
  let vecTemp2 = r2.particle.position.subtract(push);
  if (
    vecTemp1.distanceTo(vecTemp2) >
    r1.particle.position.distanceTo(r2.particle.position)
  ) {
    //this is oke
    r1.particle.position.addTo(push);
    r2.particle.position.subtractFrom(push);
  } else {
    //the other way
    r1.particle.position.subtractFrom(push);
    r2.particle.position.addTo(push);
  }
}
function getBounce_Velocity(l1, d1, m1, l2, d2, m2, A) {
  let comb =
    (l1 * Math.cos(d1 - A) * (m1 - m2) + 2 * m2 * l2 * Math.cos(d2 - A)) /
    (m1 + m2);
  let x =
    comb + Math.cos(A) + l1 * Math.sin(d1 - A) * Math.cos(A + Math.PI / 2);
  let y =
    comb + Math.sin(A) + l1 * Math.sin(d1 - A) * Math.sin(A + Math.PI / 2);
  let friction = 0.538;
  x *= friction;
  y *= friction;
  return Vector.create(x, y);
}
