import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Button, Props as ButtonProps } from "ui/atoms/Button";

export interface Props extends ButtonProps {}

export const BigButton: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Button
      {...props}
      className={cx(props.className, styles(props, theme), "big")}
    >
      {props.children}
    </Button>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  height: 100%;
  width: 100%;
  min-height: 30rem;
  border-radius: ${theme.border.radius.medium} !important;

  position: relative;
`;
