import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";
import { Typography } from "ui/atoms/Typography";
import { Icon, IconType } from "ui/atoms/Icon";

export interface Props extends IProps {
  icon?: IconType;
  expanded?: boolean;
  selected: string;
  collapsed: boolean;
  variant?: "primary" | "dark";
  id: string;
  click: React.MouseEventHandler<HTMLDivElement>;
}

export const SidebarItem: React.FC<Props> = (props) => {
  const theme = useTheme();

  let color = theme.colors.Primary.White;
  let background = "transparent";
  if (props.selected === props.id) {
    color = theme.colors.Neutral.Gray3;
    background = theme.colors.Primary.White;
  } else if (props.variant) {
    if (props.variant === "dark") background = theme.colors.Neutral.Gray3;
    else background = theme.colors.Primary.Crimson;
  }

  return (
    <Box
      className={cx(
        styles(props, theme),
        "sidebar-item",
        props.collapsed ? "collapsed" : "",
        props.selected === props.id ? "selected" : "hover"
      )}
      testId={props.testId}
      style={props.style}
      padding="0 1.5rem"
      color={background}
      dom={{ onClick: props.click, id: props.id }}
    >
      <Grid
        container
        cols={props.collapsed ? "1fr 0 0" : "2rem auto 2rem"}
        alignItems="center"
      >
        <Flex className="icon" alignItems="center" justifyContent="center">
          {props.icon && <Icon color={color} type={props.icon} />}
          {!props.icon && props.collapsed && (
            <Typography noselect color={color}>
              {(props.children as string)[0]}{" "}
            </Typography>
          )}
        </Flex>
        <Flex className="text" alignItems="center">
          <Typography noselect color={color}>
            {props.children}
          </Typography>
        </Flex>
        <Flex className="expand" alignItems="center">
          {props.expanded !== undefined && (
            <Icon
              color={color}
              type="caret"
              rotate={props.expanded ? -90 : 90}
            />
          )}
        </Flex>
      </Grid>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  height: 72px;
  cursor: pointer;

  & .text {
    margin-left: 0.5rem;
    min-width: 15rem;
  }

  & .icon {
    max-width: 136px;
  }

  &.collapsed {
    padding: 0;

    & .text,
    & .expand {
      display: none;
    }
  }
`;
