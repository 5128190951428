import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";

export interface Props extends Omit<IProps<HTMLDivElement>, "children"> {
  children: React.ReactNode[];
}

export const Switch: React.FC<Props> = (props) => {
  return (
    <div
      {...props.dom}
      style={props.style}
      className={cx(props.className, styles(props), "switch")}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
};

// css design
const styles = (props: Props) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // overflow: hidden;
  height: 100%;
  width: 100%;

  & > * {
    width: 100%;
    &:not(.selected) {
      z-index: 1;
      order: 2;
      visibility: hidden;
      height: 0 !important;
      overflow: hidden;
      padding: 0 !important;
      margin: 0 !important;
    }

    &.selected {
      overflow: unset;
      height: 100% !important;
      visibility: visible;
      z-index: 2;
      order: 1;
    }
  }
`;
