import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { log } from "utils/function/console";

export interface Props extends IProps<HTMLDivElement> {
  open?: boolean;
}

export const Popup: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      {...props.dom}
      className={cx(
        props.className,
        styles(props, theme),
        "popup",
        props.open ? "open" : "closed"
      )}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  &.popup {
    &.open {
      z-index: 10;
      visibility: visible;
    }
    &.closed {
      overflow: hidden;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
`;
