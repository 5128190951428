import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

// utils
import { ISummary } from "utils/model/Summary";
import { HTTPError } from "utils/error/http";

import { SummaryPage as Component } from "./Component";
import { Context } from "./Context";
import { Props } from "./types";

// local
import { fetchSummary } from "./http";

export * from "./Component";
export * from "./types";

function Page(props: Props) {
  const params = useParams();
  const id = params.id;

  // states
  const [highYes, setHighYes] = React.useState(false);
  const [threshold, setThreshold] = React.useState(2);
  const [normalizedTime, setNormalizedTime] = React.useState(false);

  const summary = useQuery<ISummary, HTTPError>(
    ["summary", id, threshold, normalizedTime],
    () => fetchSummary(id as string, threshold, normalizedTime)
  );

  const provides = {
    summary,
    threshold,
    setThreshold,
    normalizedTime,
    setNormalizedTime,
    highYes,
    setHighYes,
  };

  return (
    <Context.Provider value={provides}>
      <Component {...props} />
    </Context.Provider>
  );
}

export const SummaryPage = React.memo(Page);
