import React, { HTMLAttributes } from "react";
import { css, cx } from "@emotion/css";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// utils
import { IProps } from "utils/types/html";

type Shape = "circle" | "cone" | "pill" | "toroid" | "toroid-left";
export interface Props extends IProps<HTMLSpanElement> {
  shape: Shape;
  color: string;
  x: string;
  y: string;
  size: number;
  rotate?: number;
  noblend?: boolean;
}

export const Background: React.FC<Props> = (props) => {
  const theme = useTheme();

  let imagesize: number;
  switch (props.shape) {
    case "circle":
      imagesize = 400;
      break;
    case "cone":
      imagesize = 434;
      break;
    case "toroid":
      imagesize = 364;
      break;
    case "toroid-left":
      imagesize = 340;
      break;
    case "pill":
      imagesize = 445;
      break;
    default:
      imagesize = 400;
  }

  const cssProp: CSSProps = { ...props, imagesize };

  return (
    <span
      {...(props.dom as HTMLAttributes<HTMLSpanElement>)}
      data-testid={props.testId}
      style={props.style}
      className={cx(
        props.className,
        "background-shape",
        styles(cssProp, theme)
      )}
    />
  );
};

// css design
const styles = (props: CSSProps, theme: Theme) => css`
  position: absolute;
  left: ${props.x};
  top: ${props.y};
  width: calc(${props.imagesize}px * calc(${props.size} / 100));
  height: calc(${props.imagesize}px * calc(${props.size} / 100));
  content: " ";
  display: inline-block;
  z-index: 1;
  transform: translate(-50%, -50%)
    ${props.rotate ? `rotate(${props.rotate}deg)` : ""};

  background-image: url("${getSRC(props.shape)}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  ${!props.noblend ? `background-blend-mode: multiply;` : ""}
  background-color: ${props.color};
`;

// types & interfaces
interface CSSProps extends Props {
  imagesize: number;
}

// helper functions
function getSRC(shape: Shape) {
  switch (shape) {
    case "circle":
      return "/assets/images/Sphere-White-Glossy.png";
    case "pill":
      return "/assets/images/Pill-White-Glossy.png";
    case "toroid":
      return "/assets/images/SuperToroid-White-Glossy.png";
    case "toroid-left":
      return "/assets/images/SuperToroid-White-Glossy-Left.png";
    case "cone":
      return "/assets/images/Cone-Black-Matte.png";
  }
}
