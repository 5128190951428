import { Theme } from "./theme";

// ** enums **
export enum MediaDevices {
  Mobile = 0,
  Pad = 1,
  Laptop = 2,
  Desktop = 3,
}

export enum MediaCompare {
  Between = "between",
  Smaller = "smaller",
  Bigger = "bigger",
}

// ** types **
export type Units = "default" | "mobile" | "pad" | "laptop" | "desktop";

// ** funtions **
export function GetMediaType(width: number, theme: Theme): Units {
  if (width <= theme.sizes.MobileMax) return "mobile";
  if (width <= theme.sizes.PadMax) return "pad";
  if (width <= theme.sizes.LaptopMax) return "laptop";

  return "desktop";
}

// ** interfaces **
export interface IMediaBlock {
  min: number;
  max: number;
  device: MediaDevices;
}

export interface IMedia {
  mobile: IMediaBlock;
  pad: IMediaBlock;
  laptop: IMediaBlock;
  desktop: IMediaBlock;
}
