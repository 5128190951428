import React from "react";
import { cx, css } from "@emotion/css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// utils
import { ISurvey } from "utils/model/Survey";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { info, log } from "utils/function/console";
import { IProps } from "utils/types/html";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Typography } from "ui/atoms/Typography";
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";
import { ProgressBar } from "ui/atoms/ProgressBar";

// NOTE not good..
import { Tooltip } from "../Tooltip";

export interface Props extends IProps {
  data?: ISurvey;
  favoriteClick?(data: ISurvey): void;
}

export const SurveyItem: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  function favoriteToggle() {
    if (props.data && props.favoriteClick) props.favoriteClick(props.data);
  }
  function copyClick() {
    navigate(t("router.survey.route") + "/copy/" + props.data?.id);
  }
  function editClick() {
    navigate(t("router.survey.route") + "/" + props.data?.id);
  }
  function summaryClick() {
    if (props.data?.id !== undefined) {
      navigate(t("router.summary.redirect", { id: props.data.id }));
    }
  }

  const progressProcent = props.data
    ? Math.floor(
        (props.data.progress?.current / props.data.progress?.max) * 100
      )
    : 0;

  return (
    <Box
      {...props}
      color={theme.colors.Primary.White}
      className={cx(styles(props, theme), "survey-item", "outer")}
    >
      <Grid container cols="3fr 6fr 6fr 5fr 6fr 6fr 6fr">
        <Grid item className="survey-block">
          <Typography variant="paragraph">
            {t(`survey.status.${props.data?.status}`)}
          </Typography>
        </Grid>
        <Grid item className="survey-block">
          <Typography variant="paragraph">{props.data?.name}</Typography>
        </Grid>
        <Grid item className="survey-block">
          <Typography variant="paragraph">
            {props.data?.productCategory?.name}
          </Typography>
        </Grid>
        <Grid item className="survey-block">
          <Typography variant="paragraph">
            {t(`survey.date.${props.data?.status}`, {
              date: moment(props.data?.lastModification?.time).format(
                "DD/MM/YY"
              ),
              user: props.data?.lastModification?.userName,
            })}
          </Typography>
        </Grid>
        <Grid item className="survey-block">
          <Flex justifyContent="space-between" direction="column">
            <Box>
              <ProgressBar value={progressProcent} />
              <Flex
                justifyContent="space-between"
                style={{ paddingRight: "1rem" }}
              >
                <Typography variant="paragraph">{progressProcent}%</Typography>
                <Typography variant="paragraph">
                  {props.data?.progress?.current}/{props.data?.progress?.max}
                </Typography>
              </Flex>
            </Box>
            <Tooltip
              mode="bottom"
              text={t("molecules.surveyitem.tooltip.summary")}
              minWidth="15rem"
            >
              <Button
                onClick={summaryClick}
                disabled={props.data?.status === "DRAFT"}
                color="dark"
                shape="square"
                className="width100"
                testId={props.testId + "-summary"}
              >
                <Icon type="summary" />
              </Button>
            </Tooltip>
          </Flex>
        </Grid>
        <Grid item className="survey-block">
          <Typography variant="paragraph">
            {props.data?.numberOfProducts}
          </Typography>
        </Grid>
        <Grid item container cols="1fr 4rem">
          <Grid item className="survey-block">
            <Typography variant="paragraph">
              {props.data?.country?.name}
            </Typography>
          </Grid>
          <Grid container rows="1fr 1fr" rowGap="1rem" className="buttons">
            <Tooltip
              mode="left"
              text={t("molecules.surveyitem.tooltip.copy")}
              minWidth="11rem"
            >
              <Button
                shape="square-small"
                testId={props.testId + "-copy"}
                color="primary"
                onClick={copyClick}
                className="height100"
              >
                <Icon type="copy" />
              </Button>
            </Tooltip>

            <Tooltip
              mode="left"
              text={t("molecules.surveyitem.tooltip.edit")}
              minWidth="11rem"
            >
              <Button
                shape="square-small"
                testId={props.testId + "-edit"}
                color="primary"
                onClick={editClick}
                className="height100"
              >
                <Icon type="edit" />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  padding: 1.2rem 0;
  border: 1px solid ${theme.colors.Neutral.Gray4};
  border-radius: ${theme.border.radius.small};

  & .survey-block {
    border-right: 1px solid ${theme.colors.Neutral.Gray4};
    padding: 0 1rem;
    min-height: 8rem;
  }

  & .buttons {
    position: relative;
    width: 2rem;
    left: 1rem;
  }
`;
