import React from "react";
import { css, cx } from "@emotion/css";
import { v4 as uuidv4 } from "uuid";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";
import { Input, Props as InputProps } from "ui/atoms/Input";
import { Typography } from "ui/atoms/Typography";
import { Flex } from "ui/atoms/Flex/Component";
import { log } from "utils/function/console";

export interface Props extends InputProps {
  label: string;
  checked?: boolean;
}

export const Checkbox: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { dom, ...rest } = props;
  const uuid = React.useMemo(() => uuidv4(), []);

  return (
    <Box
      {...rest}
      testId={props.testId + "-wrapper"}
      className={cx(props.className, styles(props, theme), "checkbox")}
    >
      <Flex alignItems="center" colGap="0.7rem">
        <Input
          {...props}
          dom={{
            defaultChecked: props.checked,
            ...props.dom,
            type: "checkbox",
            id: uuid,
          }}
          className="checkbox-input"
        />
        <label htmlFor={uuid}>
          <Typography className="label noselect">{props.label}</Typography>
        </label>
      </Flex>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .checkbox-input {
    width: 2rem;
  }
`;
