import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { useTheme } from "utils/theme";
import { Theme } from "utils/types/theme";

import { ReactComponent as BellIcon } from "icons/bell.svg";
import { ReactComponent as FavoriteIcon } from "icons/favorite.svg";
import { ReactComponent as ArrowIcon } from "icons/arrow.svg";
import { ReactComponent as CaretIcon } from "icons/caret.svg";
import { ReactComponent as CircleIcon } from "icons/circle.svg";
import { ReactComponent as FavoriteFilledIcon } from "icons/favorite-filled.svg";
import { ReactComponent as FileIcon } from "icons/file.svg";
import { ReactComponent as HaircrossIcon } from "icons/haircross.svg";
import { ReactComponent as HamburgerIcon } from "icons/hamburger.svg";
import { ReactComponent as LogoIcon } from "icons/logo.svg";
import { ReactComponent as LogoSingleIcon } from "icons/logo-single.svg";
import { ReactComponent as MessageIcon } from "icons/message.svg";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import { ReactComponent as SearchIcon } from "icons/search.svg";
import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { ReactComponent as CopyIcon } from "icons/copy.svg";
import { ReactComponent as EyeIcon } from "icons/eye.svg";
import { ReactComponent as CheckIcon } from "icons/check.svg";
import { ReactComponent as CheckNotIcon } from "icons/checknot.svg";
import { ReactComponent as CheckpointIcon } from "icons/checkpoint.svg";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as DeleteIcon } from "icons/delete.svg";
import { ReactComponent as SummaryIcon } from "icons/summary.svg";
import { ReactComponent as CrossIcon } from "icons/cross.svg";
import { ReactComponent as WarningIcon } from "icons/warning.svg";
import { ReactComponent as GenisysIcon } from "icons/genisys.svg";
import { ReactComponent as ThumbIcon } from "icons/thumb.svg";
import { ReactComponent as EditBlack } from "icons/editblack.svg";
import { ReactComponent as Export } from "icons/export.svg";

export interface Props extends IProps<HTMLSpanElement> {
  type: IconType;
  color?: string;
  rotate?: number;
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
  iconSize?: number;
  disableRotateAnimtaion?: boolean;
}

// NOTE this file uses hooks : not common for atoms but needed to load svg dynamically
export const Icon: React.FC<Props> = (props) => {
  const theme = useTheme();

  const Component = getIcon(props.type);

  return (
    <span
      {...props.dom}
      style={props.style}
      className={cx(props.className, styles(props, theme), "icon")}
      data-test-id={props.testId}
    >
      <Component height={props.iconSize ?? 17} width={props.iconSize ?? 17} />
    </span>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  color: ${props.color || "currentColor"};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props.width ? props.width : "2rem"};
  height: ${props.height ? props.height : "2rem"};

  & svg {
    ${props.rotate ? `transform: rotate(${props.rotate}deg);` : ""}
    ${!props.disableRotateAnimtaion
      ? `transition: transform ${theme.animation.default}ms ease;`
      : ""}
  }
`;

// types & interfaces
export type IconType =
  | "bell"
  | "favorite"
  | "arrow"
  | "caret"
  | "circle"
  | "favorite-filled"
  | "file"
  | "haircross"
  | "hamburger"
  | "logo"
  | "logo-single"
  | "message"
  | "plus"
  | "clock"
  | "search"
  | "copy"
  | "eye"
  | "check"
  | "checkpoint"
  | "delete"
  | "summary"
  | "edit"
  | "cross"
  | "genisys"
  | "thumb"
  | "warning"
  | "checknot"
  | "editblack"
  | "export"
  | "warning";

// NOTE it would be nicer with dynamically loaded icons : import(`icons/${type}.svg`).ReactComponent
// but this did not work..
function getIcon(type: IconType) {
  switch (type) {
    case "bell":
      return BellIcon;
    case "favorite":
      return FavoriteIcon;
    case "arrow":
      return ArrowIcon;
    case "caret":
      return CaretIcon;
    case "circle":
      return CircleIcon;
    case "favorite-filled":
      return FavoriteFilledIcon;
    case "file":
      return FileIcon;
    case "haircross":
      return HaircrossIcon;
    case "hamburger":
      return HamburgerIcon;
    case "logo":
      return LogoIcon;
    case "logo-single":
      return LogoSingleIcon;
    case "message":
      return MessageIcon;
    case "plus":
      return PlusIcon;
    case "clock":
      return ClockIcon;
    case "search":
      return SearchIcon;
    case "eye":
      return EyeIcon;
    case "copy":
      return CopyIcon;
    case "check":
      return CheckIcon;
    case "checkpoint":
      return CheckpointIcon;
    case "edit":
      return EditIcon;
    case "delete":
      return DeleteIcon;
    case "summary":
      return SummaryIcon;
    case "cross":
      return CrossIcon;
    case "warning":
      return WarningIcon;
    case "genisys":
      return GenisysIcon;
    case "editblack":
      return EditBlack;
    case "thumb":
      return ThumbIcon;
    case "checknot":
      return CheckNotIcon;
    case "export":
      return Export;
    default:
      return LogoIcon; //just so we dont have to null-check etc
  }
}
