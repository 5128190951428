import React, { useState } from "react";
import { css, cx } from "@emotion/css";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { Viewer } from "utils/function/authchecks";
import { IPaginationResponse } from "utils/types/api";

// models
import { ISummary } from "utils/model/Summary";

// atoms
import { Flex } from "ui/atoms/Flex";

// molecules

// organisms
import { HeaderSummary } from "ui/organisms/Header.summary";
import { SummaryAttributeType } from "ui/organisms/SummaryAttributeType";
import { SummaryCollapse } from "ui/organisms/SummaryCollapse";

// templates
import { SHTemplate } from "ui/templates/SidebarHeader";

// local
import { Props } from "./types";
import { Context } from "./Context";
import { Typography } from "ui/atoms/Typography";

export const SummaryPage: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { highYes, threshold, normalizedTime, summary } =
    React.useContext(Context);

  const distinctAttributeTypes = [
    ...new Map(
      summary.data?.attributes.map((item) => [item.type, item])
    ).values(),
  ];

  React.useEffect(() => {
    summary.refetch();
  }, [threshold, normalizedTime]);

  return (
    <SHTemplate
      authcheck={Viewer}
      {...props}
      variant={1}
      className={cx(
        props.className,
        styles(props, theme),
        "implicit-reaction-page"
      )}
    >
      <Flex rowGap="0.5rem" colGap={theme.padding.page} direction="column">
        <HeaderSummary />
        {summary.isLoading ? (
          <Typography variant="subtitle3" className="ml1 mt1">
            Loading ...
          </Typography>
        ) : summary.error?.status === "NOT_FOUND" ? (
          <Typography variant="subtitle3" className="ml1 mt1">
            Not found
          </Typography>
        ) : summary.data?.results.length === 0 ? (
          <Typography variant="subtitle3" className="ml1 mt1">
            No results yet
          </Typography>
        ) : (
          <>
            <SummaryAttributeType products={summary.data?.products} />
            {distinctAttributeTypes?.map((attribute) => (
              <SummaryCollapse
                highYes={highYes}
                attribute={attribute.type}
                data={summary.data}
              />
            ))}
          </>
        )}
      </Flex>
    </SHTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  background-color: ${theme.colors.Neutral.Gray1};
`;
