import { createContext } from "react";
export const Context = createContext<IContext>({
  map: {},
  toggleValue: () => 1,
  defaultValue: "",
});

export interface IContext {
  map: {
    [key: string]: string;
  };
  defaultValue: string;
  toggleValue(value: string, name: string): void;
}
