import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Flex } from "ui/atoms/Flex";
import { Typography } from "ui/atoms/Typography";
import { Card } from "ui/atoms/Card";

export interface Props extends IProps<HTMLDivElement> {
  attributeName: string;
}

export const CardSummaryAttribute: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Card
      {...props}
      className={cx(
        props.attributeName,
        styles(props, theme),
        "cardSummaryProduct"
      )}
      noBorder
    >
      <Flex justifyContent="center" alignItems="center">
        <Typography
          testId={props.testId + "-cardSummaryAttribute"}
          variant="overline"
          color={theme.colors.Primary.White}
        >
          {props.attributeName.toUpperCase()}
        </Typography>
      </Flex>
    </Card>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  width: ${theme.components.summaryCard.width};
  background-color: ${theme.colors.Neutral.Gray2};
`;
