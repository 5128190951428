import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Flex } from "ui/atoms/Flex";
import { Box } from "ui/atoms/Box";
import { Icon } from "ui/atoms/Icon";
import { Typography } from "ui/atoms/Typography";

// molecules
import { TestButton } from "ui/molecules/TestButton";

// templates (weird)
import { TesterBasicTemplate } from "ui/templates/TesterBasic";

export interface Props extends IProps<HTMLDivElement> {
  continue: string;
  continueClick?(): void;
  submit?: boolean;
}

export const TesterSingleTemplate: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <TesterBasicTemplate
      {...props}
      className={cx(
        props.className,
        "tester-single-template",
        styles(props, theme)
      )}
    >
      <Flex justifyContent="space-between" direction="column">
        <Box style={{ height: "100%" }}>{props.children}</Box>
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            testId={props.testId + "-continuetext"}
            variant="subtitle3"
          >
            {props.continue}
          </Typography>
          <TestButton
            type={props.submit ? "submit" : undefined}
            testId={props.testId + "-continue"}
            onClick={props.submit ? undefined : props.continueClick}
            color="primary"
            textVariant="button2"
          >
            <Flex justifyContent="center" noHeight>
              <Icon type="arrow" rotate={180} disableRotateAnimtaion iconSize={30}/>
            </Flex>
          </TestButton>
        </Flex>
      </Flex>
    </TesterBasicTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
