import { doFetch } from "utils/api/utils";

export async function fetchSurveys(tab: string, page: number) {
  // FIXME pagination .. need flow/stream
  // const filter =
  //   tab === "overview" ? "" : `&filterProperty=status&filterValue=${tab}`;
  // return await doFetch(`/api/surveys?pageSize=10&page=${page}${filter}`);



  const filter =
    tab === "overview"
      ? `pageSize=20&page=${page}`
      : `filterProperty=status&filterValue=${tab}&pageSize=20&page=${page}`;
  return await doFetch(`/api/surveys?${filter}`);
}

export async function fetchRecentSurveys() {
  return await doFetch(
    "/api/surveys?pageSize=4&orderBy=updatedAt&orderDirection=DESC"
  );
}
