import React from "react";
import { TFunction } from "react-i18next";
import { UseQueryResult } from "react-query";
import { HTTPError } from "utils/error/http";
import { INameModel } from "utils/model/utils";

// atoms
import { Skeleton } from "ui/atoms/Skeleton";

// molecules
import { Option } from "ui/molecules/Dropdown";

export function RenderOptions(
  query: UseQueryResult<INameModel[], HTTPError>,
  testid: string | undefined,
  t: TFunction<"translation", undefined>
) {
  if (query.isLoading)
    return [0, 1, 2, 3].map((id) => (
      <Skeleton height="1rem" width="100%" key={id} />
    ));

  if (query.data?.length === 0)
    return <Option value="error">{t("error.loading.failed")}</Option>;

  if (query.data) {
    return query.data.map((o) => (
      <Option
        testId={`${testid}-option${o.id}`}
        key={o.id}
        value={o.id.toString()}
      >
        {o.name}
      </Option>
    ));
  }
}
