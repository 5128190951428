import React from "react";
import { css, cx } from "@emotion/css";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { log, warn } from "utils/function/console";
import { Creator } from "utils/function/authchecks";
import { ID } from "utils/types/model";
import { TabTypes } from "utils/types/survey";

// atoms
import { Form } from "ui/atoms/Form";
import { Switch } from "ui/atoms/Switch";
import { Grid } from "ui/atoms/Grid";

// molecules
import { Footer } from "ui/molecules/Footer.survey";

// organisms
import { Header } from "ui/organisms/Header.survey";
import { PageLoader } from "ui/organisms/PageLoader";
import { ISubmit as IAttributeSubmit } from "ui/organisms/Attribute";

// templates
import { SHTemplate } from "ui/templates/SidebarHeader";

// L O C A L S
import { IBeforeSubmit, ISubmit, Props } from "./types";
import { Context } from "./Context";
// forms
import { Form as BasicForm } from "./forms/Basic";
import { Form as ProductForm } from "./forms/Product";
import { Form as StatementsForm } from "./forms/Statements";
import { AttributeSubmit } from "./forms/Statements/Explicit";
import { Instructions } from "./forms/Instructions";
import { Popup } from "ui/organisms/WarningPopup";
import { once, trigger } from "utils/function/events";

export const SurveyPage: React.FC<Props> = (props) => {
  //#region variables
  const theme = useTheme();
  const formRef = React.useRef<HTMLFormElement>(null);
  const [title, setTitle] = React.useState<string>("");
  const [productsChanged, setProductsChanged] = React.useState(false);
  const [tab, setTab] = React.useState<TabTypes>(props.defaultTab || "basic");
  const { t } = useTranslation();

  const {
    survey,
    categories,
    countries,
    languages,
    families,
    specifiers,
    subfamilies,
    deleteSurvey,
    uploadCsv,
    publish,
    unpublish,
    create,
    update,
    csvRef,
  } = React.useContext(Context);

  const params = useParams();
  const id = props.test || params.id;
  const copy = /copy/.test(location.pathname);
  //#endregion

  //#region functions
  function onNameChange(event: React.FormEvent<HTMLElement>) {
    const value = (event.currentTarget as HTMLInputElement).value;
    setTitle(value);
  }
  async function onSubmit(
    e: React.FormEvent,
    data: IBeforeSubmit,
    submitter?: string
  ) {
    const { csv, regularAttributes, warmupAttributes, ...surveydata } = data;

    function handleevent(): Promise<boolean> {
      return new Promise((resolve) => {
        once("products-change", (event: CustomEvent<boolean>) => {
          resolve(event.detail);
        });
      });
    }

    if (
      survey &&
      survey.data &&
      ((survey.data.productsPerTest &&
        survey.data.productsPerTest !== data.productsPerTest) ||
        (survey.data.products.length &&
          data.products.length &&
          survey.data.products.length !== data.products.length))
    ) {
      setProductsChanged(true);
      const ans = await handleevent();
      if (!ans) return;
    }

    const surveyAttributes = extractAttributes(regularAttributes)
      .concat(extractAttributes(warmupAttributes))
      .filter((a) => a);

    const submitData = {
      ...surveydata,
      surveyAttributes,
    };

    if (csv && csv.size > 0) {
      if (id === undefined) csvRef.current = { csv };
      else uploadCsv.mutate({ id: id as ID, csv });
    }

    if (submitter === "save") {
      if (copy || !id) {
        create.mutate(submitData);
      } else {
        update.mutate(submitData);
      }
    } else if (submitter === "publish") {
      if (id === undefined) {
        // TODO publish should remain disabled as long as id is undefined
        warn("trying to publish when id does not exists");
      } else publish.mutate(submitData);
    } else if (submitter === "unpublish") {
      if (id === undefined) {
        // TODO publish should remain disabled as long as id is undefined
        warn("trying to publish when id does not exists");
      } else unpublish.mutate(submitData);
    }
  }
  function onDelete() {
    deleteSurvey.mutate(id as ID);
  }
  function handleProductsChange(answer: boolean) {
    trigger("products-change", answer);
    setProductsChanged(false);
  }
  //#endregion

  React.useEffect(() => {
    const edit = id !== undefined;

    if (copy) {
      setTitle(t("pages.survey.title.copy", { name: survey?.data?.name }));
    } else if (edit) {
      setTitle(t("pages.survey.title.edit", { name: survey?.data?.name }));
    }
  }, [survey?.data]);

  if (
    survey?.isLoading ||
    countries.isLoading ||
    languages.isLoading ||
    subfamilies.isLoading ||
    families.isLoading
  ) {
    return <PageLoader />;
  }

  return (
    <SHTemplate
      variant={1}
      title={title}
      authcheck={Creator}
      className={cx(props.className, "survey", styles(props, theme))}
      testId={props.testId}
    >
      <Form ref={formRef} onSubmit={onSubmit}>
        <Grid
          className="survey-levelA"
          container
          rows={survey?.data?.url ? "14rem 1fr 5rem" : "11rem 1fr 5rem"}
        >
          <Header
            tab={tab}
            setTab={setTab}
            survey={survey?.data}
            testId={props.testId + "-header"}
            onDelete={onDelete}
            className="survey-header"
            copy={copy}
          />
          <article>
            <Switch className="main-switch">
              <BasicForm
                languages={languages}
                categories={categories}
                countries={countries}
                survey={survey}
                copy={copy}
                onNameChange={onNameChange}
                className={cx("base", tab === "basic" ? "selected" : "")}
              />
              <ProductForm
                families={families}
                specifiers={specifiers}
                subfamilies={subfamilies}
                copy={copy}
                survey={survey}
                className={cx("product", tab === "product" ? "selected" : "")}
              />
              <StatementsForm
                className={cx(
                  "statements",
                  tab === "statements" ? "selected" : ""
                )}
                survey={survey}
                tab={tab}
              />
              <Instructions
                survey={survey}
                className={cx(
                  "instructions",
                  tab === "instructions" ? "selected" : ""
                )}
              />
            </Switch>
          </article>
          <Footer tab={tab} setTab={setTab} />
        </Grid>
      </Form>
      <Popup
        open={productsChanged}
        text={t("pages.survey.productschanged")}
        onClick={handleProductsChange}
      />
    </SHTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  // & .main-switch {
  //   height: 100%;
  //   box-sizing: border-box;
  // }
  & form {
    height: 100%;
  }
  & .survey-header {
    padding: ${theme.padding.page};
    box-sizing: border-box;
  }
  & article {
    height: 100%;
    overflow-y: auto;
    padding: ${theme.padding.page};
    box-sizing: border-box;
  }
  & .footer {
    background-color: ${theme.colors.Primary.White};
    padding: 1rem ${theme.padding.page};
    box-sizing: border-box;
  }
`;
// helper functions
function extractAttributes(data?: AttributeSubmit) {
  if (!data) return [];

  let array: IAttributeSubmit = [];
  if (data.ATTRIBUTE) array = array.concat(data.ATTRIBUTE);
  if (data.MOOD) array = array.concat(data.MOOD);
  if (data.COLOR) array = array.concat(data.COLOR);
  if (data.IMAGE) array = array.concat(data.IMAGE);
  if (data.BRAND) array = array.concat(data.BRAND);

  return array;
}
