import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { TabTypes } from "utils/types/survey";

// models
import { ISurvey, SurveyParadigm } from "utils/model/Survey";

// atoms
import { Box } from "ui/atoms/Box";
import { Flex } from "ui/atoms/Flex";
import { Switch } from "ui/atoms/Switch";
import { Typography } from "ui/atoms/Typography";
import { BigButton } from "ui/molecules/BigButton";
import { Background } from "ui/atoms/Background";

// local imports
import { Explicit, ISubmit as IExplicitSubmit } from "./Explicit";
import { Learning } from "./Learning";

export interface Props extends IProps<HTMLDivElement> {
  tab: TabTypes;
  survey?: UseQueryResult<ISurvey, unknown>;
}

export const Form: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [step, setStep] = React.useState<StepType>("base");

  React.useEffect(() => {
    if (props.survey?.data) {
      setStep(props.survey.data.paradigm || "base");
    }
  }, [props.survey?.data]);

  function setParadigm(value: StepType) {
    setStep(value);
  }

  return (
    <Box
      {...props}
      className={cx(
        props.className,
        styles(props, theme),
        "survey-form-statements"
      )}
    >
      <input
        hidden
        name="paradigm"
        readOnly
        value={step !== "base" ? step : ""}
      />

      {step === "base" && (
        <Box className="base" testId={props.testId + "-base"}>
          <Typography variant="overline">
            {t("pages.survey.forms.statements.welcome")}
          </Typography>
          <Flex justifyContent="space-between" colGap="0.6rem" className="mt2">
            <BigButton onClick={() => setStep("EXPLICIT")} color="primary">
              <Background
                shape="pill"
                x="75%"
                y="5%"
                size={50}
                color={theme.colors.Primary.Crimson}
              />
              <Background
                shape="pill"
                x="15%"
                y="70%"
                size={35}
                rotate={-70}
                // style={{ height: "50%", top: "50%" }}
                color={theme.colors.Primary.Crimson}
              />
              <Typography variant="subtitle3">
                {t("pages.survey.forms.statements.select.explicit")}
              </Typography>
            </BigButton>
            <BigButton onClick={() => setStep("LEARNING")} color="darker">
              <Background
                shape="cone"
                x="10%"
                y="90%"
                noblend
                rotate={40}
                size={70}
                color={theme.colors.Neutral.Gray3}
              />
              <Background
                shape="cone"
                x="80%"
                y="25%"
                size={40}
                noblend
                rotate={-60}
                color={theme.colors.Neutral.Gray3}
              />
              <Typography variant="subtitle3">
                {t("pages.survey.forms.statements.select.learning")}
              </Typography>
            </BigButton>
          </Flex>
        </Box>
      )}
      {step === "EXPLICIT" && (
        <Explicit
          data={props.survey?.data}
          setParadigm={setParadigm}
          testId={props.testId + "-explicit"}
        />
      )}
      {step === "LEARNING" && (
        <Learning
          setParadigm={setParadigm}
          testId={props.testId + "-explicit"}
        />
      )}
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  height: 100%;

  & .switch > .box {
    display: none;

    &.selected {
      display: block;
    }
  }

  & .button.big {
    overflow: hidden;
    max-height: 30rem;

    & .typography {
      z-index: 2;
      color: ${theme.colors.Primary.White};
    }

    & .background {
      position: absolute;
      left: 0;
      width: 100%;
    }
    & .background + .background {
      left: auto;
      right: 0;
    }
  }
`;

// types & interfaces
export type ISubmit = IExplicitSubmit;
type StepType = SurveyParadigm | "base";
