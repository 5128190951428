import React from "react";
import {css, cx} from "@emotion/css";
import {TFunction, useTranslation} from "react-i18next";
import {UseQueryResult} from "react-query";

// utils
import {IProps} from "utils/types/html";
import {Theme} from "utils/types/theme";
import {useTheme} from "utils/theme";
// import { UpdateOppositeInputOnchange } from "utils/function/form";
import {RenderOptions} from "utils/function/component";

// modles
import {IProductCategory} from "utils/model/ProductCategory";
import {ICountry} from "utils/model/Country";
import {ILanguage} from "utils/model/Language";
import {IProductType} from "utils/model/ProductType";
import {INameModel} from "utils/model/utils";
import {ISurvey} from "utils/model/Survey";

// atoms
import {Grid} from "ui/atoms/Grid";
import {Box} from "ui/atoms/Box";

// molecules
import {TextField} from "ui/molecules/TextField";
import {Dropdown} from "ui/molecules/Dropdown";
import {log} from "utils/function/console";
import {HTTPError} from "utils/error/http";

export interface Props extends IProps<HTMLDivElement> {
  categories: UseQueryResult<IProductCategory[], HTTPError>;
  countries: UseQueryResult<ICountry[], HTTPError>;
  languages: UseQueryResult<ILanguage[], HTTPError>;
  survey?: UseQueryResult<ISurvey, HTTPError>;
  copy?: boolean;
  onNameChange(event: React.FormEvent<HTMLElement>): void;
}

export const Form: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [types, setTypes] = React.useState<IProductType[]>([]);
  const {t} = useTranslation();

  function categoryChange(
    event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    if (!props.categories.data) return;

    const value = event.currentTarget.value;
    const category = props.categories.data.find(
      (c) => c.id.toString() === value
    );

    if (category) {
      setTypes(category.types);
    } else setTypes([]);
  }

  return (
    <Box
      {...props}
      className={cx(
        props.className,
        styles(props, theme),
        "survey-form-basic-data"
      )}
    >
      {!props.copy && (
        <input
          type="number"
          hidden
          name="id"
          value={props.survey?.data?.id}
          readOnly
        />
      )}
      {props.copy && <input hidden name="status" value="DRAFT" readOnly />}
      <Grid
        areas={{
          default: [
            "survey-name survey-name",
            "company-name product-category",
            "product-type country",
            "language number-test",
            "number-products null",
          ],
        }}
        container
        gap="2rem"
        cols="repeat(2, 1fr)"
        rows="repeat(5, 1fr)"
      >
        <Grid item name="survey-name">
          <TextField
            label={t("pages.survey.forms.basic.name")}
            dom={{
              required: true,
              placeholder: t("pages.survey.forms.basic.placeholders.name"),
              pattern: ".{0,30}$",
            }}
            error={{
              valueMissing: t("pages.survey.forms.basic.errors.namemissing"),
              patternMismatch: t("pages.survey.forms.basic.errors.toolong"),
            }}
            testId={props.testId + "-name"}
            name="name"
            loading={props.survey ? props.survey.isLoading : false}
            defaultValue={props.survey?.data?.name}
            onChange={props.onNameChange}
          />
        </Grid>
        <Grid item name="company-name">
          <TextField
            label={t("pages.survey.forms.basic.companyname")}
            testId={props.testId + "-company"}
            loading={props.survey ? props.survey.isLoading : false}
            name="company"
            defaultValue={props.survey?.data?.company}
            dom={{
              placeholder: t(
                "pages.survey.forms.basic.placeholders.companyname"
              ),
            }}
          />
        </Grid>
        <Grid item name="product-category">
          <Dropdown
            label={t("pages.survey.forms.basic.productcategory")}
            onChange={categoryChange}
            testId={props.testId + "-category"}
            loading={props.categories.isLoading}
            defaultValue={props.survey?.data?.productCategory?.id}
            dom={{
              placeholder: t("pages.survey.forms.basic.placeholders.category"),
            }}
            number
          >
            {RenderOptions(props.categories, props.testId + "-category", t)}
          </Dropdown>
        </Grid>
        <Grid item name="product-type">
          <Dropdown
            label={t("pages.survey.forms.basic.producttype")}
            loading={props.survey ? props.survey.isLoading : false}
            name="productTypeId"
            testId={props.testId + "-productTypeId"}
            defaultValue={
              types ? props.survey?.data?.productType?.id : undefined
            }
            number
            dom={{
              placeholder: t(
                "pages.survey.forms.basic.placeholders.producttype"
              ),
            }}
          >
            {RenderOptions(
              {data: types} as UseQueryResult<IProductType[], HTTPError>,
              props.testId + "-productTypeId",
              t
            )}
          </Dropdown>
        </Grid>
        <Grid item name="country">
          <Dropdown
            label={t("pages.survey.forms.basic.country")}
            testId={props.testId + "-country"}
            loading={props.countries.isLoading}
            name="countryId"
            defaultValue={props.survey?.data?.country?.id}
            number
            dom={{
              placeholder: t("pages.survey.forms.basic.placeholders.country"),
            }}
          >
            {RenderOptions(props.countries, props.testId + "-country", t)}
          </Dropdown>
        </Grid>
        <Grid item name="language">
          <Dropdown
            label={t("pages.survey.forms.basic.language")}
            testId={props.testId + "-language"}
            loading={props.languages.isLoading}
            name="languageId"
            defaultValue={props.survey?.data?.language?.id}
            number
            dom={{
              required: true,
              placeholder: t("pages.survey.forms.basic.placeholders.language"),
            }}
            error={{
              valueMissing: t(
                "pages.survey.forms.basic.errors.languagemissing"
              ),
            }}
          >
            {RenderOptions(props.languages, props.testId + "-language", t)}
          </Dropdown>
        </Grid>
        <Grid item name="number-test">
          <TextField
            label={t("pages.survey.forms.basic.productsPerTest")}
            name="productsPerTest"
            testId={props.testId + "-productsPerTest"}
            defaultValue={props.survey?.data?.productsPerTest?.toString()}
            dom={{
              type: "number",
              min: 1,
              required: true,
              placeholder: t(
                "pages.survey.forms.basic.placeholders.productsPerTest"
              ),
            }}
            error={{
              valueMissing: t(
                "pages.survey.forms.basic.errors.productsPerTestmissing"
              ),
              rangeUnderflow: t(
                "pages.survey.forms.basic.errors.productsPerTestunderflow"
              ),
            }}
          />
        </Grid>
        {/* <Grid item name="number-products">
          <TextField
            label={t("pages.survey.forms.basic.numberproducts")}
            testId={props.testId + "-products"}
            defaultValue={props.survey?.data?.products.length.toString()}
            dom={{
              type: "number",
              min: 0,
              id: "alphaProducts",
            }}
            onChange={updateOppositeOnchange("#betaProducts")}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;

// types & interfaces
export interface ISubmit {
  id?: number;
  name: string;
  company: string;
  productsPerTest: number;
  languageId: number;
  countryId: number;
  productTypeId: number;
}

export interface ISubmitUpdate extends ISubmit {
  id: number;
}
