import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { info } from "utils/function/console";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";
import { Typography } from "ui/atoms/Typography";

// molecules
import { Image } from "ui/molecules/Image";

import { Play } from "./astroids";

export interface Props extends IProps<HTMLDivElement> {}

export const Construction: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { className, ...rest } = props;
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [score, setScore] = React.useState(0);
  const [lives, setLives] = React.useState(-1);
  const dimensionRef = React.useRef<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const { t } = useTranslation();

  React.useLayoutEffect(() => {
    if (canvasRef.current) {
      const box = canvasRef.current.getBoundingClientRect();
      dimensionRef.current.width = box.width;
      dimensionRef.current.height = box.height;
      canvasRef.current.width = box.width;
      canvasRef.current.height = box.height;
    }
  }, []);

  function start() {
    setLives(3);
    setScore(0);

    Play(
      setLives,
      setScore,
      dimensionRef.current.width,
      dimensionRef.current.height
    );
  }

  return (
    <Box
      className={cx(
        className,
        styles({ ...props, lives }, theme),
        "construction"
      )}
      {...rest}
    >
      <Flex justifyContent="center" alignItems="center" direction="column">
        {lives === -1 && (
          <div>
            <Image
              loadDuration={300}
              src="/assets/images/cleaning2.jpg"
              alt="cleaning"
              height={350}
              placeholderHeight={350}
              placeholderWidth={600}
            />
          </div>
        )}
        {lives < 1 && (
          <>
            <Typography variant="headline6">
              {t(
                lives === -1
                  ? "molecules.construction.welcomemessage"
                  : "molecules.construction.restartmessage"
              )}
            </Typography>
            <Typography>
              {t(
                lives === -1
                  ? "molecules.construction.welcomesubmessage"
                  : "molecules.construction.restartsubmessage"
              )}
            </Typography>
          </>
        )}
        <br />
        {lives > 0 && (
          <Flex alignItems="flex-start">
            <Typography style={{ marginRight: "2rem" }}>
              {t("molecules.construction.lives", { lives })}
            </Typography>
            <Typography>
              {t("molecules.construction.score", { score })}
            </Typography>
          </Flex>
        )}
        {lives === 0 && (
          <Button color="darker" onClick={start}>
            {t("molecules.construction.restart")}
          </Button>
        )}
        {lives === -1 && (
          <Button color="darker" onClick={start}>
            {t("molecules.construction.play")}
          </Button>
        )}
      </Flex>
      <canvas ref={canvasRef}>{t("molecules.construction.canvas")}</canvas>
    </Box>
  );
};

// types & interfaces
interface CSSProps extends Props {
  lives: number;
}

// background-image: url("/assets/images/cleaning2.jpg");
// background-repeat: no-repeat;
// css design
const styles = (props: CSSProps, theme: Theme) => css`
  position: relative;
  width: 100%;
  height: 100%;

  & > .flex {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & .flex {
      padding: 2rem;
    }
  }

  & > canvas {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;
