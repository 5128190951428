import React, { ReactNode } from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { log } from "utils/function/console";

// NOTE this is a special atom with use of state

type ValueScope = number | string | "full";
type Dimension = { width: number; height: number };
export interface Props extends IProps<HTMLSpanElement> {
  from: ValueScope;
  to: ValueScope;
  target: "width" | "height";
  duration?: number;
  method?: "ease" | "ease-out" | "ease-in-out" | "ease-in" | "linear";
  expanded: boolean;
  children: ReactNode;
}

export const Collapse: React.FC<Props> = (props) => {
  const targetRef = React.useRef<HTMLSpanElement>(null);
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [dimension, setDimension] = React.useState<Dimension>({
    width: 0,
    height: 0,
  });

  // FIXME needs to accept changing children
  React.useLayoutEffect(() => {
    if (targetRef.current) {
      const box = targetRef.current.getBoundingClientRect();

      setDimension({
        width: box.width,
        height: box.height,
      });
      setLoading(false);
    }
  }, []);

  const cssprops: CSSProps = {
    ...props,
    dimension,
  };

  return (
    <span
      {...props.dom}
      className={cx(
        props.className,
        styles(cssprops, theme),
        "collapse",
        loading ? "" : props.expanded ? "expanded" : "contracted"
      )}
      data-testid={props.testId}
      style={props.style}
      ref={targetRef}
    >
      {props.children}
    </span>
  );
};

interface CSSProps extends Props {
  dimension: Dimension;
}

// css design
const styles = (props: CSSProps, theme: Theme) => css`
  position: relative;
  overflow: hidden;
  display: inline-block;
  transition: ${props.target} ${props.duration || theme.animation.default}ms
    ${props.method || "ease"};

  &.expanded {
    ${props.target}: ${getValue(props.to, props.dimension[props.target])};
  }
  &.contracted {
    ${props.target}: ${getValue(props.from, props.dimension[props.target])};
  }
`;

function getValue(value: ValueScope, max: number) {
  if (value === "full") return `${max}px`;

  if (typeof value === "string") {
    if (value.endsWith("%")) {
      const procent = Number(value.substring(0, value.length - 1)) / 100;
      value = max * procent;
    } else return value;
  }
  return value + "px";
}
