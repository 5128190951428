import { css } from "@emotion/css";
import React from "react";

// utils
import { IProps, CSSDirection, CSSJustify } from "utils/types/html";

export interface Props extends IProps {
  justifyContent?: CSSJustify;
  alignItems?: CSSJustify;
  direction?: CSSDirection;
  colGap?: string;
  rowGap?: string;

  noHeight?: boolean;
}

export const Flex: React.FC<Props> = (props) => {
  return (
    <div
      {...props.dom}
      style={props.style}
      className={["flex", styles(props), props.className].join(" ")}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
};

// design
const styles = (props: Props) => css`
  display: flex;
  ${!props.noHeight ? "height: 100%;" : ""}
  justify-content: ${props.justifyContent ?? "unset"};
  flex-direction: ${props.direction ?? "unset"};
  align-items: ${props.alignItems ?? "unset"};

  & > * {
    ${props.colGap ? `margin-right: ${props.colGap};` : ""}
    ${props.colGap ? `margin-left: ${props.colGap};` : ""}
    ${props.rowGap ? `margin-top: ${props.rowGap};` : ""}
    ${props.rowGap ? `margin-bottom: ${props.rowGap};` : ""}

    &:first-child {
      ${props.colGap ? `margin-left: 0;` : ""}
      ${props.rowGap ? `margin-top: 0;` : ""}
    }
    &:last-child {
      ${props.colGap ? `margin-right: 0;` : ""}
      ${props.rowGap ? `margin-bottom: 0;` : ""}
    }
  }
`;
// helper functions
