import React from "react";
import {css, cx} from "@emotion/css";

// utils
import {IProps} from "utils/types/html";
import {Theme} from "utils/types/theme";
import {useTheme} from "utils/theme";
import {IAnswer} from "utils/types/test";

// atoms
import {Icon} from "ui/atoms/Icon";

// molecules
import {TestButton} from "ui/molecules/TestButton";

// templates
import {TesterButtonsTemplate} from "ui/templates/TesterButtons";

import {log} from "utils/function/console";
import {TesterSingleTemplate} from "ui/templates/TesterSingle";

export interface Props extends IProps<HTMLDivElement> {
  title: string;
  isMobile: boolean;
  continueClick(): void;
}

export const TestCallibration: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [thumbs, setThumbs] = React.useState<number>(0);

  function press() {
    if (thumbs === 1) {
      props.continueClick();
    } else setThumbs(() => thumbs + 1);
  }

  function release() {
    setThumbs(() => thumbs - 1);
  }

  if (!props.isMobile) {
    // TODO needs anthor text for non-mobile case as the default text is like: 
    // Tap both fingerprint buttons with your thumbs at the same time to continue.
    return <TesterSingleTemplate {...props} continue={props.title} />;
  }

  return (
    <TesterButtonsTemplate
      {...props}
      alignBottom
      left={{
        children: <Icon type="thumb" />,
        dom: {
          onTouchStart: press, // should work on both touch & non-touch for testing purposes
          onTouchEnd: release,
        },
      }}
      right={{
        children: <Icon type="thumb" />,
        dom: {
          onTouchStart: press, // should work on both touch & non-touch for testing purposes
          onTouchEnd: release,
        },
      }}
      className={cx(props.className, styles(props, theme), "test-callibration")}
    />
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
