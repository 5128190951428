import { ISurvey } from "utils/model/Survey";
import { attributes } from "./attributes";
import { productcategories } from "./productcategories";
import { products } from "./products";

const data: ISurvey = {
  id: "abc",
  name: "Study name is displayed here second line",
  productCategory: productcategories[0],
  owner: {
    email: "tester@test.test",
    firstname: "tester",
    lastname: "test",
    id: 0,
    role: {
      id: 0,
      name: "banana-picker",
    },
  },
  productsPerTest: 10,
  lastModification: {
    time: new Date(2022, 2, 27),
    userName: "John Doe",
    status: "CREATED",
  },
  status: "DRAFT",
  progress: {
    current: 23,
    max: 50,
  },
  productType: {
    id: 0,
    name: "a",
  },
  surveyAttributes: [...attributes],
  numberOfProducts: products.length,
  products: [...products],
  country: { id: 0, name: "Hungary" },
  language: { id: 0, name: "Magyar" },
  favorite: false,
  paradigm: "EXPLICIT",
  company: "companyname",

  instruction: {
    welcome: "welcome",
    userIdPrompt: "userIdPrompt",
    userIdCheck: "userIdCheck",
    testInstructionLong: "testInstructionLong",
    textInstructionShort: "textInstructionShort",
    testInstructionImageShort: "testInstructionImageShort",
    experienceProductInstruction: "experienceProductInstruction",
    warmupYesNoInstructionLong: "warmupYesNoInstructionLong",
    warmupYesNoInstructionShort: "warmupYesNoInstructionShort",
    readSpeedInstructionLong: "readSpeedInstructionLong",
    readSpeedInstructionShort: "readSpeedInstructionShort",
    endSurveyMessage: "endSurveyMessage",
    continueButtonInstruction: "continueButtonInstruction",
    fingerprintInstruction: "fingerprintInstruction",
    calibrationText: "calibrationtext",
    yesText: "yesText",
    noText: "noText",
    okText: "okText",
    readSpeedStatement1: "readSpeedStatement1",
    readSpeedStatement2: "readSpeedStatement2",
    readSpeedStatement3: "readSpeedStatement3",
  },
};

export const surveys: ISurvey[] = [
  { ...data, id: 0 },
  { ...data, id: 1 },
  { ...data, id: 2 },
  { ...data, id: 3, status: "COMPLETED" },
  { ...data, id: 4, status: "PUBLISHED" },
  { ...data, id: 5, status: "COMPLETED" },
  { ...data, id: 6, status: "COMPLETED" },
  { ...data, id: 7, status: "PUBLISHED" },
  { ...data, id: 8, status: "PUBLISHED" },
  { ...data, id: 9, status: "COMPLETED" },
];
