import React from "react";
import { IConfig } from "utils/types/router";
import { LoginPage as Component } from "./Component";

export * from "./Component";

export const LoginPage = React.memo(Component);

export const Config: IConfig = {
  authcheck: () => {
    return true;
  },
};
