import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// models
import { ISummaryAttribute } from "utils/model/Attribute";
import { ISummary, SummaryProducts, SummaryResult } from "utils/model/Summary";

// atoms
import { Grid } from "ui/atoms/Grid";

// molecules
import { Collapser } from "ui/molecules/Collapser";
import { CardSummaryAttribute } from "ui/molecules/CardSummaryAttribute";
import { Flex } from "ui/atoms/Flex";
import { CardSummaryCircular } from "ui/molecules/CardSummaryCircular";

export interface Props extends IProps<HTMLDivElement> {
  data?: ISummary;
  highYes?: boolean; // FIXME
  loading?: boolean;
  attribute?: string;
}

export const SummaryCollapse: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  // NOTE they are all grouped into same attribute type thus we can take 0
  const attributeType = props.data ? props.attribute : "ATTRIBUTE";
  const attributes = props.data?.attributes.filter(
    (x) => x.type === props.attribute
  );
  const percentage = (threshold: number, count: number) => {
    const x = (threshold / count) * 100;
    return Math.round(x);
  };

  const countAttribute = props.data?.attributes.filter(
    (att) => att.type === props.attribute
  ).length;

  props.data?.results.forEach((res) => {
    return res.products.sort((a, b) => a.productId - b.productId);
  });

  return (
    <Collapser
      {...props}
      setOpen={setOpen}
      open={open}
      text={
        t(`organisms.summarycollapse.${attributeType?.toLowerCase()}`, {}) +
        ` (${countAttribute})`
      }
      className={cx(props.className, styles(props, theme), "summary-collapse")}
    >
      <Grid
        container
        cols={`${theme.components.summaryCard.width} 1fr`}
        gap="1.5rem"
        rows={`repeat(${attributes?.length || 0}, 2fr)`}
      >
        {attributes?.map((attribute) => [
          <CardSummaryAttribute
            attributeName={attribute.translation}
            key={attribute.id}
          />,
          <Flex colGap="0.5rem">
            {props.data?.results
              .find((x) => x.attributeId === attribute?.id)
              ?.products.map((product) => (
                <CardSummaryCircular
                  percentage={
                    props.highYes
                      ? percentage(product.nHiYes, product.count)
                      : percentage(product.nYes, product.count)
                  }
                  key={attribute.id + "." + product.productId}
                />
              ))}
          </Flex>,
        ])}
      </Grid>
    </Collapser>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;

// types & interfaces

export type Attribute = ISummaryAttribute & {
  products: SummaryProducts[];
};
