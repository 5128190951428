// utils
import { ID } from "utils/types/model";
import { doFetch } from "utils/api/utils";
import { IAnswer, IAuth, IReadingTime } from "utils/types/test";

export async function fetchSurvey(id: ID) {
  return await doFetch(`/api/surveys/${id}`);
}

export async function fetchRotation() {
  return await doFetch(`/api/testers/survey`);
}

export async function authenticate(auth: IAuth) {
  return await doFetch("/api/testers/auth", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(auth),
  });
}

export async function readingtime(data: IReadingTime) {
  return await doFetch("/api/respondents/readingTime", {
    method: "put",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export async function answer(answer: IAnswer) {
  return await doFetch("/api/answers", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(answer),
  });
}
