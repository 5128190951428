import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// template
import {
  InputTemplate,
  Props as InputTemplateProps,
  ElementTypes,
} from "ui/templates/Input";

export interface Props
  extends Omit<InputTemplateProps<HTMLTextAreaElement>, "children"> {
  resize?: "none" | "vertical" | "horizontal" | "both";
  rows?: number;
  defaultValue?: string;
}

export const Textarea: React.FC<Props> = (props) => {
  const theme = useTheme();
  const ref = React.useRef<HTMLTextAreaElement>(null);

  const { dom, ...rest } = props;

  React.useEffect(() => {
    if (ref.current) {
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  }, []);

  function onInput(onChange: (e: React.FormEvent<ElementTypes>) => void) {
    // TODO auto resizing
    return (e: React.FormEvent<ElementTypes>) => {
      const ta = e.target as HTMLTextAreaElement;
      ta.style.height = "auto";
      ta.style.height = ta.scrollHeight + "px";

      onChange(e);
    };
  }

  return (
    <InputTemplate {...rest} testId={props.testId + "-wrapper"}>
      {(onChange) => (
        <textarea
          {...props.dom}
          ref={ref}
          name={props.name}
          rows={props.rows || 1}
          className={cx(
            props.className,
            styles(props, theme),
            "textarea",
            "selector"
          )}
          data-testid={props.testId}
          defaultValue={props.defaultValue}
          onInput={onInput(onChange)}
        />
      )}
    </InputTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  resize: ${props.resize ? props.resize : "none"};
`;
