// basic imports
import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// util imports
import { useTheme } from "utils/theme";
import { HeaderVariant, Theme } from "utils/types/theme";
import { Pages } from "utils/types/router";
import { IProps } from "utils/types/html";

// models
import { IUser } from "utils/model/User";
import { IMessage } from "utils/model/Message";
import { INotification } from "utils/model/Notification";

// atoms
import { Box } from "ui/atoms/Box";
import { Button } from "ui/atoms/Button";
import { Flex } from "ui/atoms/Flex";
import { Typography } from "ui/atoms/Typography";
import { Background } from "ui/atoms/Background";

// molecules
import { IconWithBadge } from "ui/molecules/IconWithBadge";
import { info } from "utils/function/console";
import { useLocation, useNavigate } from "react-router-dom";
import { getRouteName } from "utils/function/router";

// local imports

// defining Props
interface Props extends IProps {
  variant?: HeaderVariant;
  notifications: INotification[];
  messages: IMessage[];
  title?: string;
  user?: IUser;
  logout(): void;
}

// defining Component as function component
export const Header: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  let background: string;
  let iconcolor: string = theme.colors.Primary.White;
  switch (props.variant || theme.components.header.variant) {
    default:
      background = theme.colors.Primary.Crimson;
      break;
    case "dark":
      background = theme.colors.Neutral.Gray3;
      break;
    case "light":
      background = theme.colors.Neutral.Gray1;
      iconcolor = theme.colors.Neutral.Gray2;
      break;
    case "medium":
      background = theme.colors.Neutral.Gray2;
      break;
    case "secondary":
      background = theme.colors.Secondary.Crimson;
      break;
  }

  function onMessageClick() {
    info("message click");
  }
  function onNotificationClick() {
    info("notification click");
  }
  function onUserClick() {
    if (props.user) {
      // logout
      props.logout();
    } else {
      // login
      navigate("/login");
    }
  }

  const routeName = getRouteName(location);

  return (
    <Box
      className={cx(styles(props, theme), "header")}
      testId={props.testId}
      color={background}
      padding="32px 24px 32px 40px"
    >
      <Background shape="toroid" size={60} x="60%" y="-5%" color={background} />
      <Flex justifyContent="space-between" alignItems="center">
        <Typography
          variant="headline6"
          testId={props.testId + "-title"}
          color={
            props.variant === "light"
              ? theme.colors.Neutral.Gray3
              : theme.colors.Primary.White
          }
        >
          {props.title || t(`router.${routeName}.name`)}
        </Typography>
        <Flex
          justifyContent="space-between"
          className={buttonStyles(props, theme)}
          alignItems="center"
        >
          <Button
            testId={props.testId + "-notifications"}
            shape="plain"
            onClick={onNotificationClick}
          >
            <IconWithBadge
              color={iconcolor}
              badge={props.notifications.length > 0}
              type="bell"
            />
          </Button>
          <Button
            testId={props.testId + "-messages"}
            shape="plain"
            onClick={onMessageClick}
          >
            <IconWithBadge
              color={iconcolor}
              badge={props.messages.length > 0}
              type="message"
            />
          </Button>
          <Button
            onClick={onUserClick}
            testId={props.testId + "-user"}
            color={props.variant === "light" ? "primary" : "lighter"}
          >
            {props.user ? t("user.logout") : t("user.login")}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  position: relative;
  box-sizing: border-box;
  height: ${theme.components.header.height}px;
  overflow: hidden;

  & > .flex {
    position: relative;
    z-index: 2;
  }
`;
const buttonStyles = (props: Props, theme: Theme) => css`
  width: 20rem;
`;

// helper functions
