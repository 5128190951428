import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Textarea, Props as TextareaProps } from "ui/atoms/Textarea";

// templates
import { FieldTemplate } from "ui/templates/Field";

export interface Props extends TextareaProps {
  label?: string;
  loading?: boolean;
}

export const TextareaField: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { dom, testId, ...rest } = props;

  return (
    <FieldTemplate
      {...rest}
      className={cx(props.className, styles(props, theme), "text-field")}
    >
      <Textarea {...props} dom={{ ...props.dom }} />
    </FieldTemplate>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
