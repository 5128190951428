import React, { useState } from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Typography } from "ui/atoms/Typography";
import { Box } from "ui/atoms/Box";
import { Collapse } from "ui/atoms/Collapse";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";

export interface Props extends IProps<HTMLDivElement> {
  open?: boolean;
  setOpen(state: boolean): void;
  text: string;
}

export const Collapser: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      className={cx(props.className, styles(props, theme), "collapser")}
      // key={props.text}
    >
      <Button
        shape="plain"
        testId={props.testId + "-button"}
        onClick={() => props.setOpen(!props.open)}
        className="buttonCollapse"
      >
        <Typography variant="overline">{props.text}</Typography>
        <Icon type="caret" rotate={props.open ? 270 : 90} />
      </Button>
      <Collapse
        target="height"
        from="0"
        to="100%"
        method="ease-out"
        expanded={!!props.open}
        testId={props.testId + "-collapse"}
        style={{ width: "100%" }}
      >
        {props.children}
      </Collapse>
      {!props.open && <Box className="separationBar" />}
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  .separationBar {
    border-top: 1px solid #dcdcdc;
  }
  .buttonCollapse {
    padding-left: 0rem !important;
  }
`;
