import {css} from "@emotion/css";
import React from "react";

// utils
import {IProps} from "utils/types/html";
import {useTheme} from "../../../utils/theme";
import {Theme} from "../../../utils/types/theme";

export interface Props extends IProps {
  onChange(value: number): void;
  value?: number;
  defaultValue?: number;
}

export const NumberField: React.FC<Props> = (props) => {
  const theme = useTheme();

  function onChange(e: any) {
    if (e.target.value.length === 0) {
      props.onChange(0);
    } else {
      const goodValue = parseFloat(e.target.value);
      props.onChange(goodValue);
    }
  }

  return (
    <input
      {...props.dom}
      style={props.style}
      data-testid={props.testId}
      className={[styles(props, theme), props.className, "numberField"].join(
        " "
      )}
      type="number"
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={onChange}
    />
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  width: 88px;
  height: 48px;
  text-align: center;
  background-color: ${theme.colors.Neutral.Gray1};
  border-radius: 8px;
  border: 1px solid ${theme.colors.Neutral.Gray4} !important;
  font-weight: 500;
  font-size: 16px;

  :focus {
    outline: none !important;
    border: 1px solid black !important;
  }

  :active {
    outline: none !important;
    border: 1px solid black !important;
  }

  :hover {
    outline: none !important;
  }
`;
