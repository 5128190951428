import React, { useState } from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { products } from "utils/mock/data/products";

// models
import { IProduct } from "utils/model/Product";

// atoms
import { Flex } from "ui/atoms/Flex";
import { Grid } from "ui/atoms/Grid";
import { Typography } from "ui/atoms/Typography";

// molecules
import { CardSummaryProduct as ProductCard } from "ui/molecules/CardSummaryProduct";
import { LoadComponent } from "ui/molecules/LoadComponent";

export interface Props extends IProps<HTMLDivElement> {
  products?: Pick<IProduct, "code" | "id" | "name">[];
}

export const SummaryAttributeType: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const data = props.products || [products.slice(0, 3)];

  return (
    <Grid
      {...props}
      colGap="1.5rem"
      container
      cols={`${theme.components.summaryCard.width} 1fr`}
      rows="auto 1fr"
      areas={["empty text", "empty cards"]}
      className={cx(
        props.className,
        styles(props, theme),
        "summary-attribute-types"
      )}
    >
      <Grid item name="text">
        <Typography variant="overline" className="mb1">
          {t("organisms.summaryattributetypes.products", {
            total: data.length,
          })}
        </Typography>
      </Grid>
      <Grid item name="cards">
        <Flex colGap="0.5rem">
          {props.products?.map((product) => (
            <LoadComponent
              key={product.id}
              loading={!props.products}
              height={theme.components.summaryCard.height.small}
              width={theme.components.summaryCard.width}
            >
              <ProductCard
                imagePath="https://images.pexels.com/photos/3094799/pexels-photo-3094799.jpeg?cs=srgb&dl=pexels-akwice-3094799.jpg&fm=jpg"
                productCode={product.code}
                productName={product.name}
                key={product.id}
                testId={props.testId + "-productcard"}
              />
            </LoadComponent>
          ))}
        </Flex>
      </Grid>
    </Grid>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  height: auto !important;
`;
