import { css } from "@emotion/css";

// utils
import { Theme } from "utils/types/theme";

import { Props } from "./utils";

// css design
export const styles = (props: Props, theme: Theme) => css`
  padding: 2rem;
  background-color: ${theme.colors.Primary.White};
  & > * {
    margin-top: 0.8rem;
  }

  & > .title {
    margin-top: 0rem;
    margin-bottom: 0.8rem;
  }

  & > .additional {
    margin-top: 2rem;

    & .additional-flex {
      & .typography {
        margin-bottom: 0;
      }
      & .icon {
        margin-right: 1rem;
      }
    }
  }

  & .flex > .text-field {
    width: 100%;
    &:first-child {
      margin-right: 1rem;
      box-sizing: border-box;
    }
    &:last-child {
      margin-left: 1rem;
      box-sizing: border-box;
    }
  }
`;
