import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { useTheme } from "utils/theme";
import { Theme } from "utils/types/theme";

export interface Props extends IProps<HTMLSpanElement> {
  value: number;
}

export const ProgressBar: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <span
      {...props.dom}
      className={cx(props.className, styles(props, theme), "progressbar")}
      data-test-id={props.testId}
    />
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  content: "";
  position: relative;
  border-radius: 0.3rem;
  height: 0.45rem;
  width: 100%;
  display: inline-block;
  background-color: ${theme.colors.Neutral.Gray1};
  z-index: 1;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    width: ${props.value}%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;

    background-color: ${theme.colors.Success.Green1};
  }
`;
