import { createContext } from "react";
import { UseMutationResult, UseQueryResult } from "react-query";

// utils
import { HTTPError } from "utils/error/http";
import { IAuthResponds, IAuth, IReadingTime, IAnswer } from "utils/types/test";

// models
import { ISurvey } from "utils/model/Survey";
import { IRotationTester } from "utils/model/Rotation";

// local
// import { ISubmit } from "./types";

export const Context = createContext<IContext>({
  survey: {} as UseQueryResult<ISurvey, HTTPError>,
  rotation: {} as UseQueryResult<IRotationTester, HTTPError>,
  readingtime: {} as UseMutationResult<unknown, HTTPError, IReadingTime>,
  authenticate: {} as UseMutationResult<IAuthResponds, HTTPError, IAuth>,
  answer: {} as UseMutationResult<unknown, HTTPError, IAnswer>,
});

export interface IContext {
  survey: UseQueryResult<ISurvey, HTTPError>;
  rotation: UseQueryResult<IRotationTester, HTTPError>;
  readingtime: UseMutationResult<unknown, HTTPError, IReadingTime>;
  authenticate: UseMutationResult<IAuthResponds, HTTPError, IAuth>;
  answer: UseMutationResult<unknown, HTTPError, IAnswer>;
}
