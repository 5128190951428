import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Button, Props } from "ui/atoms/Button";

export type { Props };

export const TestButton: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Button
      {...props}
      shape="circle"
      className={cx(props.className, styles(props, theme), "test-button")}
    >
      {props.children}
    </Button>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  width: 160px;
  height: 160px;
`;
