import React from "react";

// atoms
import { Button, Props } from "ui/atoms/Button";
import { useForm } from "utils/hook/form";

export const SubmitButton: React.FC<Props> = (props) => {
  const { errors } = useForm();
  return (
    <Button
      testId="submit"
      {...props}
      type="submit"
      disabled={Object.keys(errors).length > 0}
    >
      {props.children}
    </Button>
  );
};
