import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";

// utils
import { useTheme } from "utils/theme";
import { Theme } from "utils/types/theme";
import { IProps } from "utils/types/html";
import { HTTPError } from "utils/error/http";

// models
import { ISurvey } from "utils/model/Survey";

// atoms
import { Grid } from "ui/atoms/Grid";
import { Box } from "ui/atoms/Box";
import { Flex } from "ui/atoms/Flex";
import { Typography } from "ui/atoms/Typography";
import { Badge } from "ui/atoms/Badge";

// molecules
import { SurveyItem } from "ui/molecules/SurveyItem";
import { LoadComponent } from "ui/molecules/LoadComponent";
import { IPaginationResponse } from "utils/types/api";
import { surveys as surveysmock } from "utils/mock/data/surveys";

export interface Props extends IProps<HTMLDivElement> {
  name: string;
  surveys: UseQueryResult<IPaginationResponse<ISurvey>, HTTPError>;
  favoriteClick(data: ISurvey): void;
}

export const Table: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const surveys: IPaginationResponse<ISurvey> = props.surveys.data?.items
    ? props.surveys.data
    : ({ items: surveysmock.slice(0, 3) } as IPaginationResponse<ISurvey>);

  return (
    <section
      className={cx(rootStyles(props, theme), props.className, "survey-table")}
      data-test-id={props.testId}
    >
      <Flex noHeight alignItems="center">
        <Typography style={{ marginRight: "1rem" }} variant="subtitle3">
          {props.name}
        </Typography>
        <Badge value={props.surveys.data?.total || 0} />
      </Flex>
      <Box
        radius="8px"
        className={cx(styles(props, theme), "survey-table-header")}
      >
        <Grid alignItems="center" container cols="3fr 6fr 6fr 5fr 6fr 6fr 6fr">
          <Flex alignItems="center">
            <Typography variant="overline" color={theme.colors.Primary.White}>
              {t("common.status")}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography variant="overline" color={theme.colors.Primary.White}>
              {t("survey.name")}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography variant="overline" color={theme.colors.Primary.White}>
              {t("common.category")}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography variant="overline" color={theme.colors.Primary.White}>
              {t("common.date")}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography variant="overline" color={theme.colors.Primary.White}>
              {t("survey.progress")}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography variant="overline" color={theme.colors.Primary.White}>
              {t("survey.productsshort")}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography variant="overline" color={theme.colors.Primary.White}>
              {t("common.country")}
            </Typography>
          </Flex>
        </Grid>
      </Box>
      <Box className="items">
        {surveys.items.map((survey, index) => (
          <LoadComponent
            height="8rem"
            key={survey.id}
            loading={props.surveys.isLoading}
          >
            {props.surveys.isFetched && (
              <SurveyItem
                data={survey}
                testId={props.testId + "-item" + index}
                favoriteClick={props.favoriteClick}
              />
            )}
          </LoadComponent>
        ))}
      </Box>
      <br />
      <br />
    </section>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  background-color: ${theme.colors.Secondary.Crimson};
  padding: 1rem 0;
  margin-bottom: 1.1rem;
  margin-top: 1.3rem;

  & .grid > .flex {
    border-right: 1px solid white;
    padding: 0.1rem 1.5rem;

    &:last-child {
      border-right: none;
    }
  }
`;
const rootStyles = (props: Props, theme: Theme) => css`
  & .survey-item {
    margin: 0.7rem 0;
  }

  & .skeleton {
    margin: 0.7rem 0;
  }
`;
