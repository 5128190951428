import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";

// atoms
import { Skeleton } from "ui/atoms/Skeleton";
import { useDebounce } from "utils/hook/debounce";

export interface Props
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "onLoad"> {
  src: string;
  alt: string;

  onLoad?: (event: Event) => void;
  placeholderHeight?: number;
  placeholderWidth?: number;
  loadDuration?: number;
}

export const Image = React.forwardRef<HTMLImageElement, Props & IProps>(
  (props, ref) => {
    const [loading, setLoading] = React.useState<boolean>(true);

    // preventing flickering
    const load = useDebounce((e: Event) => {
      if (props.onLoad) props.onLoad(e);

      setLoading(false);
    }, props.loadDuration || 0);

    React.useEffect(() => {
      const image = new window.Image();
      image.src = props.src;
      image.onload = load.run;
    }, []);

    if (loading) {
      return (
        <Skeleton
          width={props.placeholderWidth || props.width || "100%"}
          height={props.placeholderHeight || props.height}
          duration={1.4}
          testId={props.testId + "-skeleton"}
        />
      );
    }
    return (
      <img
        {...props.dom}
        data-testid={props.testId}
        alt={props.alt}
        src={props.src}
        className={cx(props.className, styles, "image")}
        ref={ref}
        width={props.width || "100%"}
        height={props.height || "auto"}
      />
    );
  }
);

const styles = css``;
