import { IUser } from "utils/model/User";
// import { HTTPError } from "utils/Error";
import { doFetch, getURL } from "utils/api/utils";
import { ISubmit } from "./types";
import { log } from "utils/function/console";

// type UserResponse = IUser | HTTPError;
export async function login(data: ISubmit) {
  const resdata = await doFetch("/api/users/signin", {
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(data),
  });

  window.localStorage.setItem("token", resdata.accessToken);

  return resdata;
}
