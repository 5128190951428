import React from "react";
import { cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";

// utils
import { useTheme } from "utils/theme";

// models
import { INameModel } from "utils/model/utils";

// atoms
import { Flex } from "ui/atoms/Flex";
import { Typography } from "ui/atoms/Typography";
import { Icon } from "ui/atoms/Icon";
import { Form } from "ui/atoms/Form";

// molecules
import { CardClose as Card } from "ui/molecules/CardClose";
import { TextField } from "ui/molecules/TextField";
import { Dropdown, Option } from "ui/molecules/Dropdown";

// local
import { styles } from "./style";
import { Props } from "./utils";
import { RenderOptions } from "utils/function/component";
import { log } from "utils/function/console";

export const ProductCard: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [AD, setAdditionalFields] = React.useState<Additionalfields>({
    dosage: !!props.data?.dosage,
    family: !!props.data?.family,
    subfamily: !!props.data?.subfamily,
    specifier: !!props.data?.specifier,
    primarysecondarynotes: !!props.data?.primaryNotes,
    primarysecondarydegree: !!props.data?.primaryDegree,
  });

  function onAdditional(event: React.FormEvent) {
    const inputelm = event.currentTarget as HTMLInputElement;
    const values = inputelm.value.split(",");
    // set values
    const copy = { ...StandardAdditionalFields };
    for (const value of values) {
      copy[value] = true;
    }

    // update state
    setAdditionalFields({ ...copy });
  }

  let datakeys = "";
  if (props.data) {
    const added: string[] = [];
    const arrvalues = Object.keys(props.data).filter((name) => {
      const notes = /notes$/.test(name);
      const degree = /degree$/.test(name);
      if (notes && !added.includes("primarysecondarynotes")) {
        added.push("primarysecondarynotes");
      }
      if (degree && !added.includes("primarysecondarydegree")) {
        added.push("primarysecondarydegree");
      }

      return !(notes || degree);
    });
    datakeys = arrvalues.concat(added).join();
  }

  return (
    <Card
      {...props}
      className={cx(props.className, styles(props, theme), "product-card")}
    >
      {!props.copy && props.data?.id !== undefined && (
        <input
          hidden
          name={`products[${props.index}].id`}
          value={props.data.id}
          readOnly
          type="number"
        />
      )}
      <input
        hidden
        name={`products[${props.index}].typeId`}
        value="0"
        type="number"
        readOnly
      />
      <Typography
        className="title"
        variant="overline"
        color={theme.colors.Primary.Crimson}
        testId={props.testId + "-title"}
      >
        {t("organisms.productcard.product", { index: props.index + 1 })}
      </Typography>
      <Dropdown
        defaultValue={props.data?.stimulusType}
        testId={props.testId + "-stimulusType"}
        name={`products[${props.index}].stimulusType`}
        label={t("organisms.productcard.stimulusType.label")}
        dom={{
          placeholder: t("organisms.productcard.stimulusType.placeholder"),
        }}
        variant="plain"
      >
        {["FRAGRANCE", "FLAVOUR", "OTHERS"].map((o) => (
          <Option testId={o} key={o} value={o}>
            {t(`organisms.productcard.stimulusType.${o.toLowerCase()}`)}
          </Option>
        ))}
      </Dropdown>
      <TextField
        testId={props.testId + "-name"}
        defaultValue={props.data?.name}
        name={`products[${props.index}].name`}
        dom={{
          placeholder: t("organisms.productcard.name.placeholder"),
          maxLength: 20,
        }}
        label={t("organisms.productcard.name.label")}
      />
      <Dropdown
        testId={props.testId + "-description"}
        defaultValue={props.data?.description}
        name={`products[${props.index}].description`}
        dom={{
          placeholder: t("organisms.productcard.description.placeholder"),
        }}
        label={t("organisms.productcard.description.label")}
      >
        {["BENCHMARK", "CREATIVE", "NA"].map((o) => (
          <Option testId={o} key={o} value={o}>
            {t(`organisms.productcard.description.${o.toLowerCase()}`)}
          </Option>
        ))}
      </Dropdown>
      <Flex justifyContent="space-between" noHeight>
        <TextField
          testId={props.testId + "-code"}
          defaultValue={props.data?.code}
          name={`products[${props.index}].code`}
          label={t("organisms.productcard.code.label")}
          dom={{
            required: true,
            placeholder: t("organisms.productcard.code.placeholder"),
            maxLength: 10,
          }}
          error={{
            patternMismatch: "UPS MISMATCH",
            tooLong: "UPS TOO LONG",
          }}
        />
        <TextField
          testId={props.testId + "-developerCode"}
          defaultValue={props.data?.developerCode}
          name={`products[${props.index}].developerCode`}
          dom={{
            placeholder: t("organisms.productcard.developerCode.placeholder"),
          }}
          label={t("organisms.productcard.developerCode.label")}
        />
      </Flex>

      {AD.family && (
        <Dropdown
          defaultValue={props.data?.family.id}
          name={`products[${props.index}].familyId`}
          dom={{
            placeholder: t("organisms.productcard.family.placeholder"),
          }}
          label={t("organisms.productcard.family.label")}
          testId={props.testId + "-family"}
          loading={props.families.isLoading}
          number
        >
          {RenderOptions(props.families, props.testId + "-family", t)}
        </Dropdown>
      )}

      {AD.subfamily && (
        <Dropdown
          defaultValue={props.data?.subfamily.id}
          testId={props.testId + "-subfamily"}
          name={`products[${props.index}].subfamilyId`}
          dom={{
            placeholder: t("organisms.productcard.subfamily.placeholder"),
          }}
          label={t("organisms.productcard.subfamily.label")}
          loading={props.subfamilies.isLoading}
          number
        >
          {RenderOptions(props.subfamilies, props.testId + "-subfamily", t)}
        </Dropdown>
      )}

      {AD.specifier && (
        <Dropdown
          defaultValue={props.data?.specifier.id}
          testId={props.testId + "-specifier"}
          name={`products[${props.index}].specifierId`}
          dom={{
            placeholder: t("organisms.productcard.specifier.placeholder"),
          }}
          label={t("organisms.productcard.specifier.label")}
          loading={props.specifiers.isLoading}
          number
        >
          {RenderOptions(props.specifiers, props.testId + "-specifier", t)}
        </Dropdown>
      )}

      {/* TODO they want to display +"%" */}
      {AD.dosage && (
        <TextField
          testId={props.testId + "-dosage"}
          defaultValue={props.data?.dosage?.toString()}
          name={`products[${props.index}].dosage`}
          dom={{
            placeholder: t("organisms.productcard.dosage.placeholder"),
            min: 0,
            type: "number",
          }}
          label={t("organisms.productcard.dosage.label")}
        />
      )}

      {AD.primarysecondarynotes && (
        <Flex justifyContent="space-between" noHeight>
          <TextField
            testId={props.testId + "-primaryNotes"}
            defaultValue={props.data?.primaryNotes}
            name={`products[${props.index}].primaryNotes`}
            dom={{
              placeholder: t("organisms.productcard.primaryNotes.placeholder"),
            }}
            label={t("organisms.productcard.primaryNotes.label")}
          />
          <TextField
            testId={props.testId + "-secondaryNotes"}
            defaultValue={props.data?.secondaryNotes}
            name={`products[${props.index}].secondaryNotes`}
            dom={{
              placeholder: t(
                "organisms.productcard.secondarynotes.placeholder"
              ),
            }}
            label={t("organisms.productcard.secondarynotes.label")}
          />
        </Flex>
      )}

      {AD.primarysecondarydegree && (
        <Flex justifyContent="space-between" noHeight>
          <TextField
            testId={props.testId + "-primaryDegree"}
            defaultValue={props.data?.primaryDegree}
            name={`products[${props.index}].primaryDegree`}
            dom={{
              placeholder: t("organisms.productcard.primarydegree.placeholder"),
            }}
            label={t("organisms.productcard.primarydegree.label")}
          />
          <TextField
            testId={props.testId + "-secondaryDegree"}
            defaultValue={props.data?.secondaryDegree}
            name={`products[${props.index}].secondaryDegree`}
            dom={{
              placeholder: t(
                "organisms.productcard.secondarydegree.placeholder"
              ),
            }}
            label={t("organisms.productcard.secondarydegree.label")}
          />
        </Flex>
      )}

      <Dropdown
        color="primary"
        className="additional"
        onChange={onAdditional}
        defaultValue={datakeys}
        multiple
        testId={props.testId + "-dropdown"}
        text={
          <Flex
            alignItems="center"
            justifyContent="center"
            className="additional-flex"
          >
            <Icon type="plus" color={theme.colors.Primary.White} />
            <Typography variant="overline" color={theme.colors.Primary.White}>
              {t("organisms.productcard.additional")}
            </Typography>
          </Flex>
        }
      >
        {Object.keys(StandardAdditionalFields).map((o) => (
          <Option key={o} value={o} testId={props.testId + "-option-" + o}>
            {t(`organisms.productcard.additionalfields.${o}`)}
          </Option>
        ))}
      </Dropdown>
      {props.children}
    </Card>
  );
};

// types & interfaces
interface Additionalfields extends Record<string, boolean> {
  dosage: boolean;
  family: boolean;
  subfamily: boolean;
  specifier: boolean;
  primarysecondarynotes: boolean;
  primarysecondarydegree: boolean;
}

// constants
const StandardAdditionalFields: Additionalfields = {
  dosage: false,
  family: false,
  specifier: false,
  subfamily: false,
  primarysecondarydegree: false,
  primarysecondarynotes: false,
};
