import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// organisms
import { PageLoader } from "ui/organisms/PageLoader";

// pages
import { DashboardPage } from "ui/pages/Dashboard";
import { SurveyPage } from "ui/pages/Survey";
import { ConstructionPage } from "ui/pages/Construction";
import { LoginPage } from "ui/pages/Login";
import { TesterPage } from "ui/pages/Tester";
import { SummaryPage } from "ui/pages/Summary";

export const Router: React.FC = () => {
  const { t } = useTranslation();
  function path(name: string) {
    return t(`router.${name}.route`);
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path={path("home")} element={<DashboardPage />} />
          <Route path={path("login")} element={<LoginPage />} />
          <Route
            path={path("bigDataAnalytics")}
            element={<ConstructionPage />}
          />
          <Route
            path={path("traditionalSurveys")}
            element={<ConstructionPage />}
          />
          <Route
            path={path("centralLocationTest")}
            element={<ConstructionPage />}
          />
          <Route path={path("homeUserTest")} element={<ConstructionPage />} />
          <Route path={path("survey")} element={<SurveyPage />}>
            <Route path="copy/:id" />
            <Route path=":id" />
          </Route>
          <Route path={path("test")} element={<TesterPage />} />
          <Route path={path("preview")} element={<TesterPage preview />} />
          <Route path={path("summary")} element={<SummaryPage />} />
          <Route path="*" element={<h1>404 not found</h1>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
