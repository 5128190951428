import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";

export interface Props extends IProps<HTMLDivElement> {}

export const TesterBasicTemplate: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Grid
      {...props}
      className={cx(
        props.className,
        styles(props, theme),
        "tester-basic-template"
      )}
      container
      rows={{
        default: "",
        mobile: "1rem 1fr 1rem",
      }}
      areas="left child right"
    >
      <Grid className="testerbasic-griditem" item name="child">
        {props.children}
      </Grid>
    </Grid>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  padding: ${theme.padding.testerpage};
  box-sizing: border-box;
  height: 100vh !important;

  background-color: ${theme.colors.Primary.White};

  .testerbasic-griditem {
    height: 100%;
  }
`;
