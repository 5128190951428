import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Skeleton, Props as SkeletonProps } from "ui/atoms/Skeleton";

export interface Props extends SkeletonProps {
  loading: boolean;
}

export const LoadComponent: React.FC<Props> = (props) => {
  if (props.loading) {
    return <Skeleton {...props} testId={props.testId + "-skeleton"} />;
  }
  return <>{props.children}</>;
};

// css design
const styles = (props: Props, theme: Theme) => css``;
