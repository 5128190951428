import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { IAnswer } from "utils/types/test";

// atoms
import { Form } from "ui/atoms/Form";

// molecules
import { CardTester } from "ui/molecules/CardTester";

// templates
import {
  TesterButtonsTemplate,
  Props as TemplateProps,
} from "ui/templates/TesterButtons";
import { Typography } from "ui/atoms/Typography";

export interface Props extends Partial<TemplateProps> {
  title: string;
  yes: string;
  no: string;
  rotationId: number;
  rank: number;

  onSubmit(answer: IAnswer): void;
  attribute: {
    id: number;
    text?: string;
    image?: string;
  };
}

export const TestQuestion: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const startTime = performance.now();

  function onSubmit(e: React.FormEvent, data: ISubmit, type: string) {
    const timeToAnswer = performance.now() - startTime;

    props.onSubmit({
      ...data,
      timeToAnswer,
      response: type === "yes",
    });
  }

  return (
    <Form
      onSubmit={onSubmit}
      className={cx(props.className, styles(props, theme), "test-question")}
    >
      <input
        type="number"
        hidden
        name="rotationId"
        readOnly
        value={props.rotationId}
      />
      <input
        type="number"
        hidden
        readOnly
        name="surveyAttributeId"
        value={props.attribute.id}
      />
      <TesterButtonsTemplate
        {...props}
        left={{
          testId: props.testId + "-no",
          name: "no",
          value: "no",
          type: "submit",
          color: "darker",
          children: props.no,
        }}
        right={{
          testId: props.testId + "-yes",
          name: "yes",
          value: "yes",
          type: "submit",
          color: "primary",
          children: props.yes,
        }}
        flexProps={{
          direction: "column",
          rowGap: "1rem",
        }}
      >
        <CardTester
          question={props.attribute.text}
          className="question-card"
          image={props.attribute.image}
        />
      </TesterButtonsTemplate>
    </Form>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .question-card {
    margin-bottom: 0rem !important;
  }
`;

// types & interfaces
type ISubmit = {
  surveyAttributeId: number;
  rotationId: number;
};
