import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { info, log, warn } from "utils/function/console";
import { once, trigger } from "utils/function/events";

// models
import { AttributeType, AttributeVariant } from "utils/model/Attribute";
import { ISurvey, SurveyParadigm } from "utils/model/Survey";
import { IAttribute } from "utils/model/Attribute";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Button } from "ui/atoms/Button";
import { Typography } from "ui/atoms/Typography";

// molecules
import { Bar } from "ui/molecules/Bar";
import { Button as AttributeButton } from "ui/molecules/AttributeButton";
import { Dropdown, Option } from "ui/molecules/Dropdown";

// organisms
import { Attribute, ISubmit as IAttributeSubmit } from "ui/organisms/Attribute";
import { Popup } from "ui/organisms/WarningPopup";

export interface Props extends IProps<HTMLDivElement> {
  data?: ISurvey;
  setParadigm(value: SurveyParadigm): void;
}

export const Explicit: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [warningpopupstate, setWarningpopupstate] = React.useState(false);

  function onParadigmChange(
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = e.currentTarget.value as SurveyParadigm;
    props.setParadigm(value);
  }
  function confirmParadigmChange(answer: boolean) {
    trigger("paradigm-change", answer);
    setWarningpopupstate(false);
  }
  async function doCheck(value: SurveyParadigm) {
    if (value === "LEARNING") {
      setWarningpopupstate(true);
    } else return true;

    function handleevent(): Promise<boolean> {
      return new Promise((resolve) => {
        once("paradigm-change", (event: CustomEvent<boolean>) => {
          resolve(event.detail);
        });
      });
    }

    return await handleevent();
  }

  const attributeMap: AttributeMap = React.useMemo(() => {
    const map: AttributeMap = {
      warmup: {
        ATTRIBUTE: [],
        BRAND: [],
        COLOR: [],
        IMAGE: [],
        MOOD: [],
      },
      regular: {
        ATTRIBUTE: [],
        BRAND: [],
        COLOR: [],
        IMAGE: [],
        MOOD: [],
      },
    };

    for (const attribute of props.data?.surveyAttributes || []) {
      map[attribute.warmup ? "warmup" : "regular"][
        attribute.attribute.type
      ].push(attribute as IAttribute);
    }

    return map;
  }, [props.data?.surveyAttributes]);

  return (
    <Box
      {...props}
      className={cx(props.className, styles(props, theme), "explicit")}
    >
      <Bar
        color={theme.colors.Primary.Crimson}
        left={
          <Typography variant="subtitle3" color={theme.colors.Primary.White}>
            {t("pages.survey.forms.statements.select.explicit")}
          </Typography>
        }
        right={
          <Dropdown
            variant="plain"
            onChange={onParadigmChange}
            testId={props.testId + "-paradigm"}
            className="paradigm"
            defaultValue="EXPLICIT"
            doCheck={doCheck}
            text={t("pages.survey.forms.statements.select.choose")}
          >
            <Option value="EXPLICIT">
              {t("pages.survey.forms.statements.select.explicit")}
            </Option>
            <Option value="LEARNING">
              {t("pages.survey.forms.statements.select.learning")}
            </Option>
          </Dropdown>
        }
      />
      <Part
        attributeMap={attributeMap}
        testId={props.testId}
        variant="warmup"
        data={props.data}
      />
      <Part
        attributeMap={attributeMap}
        testId={props.testId}
        variant="regular"
        data={props.data}
      />
      <Popup
        open={warningpopupstate}
        text={t("pages.survey.forms.statements.paradigmpopup")}
        onClick={confirmParadigmChange}
      />
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  .dropdown.paradigm .button {
    color: ${theme.colors.Primary.White};

    &:hover {
    }
  }
`;

// types & interfaces
export type AttributeSubmit = {
  ATTRIBUTE?: IAttributeSubmit;
  BRAND?: IAttributeSubmit;
  IMAGE?: IAttributeSubmit;
  MOOD?: IAttributeSubmit;
  COLOR?: IAttributeSubmit;
};
export type ISubmit = {
  regularAttributes: AttributeSubmit;
  warmupAttributes: AttributeSubmit;
};

interface PartProps {
  variant: AttributeVariant;
  data?: ISurvey;
  testId?: string;
  attributeMap: AttributeMap;
}
type AttributeButtons = Record<AttributeType, boolean>;
type AttributeMap = {
  warmup: Record<AttributeType, IAttribute[]>;
  regular: Record<AttributeType, IAttribute[]>;
};

// helper functions
function Part(props: PartProps) {
  const { t } = useTranslation();
  const [attributes, setAttributes] = React.useState<AttributeButtons>({
    ATTRIBUTE: false,
    BRAND: false,
    COLOR: false,
    IMAGE: false,
    MOOD: false,
  });

  const keys = Object.keys(attributes) as AttributeType[];

  function setSelected(type: AttributeType) {
    setAttributes({ ...attributes, [type]: !attributes[type] });
  }
  function onClose(type: AttributeType) {
    return () => {
      setSelected(type); // toggle
    };
  }

  React.useEffect(() => {
    if (props.data) {
      if (props.data.surveyAttributes) {
        const map: AttributeButtons = {
          ATTRIBUTE: false,
          BRAND: false,
          COLOR: false,
          IMAGE: false,
          MOOD: false,
        };

        for (const attribute of props.data.surveyAttributes) {
          if (
            (attribute.warmup && props.variant === "warmup") ||
            (!attribute.warmup && props.variant === "regular")
          ) {
            map[attribute.attribute.type] = true;
          }
        }

        setAttributes(map);
      } else {
        warn("survey-attributes doesnt exists", props.data);
      }
    }
  }, [props.data]);

  return (
    <Box className="mt2">
      <Typography className="mt2" variant="overline">
        {t(`pages.survey.forms.statements.explicit.${props.variant}`)}
      </Typography>
      <Typography className="mt1">
        {t("pages.survey.forms.statements.explicit.help")}
      </Typography>
      <Grid className="mt1" container cols="repeat(5, 1fr)" colGap="1rem">
        {keys.map((type) => (
          <AttributeButton
            key={props.variant + type}
            selected={!!attributes[type]}
            disabled={type === "IMAGE"}
            setSelected={setSelected}
            type={type}
            testId={`${props.testId}-${props.variant}-${type}-selector`}
          />
        ))}
      </Grid>
      <Grid className="mt1" container cols="1fr 1fr" gap="1rem">
        {keys.map((type, index) => {
          if (!attributes[type]) return null;
          return (
            <Attribute
              key={type}
              name={`${props.variant}Attributes.${type}`}
              attributes={props.attributeMap[props.variant][type]}
              onClose={onClose(type)}
              warmup={props.variant === "warmup"}
              type={type}
              testId={`${props.testId}-${props.variant}-${type}`}
            />
          );
        })}
      </Grid>
    </Box>
  );
}
