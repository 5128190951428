import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Typography } from "ui/atoms/Typography";
import { Grid } from "ui/atoms/Grid";
import { Card } from "ui/atoms/Card";
import { info } from "utils/function/console";

export interface Props extends IProps<HTMLDivElement> {
  productName?: string;
  productCode: string;
  imagePath: string;
}

export const CardSummaryProduct: React.FC<Props> = (props) => {
  const theme = useTheme();
  const name = props.productName || props.productCode;
  return (
    <Card
      {...props}
      className={cx(name, styles(props, theme), "cardSummaryProduct")}
    >
      <Grid
        colGap="0.5rem"
        cols="48px calc(100% - 48px)"
        rows="1fr 1fr"
        container
        areas={["image name", "image code"]}
      >
        <Grid item name="image">
          <img
            src={props.imagePath}
            alt={name}
            style={{ width: "48px", height: "48px", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item name="name">
          <Typography
            testId={props.testId + "-CardSummaryProduct"}
            variant="overline"
            color="white"
            className="ellipsis"
          >
            {name}
          </Typography>
        </Grid>
        <Grid item name="code">
          <Typography
            testId={props.testId + "-CardSummaryProduct"}
            variant="overline"
            color="white"
          >
            #{props.productCode.toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  width: ${theme.components.summaryCard.width};
  height: ${theme.components.summaryCard.height.small};
  background-color: #4e4e4e;
  padding: 1rem !important;
`;
