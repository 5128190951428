import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { Flex } from "../Flex";
import { useUUID } from "utils/hook/uuid";
import { info } from "utils/function/console";

export interface Props extends IProps<HTMLDivElement> {
  toggle: boolean;
  setToggle(toogle: boolean): void;
}

export const Toggle: React.FC<Props> = (props) => {
  const theme = useTheme();
  const uuid = useUUID();

  return (
    <Flex
      {...props}
      className={cx(props.className, styles(props, theme), "toggle")}
      justifyContent="flex-start"
      alignItems="center"
    >
      <input
        onClick={() => props.setToggle(!props.toggle)}
        type="checkbox"
        id={uuid}
        style={{ marginLeft: "-3px" }}
      />
      <label htmlFor={uuid}>Toggle</label>
    </Flex>
  );
};

const styles = (props: Props, theme: Theme) => css`
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 32px;
    height: 16px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 2.5px;
    left: 3px;
    width: 11px;
    height: 11px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: ${theme.colors.Primary.Crimson};
  }

  input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 33px;
  }
`;
