import { css } from "@emotion/css";
import React from "react";

// utils
import { IProps } from "utils/types/html";

export interface Props extends IProps {
  padding?: string | number;
  margin?: string | number;
  color?: string;
  radius?: number | string;
  children?: React.ReactNode;
}

export const Box = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div
      {...props.dom}
      style={props.style}
      data-testid={props.testId}
      className={[styles(props), props.className, "box"].join(" ")}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

// css design
const styles = (props: Props) => css`
  padding: ${props.padding};
  margin: ${props.margin};
  border-radius: ${props.radius};

  ${props.color ? `background-color: ${props.color};` : ""}
`;
