import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Card } from "ui/atoms/Card";
import { Flex } from "ui/atoms/Flex";
import { Typography } from "ui/atoms/Typography";

export interface Props extends IProps<HTMLDivElement> {
  image?: string;
  question?: string;
}

export const CardTester: React.FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <Card
      {...props}
      noBorder={!!props.image}
      className={cx(props.className, styles(props, theme), "card-tester")}
    >
      {props.question && (
        <Flex justifyContent="center" alignItems="center">
          <Typography testId={props.testId + "-question"}>
            {props.question}
          </Typography>
        </Flex>
      )}
    </Card>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  height: 300px;
  width: 554px;
  text-align: center;
  font-size: 32px;
  background-color: ${theme.colors.Neutral.Gray1};
  background-image: url(${props.image});
  background-size: cover;
`;
