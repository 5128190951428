import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { UseQueryResult } from "react-query";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { HTTPError } from "utils/error/http";
import { info } from "utils/function/console";
import { IPaginationResponse } from "utils/types/api";
import { surveys as surveysmock } from "utils/mock/data/surveys";

// models
import { ISurvey } from "utils/model/Survey";

// atoms
import { Typography } from "ui/atoms/Typography";
import { Box } from "ui/atoms/Box";
import { Flex } from "ui/atoms/Flex";

// molecules
import { LoadComponent } from "ui/molecules/LoadComponent";
import { SurveyCard } from "ui/molecules/SurveyCard";

export interface Props extends IProps<HTMLDivElement> {
  surveys: UseQueryResult<IPaginationResponse<ISurvey>, HTTPError>;
}

export const RecentSurveys: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { className, testId, children, ...rest } = props;

  const surveys =
    props.surveys.data ||
    ({
      items: surveysmock.slice(0, 4),
    } as IPaginationResponse<ISurvey>);

  return (
    <Box
      className={cx(className, styles(props, theme), "recent-surveys")}
      testId={testId}
      {...rest}
    >
      <Typography variant="subtitle3">
        {t("organisms.survey.recent.title")}
      </Typography>
      <Flex justifyContent="space-between">
        {surveys.items.map((survey) => (
          <LoadComponent
            height="8rem"
            loading={props.surveys.isLoading}
            key={survey.id}
          >
            <SurveyCard {...survey} />
          </LoadComponent>
        ))}
      </Flex>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .skeleton {
    margin: 0.6rem;
    border-radius: ${theme.border.radius.small};
  }
  & .survey-card {
    padding-bottom: 3rem;
    margin: 0.6rem;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
