import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
// import { UpdateOppositeInputOnchange } from "utils/function/form";

// models
import { ISurvey } from "utils/model/Survey";
import { IProduct } from "utils/model/Product";
import { IFamily } from "utils/model/Family";
import { ISubFamily } from "utils/model/SubFamily";
import { ISpecifier } from "utils/model/Specifier";

// atoms
import { Box } from "ui/atoms/Box";
import { Form as AtomForm } from "ui/atoms/Form";
import { Grid } from "ui/atoms/Grid";
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";

// molecules
import { TextField } from "ui/molecules/TextField";
import { BigButton } from "ui/molecules/BigButton";

// organisms
import {
  ProductCard,
  ISubmit as ISubmitProductcard,
} from "ui/organisms/ProductCard";
import { HTTPError } from "utils/error/http";

export interface Props extends IProps<HTMLDivElement> {
  survey?: UseQueryResult<ISurvey, HTTPError>;
  families: UseQueryResult<IFamily[], HTTPError>;
  subfamilies: UseQueryResult<ISubFamily[], HTTPError>;
  specifiers: UseQueryResult<ISpecifier[], HTTPError>;
  copy?: boolean;
}
export const Form: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fileRef = React.useRef<HTMLInputElement>(null);
  const [products, setProducts] = React.useState<(IProduct | undefined)[]>(
    props.survey?.data?.products || []
  );

  function onClose(index: number) {
    return () => {
      const copy = [...products];
      copy.splice(index, 1);

      setProducts([...copy]);
    };
  }

  function openFileExplorer() {
    if (fileRef.current) fileRef.current.click();
  }

  function addClick() {
    const copy = [...products];
    copy.push(undefined);
    setProducts(copy);
  }

  React.useEffect(() => {
    if (props.survey?.data?.products) {
      setProducts(props.survey.data.products);
    }
  }, [props.survey?.data?.products]);

  return (
    <Box
      {...props}
      className={cx(
        props.className,
        styles(props, theme),
        "survey-form-product-data"
      )}
    >
      <Flex justifyContent="space-between" alignItems="flex-end" noHeight>
        {/* <TextField
          label={t("pages.survey.forms.product.numberofproducts")}
          dom={{
            placeholder: t(
              "pages.survey.forms.product.placeholders.numberofproducts"
            ),
            min: 0,
            type: "number",
            id: "betaProducts",
          }}
          onChange={onNumberChange}
        /> */}
        <input type="file" name="csv" ref={fileRef} hidden accept=".csv" />
        <Button
          onClick={openFileExplorer}
          textVariant="button1"
          border
          color="darker"
        >
          <Flex noHeight justifyContent="space-between" alignItems="center">
            <Icon type="arrow" rotate={90} disableRotateAnimtaion />
            {t("pages.survey.forms.product.uploadrotationplan")}
          </Flex>
        </Button>
      </Flex>
      <Grid
        className="product-grid"
        container
        cols="1fr 1fr"
        rows={`repeat(${Math.ceil((products.length + 1) / 2)}, 1fr)`}
        gap="2rem"
      >
        {products.map((product, index) => (
          <Grid item key={index.toString() + product?.id}>
            <ProductCard
              families={props.families}
              subfamilies={props.subfamilies}
              specifiers={props.specifiers}
              onClose={onClose(index)}
              data={product}
              copy={props.copy}
              index={index}
              testId={props.testId + "-product-" + index}
            />
          </Grid>
        ))}
        <Grid item>
          <BigButton className="add" onClick={addClick} color="dark">
            <Icon type="plus" />
            {t("pages.survey.forms.product.add")}
          </BigButton>
        </Grid>
        {/* HEIGHT ISSUE FIX */}
        {products.length % 2 === 0 && <Grid item></Grid>}
        <br />
      </Grid>
      <br />
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .product-grid {
    margin-top: 2rem;
  }

  & .add {
    max-height: 11rem;
  }
`;

// types & interfaces
export type ISubmit = {
  products: ISubmitProductcard[];
  csv?: File | null | undefined;
};
