import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

export interface Props extends IProps<HTMLDivElement> {
  noBorder?: boolean;
  radius?: "small" | "medium" | "large";
}

export const Card: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      {...props.dom}
      style={props.style}
      data-testid={props.testId}
      className={cx(styles(props, theme), props.className, "card")}
    >
      {props.children}
    </div>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  position: relative;
  box-sizing: border-box;
  padding: 1.5rem;
  min-height: 2rem;
  background-color: ${theme.colors.Primary.White};
  border-radius: ${props.radius
    ? theme.border.radius[props.radius]
    : theme.border.radius.medium};
  ${!props.noBorder ? `border: 1px solid ${theme.colors.Neutral.Gray4};` : ""}
`;
