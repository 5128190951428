import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";

export interface Props extends IProps<HTMLDivElement> {}

export const PageTemplate: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      className={cx(props.className, styles(props, theme), "page")}
    >
      {props.children}
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  min-height: 100vh;
  // overflow: hidden;
  width: 100%;
`;
