import { createContext } from "react";

export interface IContext {
  errors: Errors;
  setError(name: string, validityState: ValidityState): void;
}
export const Context = createContext<IContext>({
  errors: {},
  setError: () => null,
});
export type Errors = {
  [key: string]: boolean;
};
