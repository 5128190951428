// NOTE we go with javasript here as typescript fails a bit on nested-generic-objects

import { error } from "utils/function/console";

// NOTE OBS! does not support array-array e.g. items[8]|2].name
// FIXME ..one day (line ~68: "target[index] = {};")
export function ExtractValues(submitEvent) {
  // NOTE 1 extract + fix multi-select
  const form = submitEvent.currentTarget;
  // eslint-disable-next-line no-undef
  const formdata = new FormData(form);
  const list = formdata.entries();
  let next = list.next();

  const source = {};

  // 🎉 took me many years but finally a case where do-while is needed !
  do {
    if (next.value) {
      const [name, value] = next.value;
      source[name] = value;
    }

    next = list.next();
  } while (!next.done);

  // fixing checkboxes
  const checkboxes = form.querySelectorAll("input[type='checkbox'][name]");
  checkboxes.forEach((checkboxElm) => {
    source[checkboxElm.name] = checkboxElm.checked;
  });

  try {
    const inputnumbers = form.querySelectorAll("input[type='number'][name]");
    const selectnumberfields = form.querySelectorAll(
      "input[data-selectnumber='true'][name]"
    );

    inputnumbers.forEach((elm) => convertToNumber(source, elm));
    selectnumberfields.forEach((elm) => convertToNumber(source, elm));
  } catch (e) {
    error("convertion of number failed", e);
  }

  // NOTE 2 at this point its flattened but needs objectivization - ?
  const data = {};
  for (const key in source) {
    AddKey(data, source, key);
  }

  return data;
}

function AddKey(data, source, key) {
  const split = key.split("."); // e.g. products[1].items[9].name
  // const path = [];

  // normal case
  if (split.length === 1) {
    data[key] = source[key];
    return;
  }

  // key has object positioning
  let target = data;
  let lastkey = null;
  for (let i = 0; i < split.length; i++) {
    const s = split[i];
    const isLast = i === split.length - 1;
    const array = /(\w+)\[(\d+)\]/.exec(s);
    let keyname = s;

    if (array) {
      const [_full, name, index] = array;
      keyname = name;

      if (!target[keyname]) {
        target[keyname] = [];
        // target = target[keyname];
      }

      if (!target[keyname][index]) {
        target[keyname][index] = {};
      }
      if (isLast) {
        lastkey = index;
        target = target[keyname];
        break;
      }
      target = target[keyname][index];
      continue;
    }

    if (!target[keyname]) {
      target[keyname] = {};
    }
    if (isLast) {
      lastkey = keyname;
      break;
    }
    target = target[keyname];
  }

  target[lastkey] = source[key];
}

function convertToNumber(source, element) {
  if (element.value !== "") {
    source[element.name] = Number(element.value);
  } else {
    source[element.name] = undefined;
  }
}
