import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// model
import {
  AttributeType,
  ICreateAttribute,
  IAttribute,
  ICreateSubmitAttribute,
} from "utils/model/Attribute";

// atoms
import { Box } from "ui/atoms/Box";
import { Typography } from "ui/atoms/Typography";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";
import { Flex } from "ui/atoms/Flex";

// molecules
import { AttributeBar } from "ui/molecules/AttributeBar";
import {
  CardClose as Card,
  CardClose,
  Props as CardProps,
} from "ui/molecules/CardClose";
import { Item, ISubmit as ISubmitItem } from "ui/molecules/AttributeItem";
import { log } from "utils/function/console";

export interface Props extends CardProps {
  attributes: IAttribute[];
  type: AttributeType;
  warmup: boolean;
  name: string;
}

export const Attribute: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [items, setItems] = React.useState<(IAttribute | ICreateAttribute)[]>(
    props.attributes && props.attributes.length > 0
      ? props.attributes
      : [
          {
            attribute: {
              id: uuidv4(),
              imageLocation: "",
              translation: "",
              type: props.type,
            },
            warmup: props.warmup,
            id: uuidv4(),
          },
        ]
  );

  function add() {
    setItems([
      ...items,
      {
        attribute: {
          id: uuidv4(),
          imageLocation: "",
          translation: "",
          type: props.type,
        },
        warmup: props.warmup,
        id: uuidv4(),
      },
    ]);
  }

  function remove(index: number) {
    const copy = [...items];
    copy.splice(index, 1);
    setItems(copy);
  }

  return (
    <CardClose
      {...props}
      className={cx(props.className, styles(props, theme), "attribute-card")}
    >
      <Typography
        className="name"
        variant="overline"
        color={theme.colors.Primary.Crimson}
      >
        {t(`organisms.attribute.name.${props.type.toLocaleLowerCase()}`)}
      </Typography>
      <AttributeBar />

      <Flex
        testId={props.testId + "-list"}
        noHeight
        direction="column"
        rowGap="4px"
        className="mb1"
      >
        {items.map((attribute, index) => (
          <Item
            name={props.name}
            index={index}
            remove={remove}
            key={attribute.id + " " + attribute.attribute.id}
            attribute={attribute}
            testId={`${props.testId}-${index}`}
          />
        ))}
      </Flex>

      <Button
        testId={props.testId + "-add"}
        onClick={add}
        className="width100"
        shape="square"
        color="dark"
      >
        <Flex>
          <Icon type="plus" />
          <Typography variant="button2">
            {t("organisms.attribute.add")}
          </Typography>
        </Flex>
      </Button>
    </CardClose>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;

// types & interfaces
export type ISubmit = ISubmitItem[];
