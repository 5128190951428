import React from "react";
import { css, cx } from "@emotion/css";
import { useMutation, useQueryClient } from "react-query";
import { AppContext } from "AppContext";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Grid } from "ui/atoms/Grid";

// organisms
import { Header } from "ui/organisms/Header";
import { Sidebar } from "ui/organisms/Sidebar";

// templates
import {
  AuthPageTemplate,
  Props as AuthPageTemplateProps,
} from "ui/templates/AuthPage";

export interface Props extends AuthPageTemplateProps {
  variant: 1 | 2 | 3 | 4;
  title?: string;
}

export const SHTemplate: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { user, setUser } = React.useContext(AppContext);
  const queryClient = useQueryClient();

  let sidebarvariant: "primary" | "dark" = "primary";
  let headervariant: "medium" | "secondary" | "light" = "medium";
  switch (props.variant) {
    case 1:
      sidebarvariant = "primary";
      headervariant = "medium";
      break;
    case 2:
      sidebarvariant = "dark";
      headervariant = "secondary";
      break;
    case 3:
      sidebarvariant = "primary";
      headervariant = "light";
      break;
    case 4:
      sidebarvariant = "dark";
      headervariant = "light";
      break;
  }

  function logoutClick() {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("last-authcheck");
    queryClient.invalidateQueries("authcheck");
  }

  return (
    <AuthPageTemplate
      {...props}
      className={cx(props.className, "sh-template", styles(props, theme))}
    >
      <Grid
        className="sh-template-grid"
        style={props.style}
        container
        cols="auto 1fr"
        rows="auto 1fr"
        areas={{
          default: ["sidebar header", "sidebar body"],
        }}
      >
        <Sidebar variant={sidebarvariant} />
        <Header
          notifications={[]}
          messages={[]}
          user={user}
          variant={headervariant}
          title={props.title}
          logout={logoutClick}
        />
        <main className="sh-template-body" data-testid={props.testId + "-main"}>
          {props.children}
        </main>
      </Grid>
    </AuthPageTemplate>
  );
};

// <SidebarTemplate
//   {...props}
//   variant={sidebarvariant}
//   className={cx(props.className, "sh-template", styles(props, theme))}
// >

// </SidebarTemplate>

// css design
const styles = (props: Props, theme: Theme) => css`
  & .sh-template-grid {
    height: 100vh;
    overflow: hidden;

    & > .header {
      grid-area: header;
    }
    & > .sidebar {
      grid-area: sidebar;
    }
    & > main {
      grid-area: body;
      overflow-y: auto;
    }
  }
`;
