import React from "react";
import { css, cx, keyframes } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";
import { Flex } from "ui/atoms/Flex";
import { Icon } from "ui/atoms/Icon";
import { Typography } from "ui/atoms/Typography";

export interface Props extends IProps<HTMLDivElement> {}

export const PageLoader: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      {...props}
      className={cx(props.className, styles(props, theme), "PageLoader")}
    >
      <Flex justifyContent="center" alignItems="center">
        <Icon
          type="logo-single"
          color={theme.colors.Primary.Crimson}
          rotate={-60}
          className="spinner"
        />
        <Typography variant="subtitle3" className="dotted">
          {t("organisms.pageloader.text")}
        </Typography>
      </Flex>
    </Box>
  );
};

// css design
const spin = keyframes`
  0%, 50%, 80%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  70% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  73% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  77% {
    transform: translate(-50%, -50%) scale(1.05);
  }
`;

const styles = (props: Props, theme: Theme) => css`
  height: 100vh;

  & .spinner {
    position: absolute;
    top: 45%;
    left: 50%;

    animation: ${spin} 1200ms linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`;
