import { IProduct } from "utils/model/Product";
import { families } from "./families";
import { specifiers } from "./specifiers";
import { subfamilies } from "./subfamilies";

const data: IProduct = {
  id: 0,
  name: "name",
  type: {
    id: 0,
    name: "type",
  },
  stimulusType: "FRAGRANCE",
  description: "BENCHMARK",
  code: "20",
  developerCode: "21",
  family: families[0],
  subfamily: subfamilies[0],
  specifier: specifiers[0],
  dosage: 10,
  primaryNotes: "primaryNotes",
  secondaryNotes: "secondaryNotes",
  primaryDegree: "primaryDegree",
  secondaryDegree: "secondaryDegree",
};

export const products = [
  { ...data },
  { ...data, id: 1 },
  { ...data, id: 2 },
  { ...data, id: 3 },
  { ...data, id: 4 },
  { ...data, id: 5 },
  { ...data, id: 6 },
];
