import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { AttributeType } from "utils/model/Attribute";

// atoms
import { Button as AtomButton, Props as ButtonProps } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";
import { Typography } from "ui/atoms/Typography";
import { Flex } from "ui/atoms/Flex";

export interface Props extends Omit<Partial<ButtonProps>, "type"> {
  selected: boolean;
  setSelected(type: AttributeType): void;
  type: AttributeType;
}

export const Button: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function click() {
    props.setSelected(props.type);
  }

  return (
    <AtomButton
      disabled={props.disabled}
      testId={props.testId}
      style={props.style}
      dom={props.dom}
      color={props.selected ? "white" : "darker"}
      shape="square"
      className={cx(props.className, styles(props, theme), "attribute-button")}
      onClick={click}
    >
      <Flex colGap="2px">
        <Icon type="plus" rotate={props.selected ? 45 : 0} />
        <Typography variant="button1">
          {t(`molecules.attributebutton.${props.type.toLocaleLowerCase()}`)}
        </Typography>
      </Flex>
    </AtomButton>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;

// types & interfaces
