import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

import { AppContext } from "AppContext";

// utils
import { useTheme } from "utils/theme";
import { Theme } from "utils/types/theme";
import { IProps } from "utils/types/html";
import { info, log } from "utils/function/console";

//atoms
import { Box } from "ui/atoms/Box";
import { Typography } from "ui/atoms/Typography";
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";
import { Icon } from "ui/atoms/Icon";
import { useDebounce } from "utils/hook/debounce";
import { trigger } from "utils/function/events";

export type Props = ISnackbar & IProps;

export const Snackbar: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const debouncedRemove = useDebounce(
    removebar,
    theme.components.snackbar.timeout
  );
  // const timerRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(() => {
    debouncedRemove.run();

    return () => {
      debouncedRemove.clear();
    };
  }, []);

  function removebar() {
    trigger("snackbar-remove", props.id);
  }

  let bordercolor, textcolor;
  switch (props.type) {
    case "info":
      bordercolor = theme.colors.Primary.Crimson;
      textcolor = theme.colors.Primary.Black;
      break;
    case "warning":
      bordercolor = theme.colors.Primary.Black;
      textcolor = theme.colors.Primary.Black;
      break;
    case "success":
      bordercolor = theme.colors.Success.Green2;
      textcolor = theme.colors.Success.Green2;
      break;
    default:
      bordercolor = theme.colors.Error.Red2;
      textcolor = theme.colors.Error.Red2;
      break;
  }

  return (
    <Box
      {...props}
      className={cx(
        props.className,
        styles({ ...props, bordercolor, textcolor }, theme),
        "snackbar",
        props.type
      )}
    >
      <Flex direction="column">
        <Flex alignItems="center" justifyContent="space-between">
          <Typography align="left" variant="overline">
            {props.type}
          </Typography>
          <Button
            testId={props.testId + "-search"}
            className="search"
            shape="circle"
            onClick={removebar}
          >
            <Icon type="cross" />
          </Button>
        </Flex>
        <Typography align="left">{props.text}</Typography>
      </Flex>
    </Box>
  );
};

// css design
const styles = (props: CSSProps, theme: Theme) => css`
  background-color: ${theme.colors.Primary.White};
  border-color: ${props.bordercolor};
  border-radius: ${theme.border.radius.medium};
  border-style: solid;
  border-width: thin;
  padding: ${theme.padding.page};
  min-width: 35rem;
  pointer-events: auto;

  & .overline {
    color: ${props.textcolor};
  }

  & .button {
    background: none;
  }
`;

// types & interfaces
export interface ISnackbar {
  id: string;
  type: "warning" | "info" | "success" | "error";
  text: string;
}
type CSSProps = Props & { bordercolor: string; textcolor: string };
