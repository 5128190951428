import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { info, log } from "utils/function/console";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// models
import {
  IAttribute,
  ICreateAttribute,
  ICreateSubmitAttribute,
} from "utils/model/Attribute";

// atoms
import { Box } from "ui/atoms/Box";
import { Grid } from "ui/atoms/Grid";
import { Flex } from "ui/atoms/Flex";
import { Button } from "ui/atoms/Button";
import { Typography } from "ui/atoms/Typography";
import { Input } from "ui/atoms/Input";
import { Icon } from "ui/atoms/Icon";

export interface Props extends IProps<HTMLDivElement> {
  attribute: IAttribute | ICreateAttribute;
  index: number;
  name: string;
  remove(index: number): void;
}

export const Item: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [changed, setChanged] = React.useState(false);

  function removeClick() {
    props.remove(props.index);
  }
  function onChanged() {
    if (!changed) setChanged(true);
  }

  const baseName = `${props.name}[${props.index}].`;
  const baseNameA = `${props.name}[${props.index}].attribute.`;

  return (
    <Box
      className={cx(props.className, styles(props, theme), "attribute-item")}
    >
      {!changed && typeof props.attribute.id === "number" && (
        <input
          hidden
          name={baseName + "id"}
          defaultValue={props.attribute.id}
          type="number"
        />
      )}
      <input
        hidden
        name={baseName + "warmup"}
        type="number"
        defaultValue={props.attribute.warmup ? 1 : 0}
      />
      {!changed && typeof props.attribute.attribute.id === "number" && (
        <input
          hidden
          name={baseNameA + "id"}
          defaultValue={props.attribute.attribute.id}
          type="number"
        />
      )}
      <input
        hidden
        name={baseNameA + "type"}
        defaultValue={props.attribute.attribute.type}
      />
      <Flex {...props} alignItems="center" colGap="4px">
        <Box className="item-badge">
          <Typography align="center">{props.index}</Typography>
        </Box>
        <Box className="input-wrapper">
          <Input
            name={baseNameA + "translation"}
            testId={props.testId + "-input"}
            defaultValue={props.attribute.attribute.translation}
            onChange={onChanged}
          />
          <Button
            testId={props.testId + "-delete"}
            onClick={removeClick}
            shape="plain"
          >
            <Icon type="cross" color={theme.colors.Neutral.Gray4} />
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & > .flex {
    & .item-badge {
      background-color: ${theme.colors.Neutral.Gray1};
      width: 2rem;
      padding: 1rem;
      border-radius: ${theme.border.radius.small};
    }

    & .input-wrapper {
      position: relative;
      width: 100%;

      & .button {
        z-index: 2;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

// types & interfaces
export type ISubmit = IAttribute | ICreateSubmitAttribute;
