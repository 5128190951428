import React from "react";
import { css } from "@emotion/css";

// utils
import { CSSJustify, IProps } from "utils/types/html";
import { GetMediaType, Units } from "utils/types/media";
import { AppContext } from "AppContext";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

export const Grid: React.FC<Props> = (props) => {
  const { windowWidth } = React.useContext(AppContext);
  const theme = useTheme();

  return (
    <div
      {...props.dom}
      style={props.style}
      className={[
        props.className,
        styles({ ...props, windowWidth }, theme),
        "grid",
      ].join(" ")}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
};

type StringA = string | string[];
type Area = StringA | IMultiDimensonal<StringA>;
export interface Props extends IProps {
  container?: boolean;
  item?: boolean;

  rows?: string | IMultiDimensonal;
  cols?: string | IMultiDimensonal;

  gap?: GapType;
  rowGap?: GapType;
  colGap?: GapType;
  col?: RowColSplit | IRCM;
  row?: RowColSplit | IRCM;
  areas?: Area;
  alignItems?: CSSJustify;

  name?: string;
  inline?: boolean;
}

interface CSSProps extends Props {
  windowWidth: number;
}

// ** design **
const styles = (props: CSSProps, theme: Theme) => css`
  height: 100%;
  display: ${props.container
    ? props.inline
      ? "inline-grid"
      : "grid"
    : "block"};
  grid-template-columns: ${RowCol(
    props,
    "cols",
    GetMediaType(props.windowWidth, theme)
  )};
  grid-template-rows: ${RowCol(
    props,
    "rows",
    GetMediaType(props.windowWidth, theme)
  )};
  grid-template-areas: "${Areas(
    props.areas,
    GetMediaType(props.windowWidth, theme)
  )}";
  align-items: ${props.alignItems};

  grid-gap: ${props.gap};
  grid-column-gap: ${props.colGap};
  grid-row-gap: ${props.rowGap};
  grid-area: ${props.name};
`;

// ** types & interfaces **
interface IGridItem {
  gridColumnStart?: number;
  gridColumnEnd?: number;
  gridRowStart?: number;
  gridRowEnd?: number;
}
interface IMultiDimensonal<T = string> {
  default: T;
  mobile?: T;
  pad?: T;
  laptop?: T;
  desktop?: T;
}

// ** types **
type RowColSplit = {
  from: number;
  to: number;
};

// Item-Row-Col-IMultiDimensonal
type IRCM = IMultiDimensonal<RowColSplit>;
type FT = "from" | "to";
type RC = "row" | "col";
type RCS = "rows" | "cols" | "areas";
type GapType = string | number;

// ** helper functions **
function FromTo(
  props: Props,
  type: RC,
  mode: FT,
  unit: Units
): number | undefined {
  let rowsplit = props[type] as RowColSplit;
  if ((props[type] as IRCM)?.default) {
    rowsplit =
      ((props[type] as IRCM)[unit] as RowColSplit) ||
      (props[type] as IRCM)?.default;
  }

  if (!rowsplit) return undefined;

  return rowsplit[mode];
}

function RowCol(props: Props, type: RCS, unit: Units): string | undefined {
  if (typeof props[type] === "string") return props[type] as string;
  else if ((props[type] as IMultiDimensonal)?.default) {
    return (
      (props[type] as IMultiDimensonal)[unit] ||
      (props[type] as IMultiDimensonal)?.default
    );
  }
}

function Areas(area: Area | undefined, unit: Units) {
  function helper(value: StringA) {
    if (typeof value === "string") return value;
    else if (value instanceof Array) return (value as string[]).join('" "');

    return undefined;
  }

  if (area) {
    const val = helper(area as StringA);
    if (!val) {
      const multi = area as IMultiDimensonal<StringA>;
      if (multi?.default) {
        if (multi[unit]) return helper(multi[unit] as StringA);
        return helper(multi?.default);
      }
    }

    return val;
  }
}

function renderItem(props: Props, unit: Units): IGridItem {
  return {
    gridColumnStart: FromTo(props, "col", "from", unit),
    gridColumnEnd: FromTo(props, "col", "to", unit),
    gridRowStart: FromTo(props, "row", "from", unit),
    gridRowEnd: FromTo(props, "row", "to", unit),
  };
}
