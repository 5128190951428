import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";
import { info } from "utils/function/console";

// atoms
import { Flex } from "ui/atoms/Flex";
import { Icon } from "ui/atoms/Icon";
import { Typography } from "ui/atoms/Typography";
import { Button } from "ui/atoms/Button";

//molecules
import { PopupCard } from "ui/molecules/PopupCard";

export interface Props extends IProps<HTMLDivElement> {
  open?: boolean;
  text: string;
  onClick(answer: boolean): void;
}

export const Popup: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function click(ans: boolean) {
    return () => props.onClick(ans);
  }

  return (
    <PopupCard
      {...props}
      data-testid={props.testId}
      open={props.open}
      className={cx(
        props.className,
        styles(props, theme),
        "paradigm-change-popup"
      )}
      header={
        <Flex justifyContent="flex-start" alignItems="center">
          <Icon type="warning" />
          <Typography variant="overline">
            {t("organisms.warningpopup.header")}
          </Typography>
        </Flex>
      }
      footer={
        <Flex colGap="1rem" justifyContent="center" alignItems="center">
          <Button
            testId={props.testId + "-no"}
            className="nowrap"
            onClick={click(false)}
            shape="square"
            textVariant="button2"
            border
          >
            {t("organisms.warningpopup.buttons.cancel")}
          </Button>
          <Button
            testId={props.testId + "-yes"}
            className="nowrap"
            onClick={click(true)}
            shape="square"
            color="error"
            textVariant="button2"
          >
            {t("organisms.warningpopup.buttons.continue")}
          </Button>
        </Flex>
      }
    >
      <Typography variant="button1" align="center">
        {props.text}
      </Typography>
    </PopupCard>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  & .card {
    padding: 2rem !important;
  }
`;
