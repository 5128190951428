import React from "react";
import {css, cx} from "@emotion/css";
import {useTranslation} from "react-i18next";

// utils
import {Theme} from "utils/types/theme";
import {useTheme} from "utils/theme";
import {IAuth} from "utils/types/test";

// atoms
import {Form} from "ui/atoms/Form";

// molecules
import {Logo} from "ui/molecules/Logo";
import {TextField} from "ui/molecules/TextField";

// templates
import {
  TesterSingleTemplate,
  Props as TemplateProps,
} from "ui/templates/TesterSingle";
export interface Props extends TemplateProps {
  label: string;
  token: string;
  login(data: IAuth): void;
}

export const TestAuth: React.FC<Props> = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  function onSubmit(e: React.FormEvent, data: IAuth) {
    props.login(data);
  }

  return (
    <Form
      onSubmit={onSubmit}
      className={cx(props.className, styles(props, theme), "test-welcome")}
    >
      <TesterSingleTemplate {...props} submit>
        <input hidden readOnly name="token" value={props.token} />
        {/* <Logo logoColor={theme.colors.Primary.Crimson} /> */}
        <TextField
          testId={props.testId + "-respondentIdentifier"}
          name="respondentIdentifier"
          className="mt4 textauth-input"
          labelVariant="button3"
          dom={{
            placeholder: t(
              "organisms.testauth.respondentIdentifier.placeholder"
            ),
          }}
          error={{
            valueMissing: t("organisms.testauth.respondentIdentifier.error"),
          }}
          label={props.label}
        />
      </TesterSingleTemplate>
    </Form>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css``;
