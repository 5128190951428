import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

export interface Props
  extends IProps<HTMLParagraphElement | HTMLHeadingElement> {
  variant?: Variant;
  align?: "center" | "left" | "right" | "justify";
  color?: string;
  noselect?: boolean;
}

export const Typography: React.FC<Props> = (props) => {
  const theme = useTheme();

  const passedProps = {
    ...props.dom,
    style: props.style,
    className: cx(
      props.className,
      styles(props, theme),
      "typography",
      props.noselect ? "noselect" : "",
      props.variant
    ),
    "data-testid": props.testId,
  };

  switch (props.variant) {
    default:
      return <p {...passedProps}>{props.children}</p>;
    case "headline1":
      return <h1 {...passedProps}>{props.children}</h1>;
    case "headline2":
      return <h2 {...passedProps}>{props.children}</h2>;
    case "headline3":
      return <h3 {...passedProps}>{props.children}</h3>;
    case "headline4":
      return <h4 {...passedProps}>{props.children}</h4>;
    case "headline5":
      return <h5 {...passedProps}>{props.children}</h5>;
    case "headline6":
      return <h6 {...passedProps}>{props.children}</h6>;
  }
};

// css design
const styles = (props: Props, theme: Theme) => css`
  text-align: ${props.align ?? "left"};
  color: ${props.color ?? theme.colors.Primary.Black};
  margin: 0;

  &.headline1 {
    font-style: normal;
    font-weight: 900;
    font-size: 80px;
    line-height: 96px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  &.headline2 {
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 96px;
    letter-spacing: 0.02em;
  }
  &.headline3 {
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 96px;
    letter-spacing: 0.01em;
  }
  &.headline4 {
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 80px;
  }
  &.headline5 {
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
    letter-spacing: 0.02em;
  }
  &.headline6 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.02em;
  }
  &.subtitle1 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    letter-spacing: 0.02em;
  }
  &.subtitle2 {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 0.02em;
  }
  &.subtitle3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
  }
  &.paragraph {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
  }
  &.overline {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  &.caption {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
  }
  &.button1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
  &.button2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  &.button3 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
  }
`;

// Types & Interfaces
export type Variant =
  | "headline1"
  | "headline2"
  | "headline3"
  | "headline4"
  | "headline5"
  | "headline6"
  | "subtitle1"
  | "subtitle2"
  | "subtitle3"
  | "paragraph"
  | "overline"
  | "caption"
  | "button1"
  | "button2"
  | "button3";
