import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// local
import { ExtractValues } from "./ExtractValues";
import { Context, Errors, IContext } from "./context";
import { info } from "utils/function/console";

export interface Props extends IProps<HTMLFormElement> {
  onSubmit?(
    e: React.FormEvent<HTMLFormElement>,
    values: unknown,
    submitter?: string
  ): void;
  onError?(form: HTMLFormElement): void;
}

export const Form = React.forwardRef<HTMLFormElement, Props>((props, ref) => {
  const theme = useTheme();
  const [errors, setErrors] = React.useState<Errors>({});

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (e.currentTarget.reportValidity()) {
      if (props.onSubmit) {
        let submitter: string | undefined = undefined;
        if (e.nativeEvent) {
          const submitevent = e.nativeEvent as SubmitEvent;

          if (submitevent.submitter) {
            submitter = submitevent.submitter.getAttribute("name") || undefined;
          }
        }

        props.onSubmit(e, ExtractValues(e), submitter);
      }
    } else if (props.onError) {
      props.onError(e.currentTarget);
    }
  }

  function setError(name: string, state: ValidityState): void {
    const copy = { ...errors };
    copy[name] = state.valid;
    if (copy[name]) {
      delete copy[name];
    }

    setErrors(copy);
  }

  const provides: IContext = {
    errors,
    setError,
  };

  return (
    <Context.Provider value={provides}>
      <form
        {...props.dom}
        className={cx(props.className, styles(props, theme), "form")}
        data-testid={props.testId}
        noValidate
        onSubmit={onSubmit}
        ref={ref}
      >
        {props.children}
      </form>
    </Context.Provider>
  );
});

// css design
function styles(props: Props, theme: Theme) {
  return css``;
}
