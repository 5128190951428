import React from "react";
import { css, cx } from "@emotion/css";

// utils
import { useTheme } from "utils/theme";
import { Theme } from "utils/types/theme";

//atoms
import { Popup, Props as PopupProps } from "ui/atoms/Popup";
import { Box } from "ui/atoms/Box";
import { Flex } from "ui/atoms/Flex";

export interface Props extends PopupProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  open?: boolean;
}

export const PopupCard: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <Popup
      {...props}
      className={cx(props.className, styles(props, theme), "popup-background")}
    >
      <Flex justifyContent="center" alignItems="center">
        <Box className="card">
          <Flex direction="column" justifyContent="space-between">
            <Box testId={props.testId + "-header"} className="header">
              {props.header}
            </Box>
            <Box testId={props.testId + "-body"} className="body">
              {props.children}
            </Box>
            <Box testId={props.testId + "-footer"} className="footer">
              {props.footer}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Popup>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);

  & .card {
    background-color: ${theme.colors.Primary.White};
    margin: auto;
    padding: 1rem;
    border: 1px solid ${theme.colors.Neutral.Gray1};
    box-sizing: border-box;
    width: 724px;
    height: 272px;
    border-radius: ${theme.border.radius.medium};
  }

  & .button {
    width: 100%;
  }
`;
