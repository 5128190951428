/* eslint-disable no-console */

import { ErrorLog } from "utils/error/system";

function logger(type: "error" | "warn" | "info" | "log" | "trace") {
  return function (...data: unknown[]) {
    switch (type) {
      case "error": {
        // send crash report to BE ?
        if (window.localStorage.getItem("crash-reports")) {
          const report: ErrorLog[] = JSON.parse(
            window.localStorage.getItem("crash-reports") || "[]"
          );
          report.push(data[0] as ErrorLog);
          window.localStorage.setItem("crash-reports", JSON.stringify(report));
        }

        break;
      }
    }

    console[type](...data);
  };
}

export const trace = logger("trace");
export const log = logger("log");
export const info = logger("info");
export const warn = logger("warn");
export const error = logger("error");
