import React from "react";
import { v4 as uuidv4 } from "uuid";
import { css, cx } from "@emotion/css";

// api
import { Setup as APIsetup } from "utils/api/config";

// utils
import { Languages } from "utils/translation/Types";
import i18n from "utils/translation";
import { ThemeProvider } from "utils/theme";
import { ErrorBoundary } from "utils/error/Boundary";
import { Router } from "utils/router";
import { Pages } from "utils/types/router";
import "utils/function/console";
import { IUser } from "utils/model/User";

// atoms
import { Flex } from "ui/atoms/Flex";

// molecules
import { ISnackbar, Snackbar } from "ui/molecules/Snackbar";

// context
import { AppContext, IAppContext } from "./AppContext";
import { log, warn } from "utils/function/console";
import { useDebounce } from "utils/hook/debounce";
import { on } from "utils/function/events";

function App() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [user, setUser] = React.useState<IUser | undefined>();
  const [snackbars, setSnackbar] = React.useState<ISnackbar[]>([]);
  const snackbarsRef = React.useRef<ISnackbar[]>([]);
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  function changeLanguage(lang: Languages) {
    i18n.changeLanguage(lang);
  }

  React.useEffect(() => {
    // do something
    setLoading(false);
    on("snackbar-remove", removeSnackbar);

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  function resize() {
    setWindowWidth(window.innerWidth);
  }

  function addSnackbar(snackbar: Omit<ISnackbar, "id">) {
    const id = uuidv4();
    const copy = [...snackbars];
    copy.push({ ...snackbar, id });
    if (copy.length >= 5) copy.splice(0, 1);
    snackbarsRef.current = copy;
    setSnackbar(copy);
  }

  function removeSnackbar(event: CustomEvent) {
    const id = event.detail;
    const index = snackbarsRef.current.findIndex((s) => s.id === id);
    const copy = [...snackbarsRef.current];
    copy.splice(index, 1);
    snackbarsRef.current = copy;
    setSnackbar(copy);
  }

  const provides: IAppContext = {
    loading,
    changeLanguage,
    windowWidth,
    user,
    setUser,
    addSnackbar,
  };

  // TODO implement fallback ui for application level !important!
  return (
    <ErrorBoundary
      name="application"
      fallbackUI="NEED TO IMPLEMENT FALLBACK UI ERROR PAGE"
    >
      <AppContext.Provider value={provides}>
        <APIsetup>
          <ThemeProvider type="primary">
            <Router />
            <Flex
              className={snackbarFlexStyle}
              rowGap="0.2rem"
              justifyContent="flex-end"
              alignItems="center"
              direction="column"
            >
              {snackbars.map((snackbar) => (
                <Snackbar {...snackbar} key={snackbar.id} />
              ))}
            </Flex>
          </ThemeProvider>
        </APIsetup>
      </AppContext.Provider>
    </ErrorBoundary>
  );
}

// css design
const snackbarFlexStyle = css`
  position: absolute;
  z-index: 100;
  width: 100%;
  bottom: 5rem;
  left: 0;
  pointer-events: none;
`;

export default App;
