import React from "react";
import { css, cx } from "@emotion/css";
import { useTranslation } from "react-i18next";

// utils
import { IProps } from "utils/types/html";
import { Theme } from "utils/types/theme";
import { useTheme } from "utils/theme";

// atoms
import { Box } from "ui/atoms/Box";
import { Flex } from "ui/atoms/Flex";
import { Typography } from "ui/atoms/Typography";

export interface Props extends IProps<HTMLDivElement> {}

export const RainbowBarHorizontal: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Flex
      {...props}
      className={cx(
        props.className,
        styles(props, theme),
        "RainbowBarHorizontal"
      )}
      alignItems="center"
    >
      <Typography variant="paragraph">
        {t("molecules.rainbowhorizontalbar.high")}
      </Typography>
      <Box className="rainbowBar ml1 mr1" />
      <Typography variant="paragraph">
        {t("molecules.rainbowhorizontalbar.low")}
      </Typography>
    </Flex>
  );
};

// css design
const styles = (props: Props, theme: Theme) => css`
  .rainbowBar {
    width: 190px;
    height: 18px;
    border-radius: 100px;
    background: linear-gradient(
      90deg,
      #99da98 0%,
      #f8b66a 51.56%,
      #cf6767 100%
    );
  }
`;
